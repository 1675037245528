// Scss for mobile devices
@media only screen and (max-width: 768px) {
}

// Scss for tablet devices
@media only screen and (min-width: 768px) and (max-width: 992px) {
}

// Scc for desktop only {
@media only screen and (min-width: 992px) {
  .express {
    .express-content {
      max-width: 724px;
    }
  }
}

// Scc for medium desktop only {
@media only screen and (min-width: 1200px) {
  .express {
    .express-content {
      max-width: 900px;
    }
  }
}
// Scc for medium desktop only {
@media only screen and (min-width: 1400px) {
  .express {
    .express-content {
      max-width: 1200px;
    }
  }
}

// Scc for big desktop only {
@media only screen and (min-width: 1600px) {
  .express {
    .express-content {
      max-width: 1400px;
    }
  }
}
