.adv-embedd-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px dashed #ddd;
}
// SWIPER
.inline-nlh-popup {
  position: relative;
  padding: 15px;
  background-color: #fff;
  font-family: "Poppins";
}

.inline-nlh-popup-pre-1 {
  max-width: 450px;
}

.inline-nlh-popup-pre-2 {
  max-width: 720px;
}

.inline-nlh-popup-pre-3 {
  max-width: 1020px;
}

.inline-nlh-minimize {
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  background-color: #e2e2e2;
  cursor: pointer;
  svg {
    width: 10px;
  }
  &:hover {
    background-color: #f2f2f2;
  }
}

.inline-nlh-wrapper {
  &.left {
    .inline-nlh-title,
    .inline-nlh-footer {
      justify-content: flex-start;
      text-align: left;
    }

    .inline-nlh-description {
      text-align: left;
    }

    .no-navigation {
      .swiper {
        margin-left: 0;
      }
    }

    .inline-swiper-wrapper {
      display: flex;
      justify-content: flex-start;
    }
  }
  &.right {
    .inline-nlh-title,
    .inline-nlh-footer {
      justify-content: flex-end;
      text-align: right;
      padding: 0px 15px;
    }

    .inline-nlh-footer {
      svg {
        margin-right: 0;
        margin-left: 10px;
      }
    }

    .no-navigation {
      .swiper {
        margin-right: 0;
      }
    }

    .inline-nlh-description {
      text-align: right;
      padding: 0px 15px;
    }

    .inline-swiper-wrapper {
      display: flex;
      justify-content: flex-end;
    }
  }
  &.center {
    .inline-nlh-title,
    .inline-nlh-footer {
      justify-content: center;
      text-align: center;
      padding: 0px 15px;
    }

    .inline-nlh-description {
      text-align: center;
      padding: 0px 15px;
    }

    .inline-swiper-wrapper {
      display: flex;
      justify-content: center;
    }
  }
}

.inline-nlh-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .inline-nlh-title-label {
    font-size: 22px;
    display: inline-block;
    vertical-align: middle;
  }
}

.inline-nlh-title-icon {
  svg {
    max-width: 20px;
    max-height: 20px;
    margin-right: 5px;
  }
}

.inline-nlh-swiper {
  overflow: hidden;
  .inline-nlh-swiper-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    &.no-navigation {
      padding: 0;
    }
  }

  .swiper {
    position: relative;
    padding: 20px 0 40px 0;
    .swiper-overflow {
      overflow: hidden;
    }
    .swiper-slide {
      height: auto;
      width: 350px;
      padding: 0 2px;
    }
    .swiper-item {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 100%;
      input {
        display: none;
      }
      &.display {
        & > label {
          cursor: pointer;
          &:hover {
            .switch-text {
              opacity: 0.6;
            }
          }
        }
      }
      & > label {
        display: flex;
        flex-direction: row;
        padding: 10px;
        height: 100%;
        border-radius: 4px;
        border: 1px solid #d6dae5;
        background-color: #ffffff;
      }
    }
    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal {
      bottom: 0;
    }

    .swiper-pagination-bullet {
      margin: 0 5px;
    }

    .swiper-navigation {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 100%;
      bottom: 0;

      .swiper-pagination {
        position: relative;
        width: auto;
        margin: 0 20px;
      }
    }

    .swiper-button-next {
      position: relative;
      width: 30px;
      height: 30px;
      right: 0;
      margin: 0;
      &::after {
        content: none;
      }

      &:hover {
        opacity: 0.7;
      }
    }

    .swiper-button-prev {
      position: relative;
      width: 30px;
      height: 30px;
      left: 0;
      margin: 0;

      &::after {
        content: none;
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }
  .item-image {
    min-width: 104px;
    img {
      width: 104px;
      height: 104px;
      margin: auto;
      margin-top: 0;
      border-radius: 2px;
      object-fit: cover;
      object-position: center;
      overflow: hidden;
      pointer-events: none;
    }
  }

  .item-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-left: 10px;
  }

  .item-content-wrapper {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
  }

  .item-content-icons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 10px;
  }

  .item-description {
    margin-bottom: 5px;
  }

  .item-get {
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
  }
  .item-get-center {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  .item-title {
    font-weight: 600;
    font-size: 15px;
  }

  .item-action-get {
    padding: 6px 12px;
    box-shadow: 0 4px 5px 0 rgb(0 0 0 / 17%);

    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }

  .item-info-icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border: 1px solid;
    border-radius: 100%;
    cursor: pointer;
    &:hover {
      opacity: 0.6;
    }
  }

  .input:checked + .item-layout {
    position: relative;
    border-color: transparent;
    border-color: #3ab78f !important;
    border: 1px solid;
    & .switch-toggle {
      background-color: #3ab78f;
      opacity: 1;
      .switch-toggle-before {
        right: 3px;
        left: auto;
        background-color: #ffffff;
      }
    }
    & .switch-text {
      font-family: inherit;
      font-weight: 400;
      .checked-text {
        display: inline-block;
      }
      .unchecked-text {
        display: none;
      }
    }
    & .selected {
      .button-select {
        display: none;
      }
      .button-selected {
        display: inline-block;
      }
    }
  }
}

.item-layout,
.item-title-text,
.item-image,
.item-description {
  cursor: pointer;
}

.item-email-icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 15px;
  width: 30px;
  height: 30px;
  border: 1px solid;
  border-radius: 100%;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
}

.item-icon-wrap {
  min-height: 15px;
}

.inline-nlh-footer {
  display: flex;
  align-items: center;
  margin-top: 15px;
  font-size: 12px;
  svg {
    min-width: 24px;
    margin-right: 10px;
  }
}

.item-nlh-dashed-button-es {
  align-items: center;
  border: 1px dashed #656060;
  border-radius: 6px;
  color: #656060;
  cursor: pointer;
  display: flex;
  height: 38px;
  justify-content: center;
  padding: 6px 24px;
  width: 160px;
}

.max-2lines {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.max-1lines {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
