:root {
  --primary-color: #292426;
  --secondary-color: #f2f2f2;
  --secondary-light-color: #f9f9f9;
  --border-color: #d6dae5;
  --active-color: #3e97ff;
  --success-color: #3ab78f;
  --default-color: #ebf8f4;
  --default-icon-color: #b5b5c3;
  --active-background-color: #eef6ff;
  --label-active-font: #844aff;
  --background-color: #fff;
  --label-background-color: #ffebdf;
  --border-radius-large: 12px;
  --border-radius-medium: 6px;
  --border-radius-small: 3px;
  --font-color: #72778a;
  --font-dark-menu: #b5b5b5;
  --font-disabled-color: #a3a8b9;
  --disable-color: #b2b7c3;
  --warning-color: #ff782d;
  --danger-color: #ff3d60;
  --border-dark-color: #303142; // not original
  --title-color: #a5adac; // not original
  --fail-color: #ec322c; // not original
  --stop-color: #aaaaaa;
  --fail-light-color: #d5c1c0; // not original
  --new-primary-color: #161616;
}
