@import url(./_fonts.scss);
@import url(./_elements.scss);

.express {
  background-color: #f6f6f7;
  color: #202223;
  min-height: 100vh;
  font-family: "Noto Sans";
  &.ant-modal {
    padding-bottom: 0;
  }
  button.ant-btn,
  .ant-btn {
    min-height: 36px;
    line-height: 36px;
    height: 36px;
    border: none;
    font-family: "Noto Sans";
    box-shadow: 0 1px 0 #d4d4d4;

    &:focus,
    &:visited {
      opacity: 0.8;
      box-shadow: none !important;
    }

    &:disabled {
      background-color: #f0f0f0;
      box-shadow: none !important;
    }
  }
  .ant-switch {
    &.ant-switch-checked {
      background-color: #007a5c;
    }
  }
}

.express-input-line-description {
  display: block;
  color: #959aab;
}

.express-input-line {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin: 15px 0;
  padding-bottom: 15px;
  border-bottom: 1px dotted #d6dae5;
  span {
    color: #007a5c;
    font-size: 13px;
  }
}

.checkbox-express {
  &.ant-checkbox-wrapper {
    .ant-checkbox-checked + span {
      color: var(--primary-color);
    }
    .checkbox-value {
      margin-left: 5px;
      color: var(--disable-color);
    }
    .ant-checkbox-inner {
      border-color: var(--disable-color);
      border-width: 2px;
      background-color: transparent;
      &::after {
        top: 5.5px;
        left: 2.6px;
      }
    }
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #007a5c;
    border-color: #007a5c;
  }
}

.clear-categories {
  font-size: 13px;
  color: #8a8f92;
}

.express-dropdown-icon {
  position: absolute;
  top: calc(50% - 3px);
  right: 10px;
  display: inline-block;
  width: 9px;
  height: 6px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../../../assets/images/icons/express-dropdown-icon.svg");
  cursor: pointer;
}

.express-message {
  .ant-message-success .anticon {
    color: #007a5c;
  }
}

.express-content {
  max-width: 724px;
  margin-top: 25px;
  margin-left: auto;
  margin-right: auto;

  .ant-collapse > .ant-collapse-item:last-child,
  .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    border-radius: 8px;
  }
}

.content-medium {
  max-width: 70%;
  margin: auto;
}

.express-logo {
  height: 22px;
  margin-right: 30px;
}

.express-onboarding-info {
  margin-bottom: 5px;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
}

.express-onboarding-description {
  min-height: 50px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
}

.express-main {
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
}

.express-title {
  text-align: center;
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 10px;
  color: #202223;
}

.express-descrpiton {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.5px;
  margin-bottom: 20px;
  color: #202223;
}

.express-row {
  margin-bottom: 20px;
}

.experss-input {
  .label-input-wrapper {
    border: none;
  }
}

.express-label {
  position: relative;
  margin-bottom: 5px;
  font-size: 13px;
  line-height: 17.5px;
  font-weight: 400;
  color: #63686a;
  .unauth-label-error {
    position: absolute;
    right: 0;
    top: 2px;
  }
  .anticon {
    cursor: pointer;
    margin-left: 5px;
    color: #007a5c;
  }
}

.express-form {
  max-width: 430px;
  margin: auto;
}

.express-button {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: white;
  padding: 12px 0;
  text-align: center;
  box-shadow: 1px -1px 5px 0px #00000024;
  z-index: 1111;

  button {
    min-height: 36px;
    line-height: 36px;
    height: 36px;
    border: none;
    font-family: "Noto Sans";
    box-shadow: 0 1px 0 #d4d4d4;
    min-width: 100px;
    &:focus,
    &:visited {
      box-shadow: none;
    }
    span {
      font-size: 13px;
      font-weight: 500;
    }
  }
}

.express-button-dark {
  background-color: #202223;
  color: #fff;
  border-radius: 4px;
  font-size: 13px;
  font-family: "Noto Sans";
  font-weight: 500;
  box-shadow: 0 1px 0 #dfdfdf;
  &:hover,
  &:focus,
  &:active,
  &:visited {
    background-color: #202223ec;
    color: #fff;
    box-shadow: none;
  }
}

.express-button-success {
  background-color: #007a5c;
  color: #fff;
  border-radius: 4px;
  font-size: 13px;
  font-family: "Noto Sans";
  font-weight: 500;
  box-shadow: 0 1px 0 #dfdfdf;
  &:hover,
  &:focus,
  &:active,
  &:visited {
    background-color: #007a5bec;
    color: #fff;
    box-shadow: none;
  }
}
.express-button-back {
  background-color: #e1e1e1;
  color: #63686a;
  border-radius: 4px;
  font-size: 13px;
  font-family: "Noto Sans";
  font-weight: 500;
  &:hover,
  &:focus,
  &:active,
  &:visited {
    border-color: transparent;
    background-color: #e1e1e1b4;
    color: #63686a;
  }
}

.button-express-status {
  svg {
    margin-right: 5px;
    height: 10px;
  }
}

.express-checkbox {
  color: var(--primary-color);
  font-family: "Poppins-Regular";
  font-size: 14px;
  color: #63686a;
  font-weight: 400;
  margin-bottom: 20px;
}

.express-link {
  text-decoration: underline !important;
  color: #427cc2 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #007a5c;
  border-color: #007a5c;
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}

.widget-express {
  border-radius: 8px;
  margin-bottom: 30px;
  box-shadow: 0px 2px 5px 0px #00000024;
  .widget-devider {
    width: 724px;
    height: 1px;
    margin-left: 50%;
    margin-top: 30px;
    margin-bottom: 25px;
    border-color: #e1e3e5;
    border-top: 0;
    border-style: solid;
    transform: translateX(-50%);
  }
  .express-disabled {
    background-color: #fff;
    cursor: pointer;
  }
}

.item-body-description {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  > span {
    font-size: 12px;
    color: #63686a;
    font-weight: 400;
  }
}

.item-preview {
  margin: 15px 0;
  .express-template-layout {
    height: 100%;
    min-height: 600px;
  }
  text-align: center;
}

.item-body-device {
  .item-body-device-wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 9px 12px;
    margin-left: 10px;
    border: 1px solid #d6dae5;
    border-radius: 4px;

    &:first-child {
      margin-left: 0;
    }

    svg {
      path {
        stroke: #d6dae5;
      }
    }
    cursor: pointer;
    &.active {
      background-color: #6d7174;
      border: 1px solid #6d7174;
      svg {
        path {
          stroke: #ffffff;
        }
      }
    }
    &:hover {
      border: 1px solid #6d7174;
    }

    .desktop {
      width: 15px;
      height: 12px;
      object-fit: contain;
    }
    .mobile {
      width: 9px;
      height: 13px;
    }
  }
}

.widget-devider {
  min-width: 724px;
  width: calc(100% + 30px);
  margin-left: -15px;
  margin-top: 15px;
  margin-bottom: 15px;
  border-color: #e1e3e5;
  border-top: 0;
  border-style: solid;
}

.widget-express-layout {
  display: flex;
  background-color: #fff;
  height: calc(100vh - 170px);
  margin-bottom: 0;
  .ant-tabs {
    height: calc(100% - 22px);
    .ant-tabs-content-holder {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}
.express-layout-sidebar {
  flex: 0 0 255px;
  padding: 20px 0;
  border-right: 1px solid #e1e3e5;
}
.express-layout-preview {
  flex-grow: 1;
  padding: 20px 0;
}

.express-dropdown {
  position: relative;
  .ant-input {
    padding-right: 30px;
  }
}

.express-placements {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0;
}
.express-placement-accordion {
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 2px 5px 0px #00000024;
  .item-accordion {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
  }
}

.express-placement-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 2px 5px 0px #00000024;
}

.item-acions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.item-button {
  margin-left: 20px;
}
.item-expand {
  color: #c9cfd6;
}
.item-title {
  font-size: 13px;
  font-weight: 400;
}
.item-description {
  color: #8a8f92;
  font-size: 13px;
  font-weight: 400;
  &.item-valid {
    color: #202223;
  }
  &.item-invalid {
    color: #e80000;
  }
}
.item-toggle {
  color: #63686a;
  font-size: 13px;
  font-weight: 400;
  &.active {
    color: #007a5c;
  }
}
.item-button {
  background-color: #f1f2f3;
  padding: 2px 8px;
  font-size: 13px;
  font-weight: 400;
  border-radius: 4px;
  color: #63686a;
  cursor: pointer;
  &:hover {
    background-color: #e0ede1;
  }
}
.item-delete {
  width: 15px;
  margin-left: 15px;
  color: #63686a;
  cursor: pointer;
  &:hover {
    color: red;
  }
}
.ant-collapse-item {
  .item-expand {
    &.item-minus {
      display: none;
    }
    &.item-plus {
      display: inline-block;
    }
  }
  &.ant-collapse-item-active {
    .item-expand {
      &.item-minus {
        display: inline-block;
      }
      &.item-plus {
        display: none;
      }
    }
  }
}
.express-placements-info {
  font-size: 13px;
  font-weight: 500;
}

.express-placements-add {
  span {
    font-size: 13px;
    font-weight: 400;
  }
}

.express-onboarding-stesp {
  .express-layout-header {
    margin-top: 15px;
    margin-bottom: 10px;
    .express-layout-info {
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
      .layout-name {
        margin-bottom: 0;
      }
    }
  }
  .ant-tabs {
    .ant-tabs-nav {
      position: fixed;
      top: 0; /* Adjust this value to control the sticky position */
      z-index: 1000; /* Optional: Ensure it stays above other content */
      width: 100%;
      background-color: #fff;
      box-shadow: 1px 1px 5px 0px #00000024;
    }

    .ant-tabs-nav-wrap,
    .ant-tabs-nav-list {
      width: 1024px !important;
      margin-left: auto;
      margin-right: auto;
    }

    .ant-tabs-content-holder {
      width: 1024px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 85px;
    }

    .ant-tabs-tab {
      padding: 16px 0;
      &:first-child {
        &::before {
          display: none;
        }
      }
    }

    &.express-tab-details {
      > .ant-tabs-nav {
        .ant-tabs-tab[data-node-key="partners"],
        .ant-tabs-tab[data-node-key="placements"],
        .ant-tabs-tab[data-node-key="layout"] {
          &::before {
            background-color: #e1e3e5;
          }
        }
      }
    }

    &.express-tab-partners {
      > .ant-tabs-nav {
        .ant-tabs-tab[data-node-key="placements"],
        .ant-tabs-tab[data-node-key="layout"] {
          &::before {
            background-color: #e1e3e5;
          }
        }
      }
    }

    &.express-tab-placements {
      > .ant-tabs-nav {
        .ant-tabs-tab[data-node-key="layout"] {
          &::before {
            background-color: #e1e3e5;
          }
        }
      }
    }
  }
}

.express-onboarding-stesp {
  .option-title {
    cursor: default;
    pointer-events: none;
  }
  .ant-tabs {
    .ant-tabs-tab {
      &.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          font-weight: 500;
          color: #202223;
        }
      }
      .ant-tabs-tab-btn {
        font-size: 13px;
      }
    }
    &.express-tabs {
      &.ant-tabs-top > .ant-tabs-nav::before {
        border-bottom: none;
      }
      > .ant-tabs-nav {
        margin-bottom: 30px;
        pointer-events: none;
        .ant-tabs-nav-list {
          width: 100%;
        }
        .ant-tabs-nav-wrap {
          width: 100%;
        }

        .ant-tabs-ink-bar {
          display: none;
        }
        .ant-tabs-tab {
          position: relative;
          width: 100%;
          text-align: center;
          margin: 0;
          &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 5px;
            background: #007a5c;
            height: 3px;
            width: calc(100% - 10px);
            padding: 0 5px;
          }

          &.ant-tabs-tab-active {
            &::before {
              background-color: #007a5c;
            }
          }
          .ant-tabs-tab-btn {
            width: 100%;
            flex-grow: 1;
          }
        }
      }
    }
  }
}

.express-completed-tab {
  color: #6db0a0;
}

.express-sidebar-row {
  padding: 0 15px;
  .option-title {
    display: block;
    padding: 0;
    margin-bottom: 10px;
    font-size: 12px;
    font-family: "Noto Sans";
    font-weight: 600;
    color: #202223;
    &:hover {
      cursor: default;
      opacity: 1;
    }
  }
  .ant-switch-small {
    min-width: 21px;
    height: 14px;
    line-height: 14px;
    width: 21px;
    .ant-switch-handle {
      left: 1px;
      top: 1px;
    }
    &.ant-switch-checked {
      background-color: #007a5c;
      .ant-switch-handle {
        left: calc(100% - 12px - 1px);
      }
    }
  }
  .color-box {
    width: 18px;
    flex: 0 0 23px;
    height: 18px;
  }
}

.express-layout-widgets {
  margin-bottom: 30px;
  .widget {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: calc(50% - 10px);
    min-height: 340px;
    margin: 0 10px;
    border: 2px solid transparent;
    cursor: pointer;

    img {
      margin-bottom: 50px;
    }

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.6);
    }
    &.active {
      border: 2px solid #007a5c;
    }
  }
}

.express-input {
  position: relative;
  .error-line {
    font-size: 10px;
  }
  .ant-dropdown-open {
    .express-disabled {
      border-color: #5ec4a1;
      box-shadow: 0 0 0 1px rgba(58, 183, 143, 0.15);
      border-right-width: 1px;
      outline: 0;
    }
  }
  .ant-select {
    width: 100%;
    height: 36px;
    &.ant-select-single {
      .ant-select-selector {
        height: 36px;
        border-radius: 4px;
        .ant-select-selection-item {
          line-height: 36px;
        }
        .ant-select-selection-placeholder {
          line-height: 36px;
        }
      }
    }
    &.ant-select-single:not(.ant-select-customize-input) {
      .ant-select-selector {
        .ant-select-selection-search-input {
          height: 36px;
        }
      }
    }
  }
  .ant-input {
    border-color: #e1e3e5;
    font-size: 13px;
    line-height: 26px;
    border-radius: 4px;
    font-weight: 400;
    color: #202223;
    &:focus,
    .ant-input-focused {
      border-color: #5ec4a1;
      box-shadow: 0 0 0 1px rgb(58 183 143 / 15%);
      border-right-width: 1px;
      outline: 0;
    }
  }
  .ant-input-affix-wrapper {
    padding: 0;
    .ant-input {
      padding: 4px 11px;
    }
  }
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    &:not(.ant-input-affix-wrapper-disabled) {
      border-color: #5ec4a1;
      box-shadow: 0 0 0 1px rgb(58 183 143 / 15%);
      border-right-width: 1px;
      outline: 0;
    }
  }
}

.express-type {
  margin-top: 10px;
  margin-bottom: 30px;
  text-align: center;
  font-size: 13px;
  color: #202223;
}

.express-type-highlight {
  display: inline-block;
  background-color: #ffe5b2;
  padding: 0 2px;
  border-radius: 4px;
}

.express-widget-title {
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
}

.express-widget-description {
  min-height: 42px;
  font-size: 13px;
  font-weight: 400;
  text-align: center;
}

.widget-small {
  max-width: 500px;
  margin: auto;
}

.express-layout-sidebar {
  .option-content {
    label {
      font-size: 12px;
      font-weight: 400;
    }
  }
  .ant-tabs-tab {
    &.ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        font-weight: 600;
        color: #202223;
      }
    }
    .ant-tabs-tab-btn {
      font-size: 12px;
      padding: 0 10px;
      color: #202223;
    }
    + .ant-tabs-tab {
      margin-left: 10px;
    }
  }

  .ant-tabs-nav-list {
    .ant-tabs-ink-bar {
      height: 4px;
      border-radius: 12px;
      background-color: #007a5c;
    }
  }
}

.express-template-layout {
  position: relative;
  flex-grow: 1;
  height: calc(100% - 75px);
  width: calc(100% - 30px);
  margin: 30px auto;
  .template-layout {
    height: 100%;
  }
  &.mobile {
    max-width: 300px;
    margin: 30px auto;
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #00000010;
    z-index: 0;
    pointer-events: none;
  }
}

.modal-express {
  &.ant-modal {
    .modal-title {
      font-family: "Noto Sans";
      font-weight: 500;
      font-size: 15px;
      color: #202223;
      letter-spacing: 0.15px;
    }
    &.modal-default {
      .ant-modal-body {
        padding: 30px 35px;
      }
    }
    .ant-modal-content {
      border-radius: 4px;
    }
  }
}

.express-modal-confirm {
  &.ant-modal-confirm {
    .ant-modal-body {
      padding: 30px 35px;
      .ant-modal-confirm-body-wrapper {
        .ant-modal-confirm-title {
          font-family: "Noto Sans";
          font-weight: 500;
          font-size: 15px;
          color: #202223;
          letter-spacing: 0.15px;
        }
        .ant-modal-confirm-content {
          font-family: "Noto Sans";
          font-weight: 400;
          font-size: 13px;
          color: #202223;
        }
      }
    }
    .ant-modal-confirm-btns {
      button {
        min-height: 36px;
        line-height: 36px;
        height: 36px;
        border: none;
        font-family: "Noto Sans";
        box-shadow: 0 1px 0 #d4d4d4;

        &:focus,
        &:visited {
          opacity: 0.8;
          box-shadow: none !important;
        }
      }
    }
  }
}

.modal-placement-delete {
  text-align: center;
  margin: 20px auto 0;
  span {
    font-size: 13px;
    color: #63686a;
    cursor: pointer;
    &:hover {
      opacity: 0.6;
    }
  }
}

.express-onboard-offers {
  display: flex;
  .switch-wrap {
    padding: 0 10px;
    &--express {
      padding: 0 10px 0 0;
    }
  }
}

.eontitle {
  display: block;
  margin: 5px 0 5px 0;
  font-weight: 600;
  font-size: 13px;
  line-height: 17.71px;
  color: #007a5c;
  cursor: pointer;
  &--black {
    color: #202223;
  }
  i {
    margin-left: 10px;
    font-weight: 400;
    font-style: italic;
    font-size: 12px;
    line-height: 16.34px;
    color: #63686a;
  }
}

.eoncontent {
  // margin-bottom: 5px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16.34px;
  color: #63686a;
}
.eoninfo {
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 13px;
  line-height: 16.34px;
  color: #63686a;
}
.eonlink {
  font-weight: 400;
  font-size: 12px;
  line-height: 16.34px;
  color: var(--active-color);
  cursor: pointer;
  margin-left: 5px;
  text-decoration: underline;
}

.express-offers-tabs {
  .ant-tabs-tab {
    padding: 0 12px 12px 12px;
    color: #63686a;
    font-family: Noto Sans;
    font-size: 15px;
    font-weight: 500;
    line-height: 20.43px;
    letter-spacing: 0.01em;

    & + .ant-tabs-tab {
      margin: 0 0 0 20px;
    }
  }

  &.ant-tabs {
    .ant-tabs-tab {
      .ant-tabs-tab-btn {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}

.express-navtabs {
  .ant-tabs-tab {
    padding: 12px 18px;
    font-size: 13px;
    color: #202223;
    & + .ant-tabs-tab {
      margin: 0;
    }
  }
  .ant-tabs-extra-content {
    margin-right: 20px;
  }

  &.ant-tabs {
    .ant-tabs-tab {
      &.ant-tabs-tab-active {
        color: #202223;
        .ant-tabs-tab-btn {
          font-size: 14px;
          font-weight: 400;
          color: #202223;
          opacity: 1;
        }
      }
      .ant-tabs-tab-btn {
        font-size: 14px;
        font-weight: 400;
        opacity: 0.85;
      }
    }
    .ant-tabs-ink-bar {
      background: #007a5c;
    }
  }
}

.layout-lock {
  position: relative;
  text-align: center;
  &::before {
    content: "";
    position: absolute;
    width: 50px;
    height: 1px;
    background-color: #ddd;
    left: calc(50% - 25px);
    top: 10px;
    z-index: 1;
  }
  .lock-left-border {
    position: absolute;
    height: 5px;
    width: 1px;
    background: #ddd;
    left: calc(50% - 25px);
    top: 5px;
  }
  .lock-right-border {
    position: absolute;
    height: 5px;
    width: 1px;
    background: #ddd;
    right: calc(50% - 25px);
    top: 5px;
  }
  svg {
    position: relative;
    padding: 0 5px;
    width: 26px;
    background-color: #fff;
    cursor: pointer;
    z-index: 2;
    &:hover {
      path {
        fill: #000;
      }
    }
    path {
      fill: #aaa;
    }
  }
}

.express-auth-header {
  display: flex;
  border-bottom: 1px solid #e1e3e5;
  position: fixed;
  width: 100%;
  top: 0;
  background: white;
  z-index: 100;
  button {
    margin: 15px 15px;
    border: none;
    &.start {
      background-color: #007a5c;
    }
    &.stop {
      background-color: #cf343d;
    }
  }
}

.express-navlist {
  flex-grow: 1;
  li {
    display: inline-block;
    a {
      display: block;
      padding: 15px;
      font-size: 13px;
      font-weight: 400;
      line-height: 33px;
      border-bottom: 3px solid transparent;
      letter-spacing: 0.13px;
      &.active {
        font-weight: 500;
        border-bottom: 3px solid #007a5c;
      }
    }
  }
}

.express-logo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

.express-page {
  margin-top: 60px;
  padding: 30px;
}

.partners-options {
  display: flex;
  margin: 0;
  .partners-content {
    flex-grow: 1;
  }
}

.d-grid-box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
  .d-grid-insight {
    grid-template-columns: repeat(2, 1fr);
  }
}

.d-box {
  position: relative;
  display: flex;
  margin: 0;
  .express-onboard-offers {
    margin: 0;
  }
  .home-widget {
    flex-grow: 1;
    .widget-title {
      font-size: 15px;
      font-weight: 500;
      font-family: "Noto Sans";
      justify-content: flex-start;
      .anticon {
        margin-left: 10px;
        max-width: 15px;
      }
    }
  }
  .table-wrapper {
    table {
      th,
      tr td {
        font-family: "Noto Sans";
      }
    }
  }
}

.d-box-content {
  flex-grow: 1;
  min-height: 200px;
  display: flex;
  flex-direction: column;
}

.d-box-icon {
  min-width: 80px;
  position: absolute;
  width: 80px;
  right: 20px;
  top: 30px;
}

.d-icon-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  background-color: #f6f6f7;
  border-radius: 100%;
}

.d-box-title {
  margin-bottom: 5px;
  margin-right: 80px;
  font-size: 15px;
  font-weight: 500;
  .anticon {
    margin-left: 5px;
    cursor: pointer;
  }
}

.d-box-subtitle {
  margin-bottom: 15px;
  margin-right: 80px;
  font-size: 12px;
  font-weight: normal;
  color: #63686a;
}

.d-box-description {
  font-size: 13px;
  margin-right: 80px;
  font-weight: normal;
}

.d-box-big {
  display: block;
  font-size: 18px;
  font-weight: bold;
}

.d-box-button {
  margin-top: 50px;
}

.d-box-space-b {
  display: flex;
  justify-content: space-between;
}

.d-box-table {
  margin-top: 30px;
  .table-wrapper {
    padding: 0;
    margin-bottom: 0;
  }
}

.d-box-list {
  li {
    margin-bottom: 5px;
    font-size: 12px;
    color: #63686a;

    span {
      color: #202223;
    }
    &::before {
      content: "";
      display: inline-block;
      margin-right: 5px;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      vertical-align: middle;
    }
    &.success {
      &::before {
        background-color: #007a5c;
      }
    }
    &.warning {
      &::before {
        background-color: #ff782d;
      }
    }
  }
}

.d-box-payment {
  img {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
  span {
    font-size: 12px;
    text-decoration: underline;
  }
  .dropdown-label {
    margin-right: 0;
  }
}

.express-content-flex {
  display: flex;
  max-width: 100%;
  .widget {
    flex-grow: 1;
    margin-right: 30px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.ant-modal {
  &.modal-full-width {
    left: 0;
    top: 0;
    max-width: none;
    border-radius: 0;
    height: 100vh;
    box-shadow: none;
    animation-duration: 0s !important;
    .ant-modal-content {
      background-color: #f6f6f7;
    }

    .ant-modal-body {
      max-width: 1200px;
      margin: auto;
      padding: 40px 75px;
    }

    .ant-modal-content {
      left: 0;
      top: 0;
      width: 100vw;
      box-shadow: none;
      border-radius: 0;
      transform: none;
      height: 100vh;
      overflow: auto;
    }
    .ant-modal-close-x {
      display: flex;
      width: 50px;
      height: 50px;
      line-height: 50px;
      margin-top: 10px;
      margin-right: 10px;
      background: #fff;
      border-radius: 100%;
      .icon-remove {
        width: 16px;
        height: 16px;
        margin: auto;
      }
    }
  }
}

.express-layout-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  .layout-name {
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: 500;
  }
  .layout-url {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-weight: 400;
  }
  .layout-globe-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    background-color: #fff;
    width: 22px;
    height: 22px;
    border-radius: 22px;
    svg {
      width: 14px;
      height: 14px;
    }
  }
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #007a5c;
}

.ant-tabs-ink-bar {
  background-color: #007a5c;
}

.preview-button {
  cursor: pointer;

  .green-text {
    font-family: Noto Sans;
    font-size: 13px;
    font-weight: 500;
    line-height: 17.71px;
    color: #007a5c;
  }
}

.rocket {
  margin: 60px 0 90px 0;
}

.golive-btn-arrow {
  display: flex;
  position: relative;
  cursor: pointer;
}

.golive-button {
  border-radius: 6px 0 0 6px !important;
}

.golive-dropdown {
  position: absolute;
  bottom: 36px;
  left: 0;
  z-index: 1;
  width: 100%;
  background-color: #fff;
  font-family: Noto Sans;
  font-size: 13px;
  font-weight: 400;
  line-height: 17.71px;
  color: #000;
  border-radius: 4px;
  div {
    padding: 10px;
  }
}

.golive-button-arrow {
  display: flex;
  align-items: center;
  justify-self: center;
  padding: 0;
  width: 36px;
  height: 36px;
  background-color: #007a5c;
  border-radius: 0 6px 6px 0;
  border-left: 1px solid white;
  .golive-button-icon {
    width: 100%;
  }
}

.golive-modal {
  &-title {
    font-family: Noto Sans;
    font-size: 15px;
    font-weight: 700;
    line-height: 20.43px;
    text-align: left;
    padding: 15px 20px;
    border-bottom: 1px solid #e1e3e5;
  }

  &-desc {
    font-family: Noto Sans;
    font-size: 15px;
    font-weight: 400;
    line-height: 22.5px;
    text-align: left;
    margin-bottom: 15px;
  }

  &-confirm {
    font-family: Noto Sans;
    font-size: 13px;
    font-weight: 500;
    line-height: 17.71px;
    text-align: left;
  }

  .express-button-modal {
    margin-top: 60px;
  }
}

.express-layout {
  margin-bottom: 90px;
}

.express-details {
  margin-bottom: 90px;
}

@media screen and (max-width: 1100px) {
  .express-content-flex {
    display: block;
    max-width: 724px;
    .widget {
      margin-right: 0;
    }
  }
}

@import url(./_responsive.scss);
