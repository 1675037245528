.content-insight {
  .widget {
    .widget-title {
      display: block;
      .anticon {
        display: inline-block;
        vertical-align: unset;
      }
      .anticon svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.chart-container {
  position: relative;
}

.chart-container-loading {
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgb(255 255 255 / 80%);
    z-index: 2;
  }
  .anticon-loading {
    position: absolute;
    left: calc(50% - 7.5px);
    top: calc(50% - 7.5px);
  }
}

.cursor {
  cursor: pointer;
}

.sales-tooltip {
  background-color: transparent;
  color: black;

  &-content {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 20px;

    &-item {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      &-title {
        font-size: 18px;
        margin: 0;
        padding: 0;
      }
      &-subtitle {
        font-size: 16px;
        margin: 0 0 0 10px;
        padding: 0;
      }
      &-count {
        margin: 0 0 0 20px;
        padding: 0;
        font-size: 14px;
      }
    }
  }
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  // border-bottom-color: white;
}
