.card {
  .items {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 60px;

    .item {
      display: flex;
      flex-basis: calc(50% - 10px);
      justify-content: flex-start;
      padding: 16px 30px 0px 16px;
      border-radius: var(--border-radius-large);
      border: 2px solid var(--border-color);

      &.active {
        border-color: var(--active-color);
        background-color: var(--active-background-color);
      }

      .check {
        width: 16px;
        height: 16px;
        margin-right: 11px;
        margin-top: 5px;
        cursor: pointer;

        &--active {
          display: flex;
          justify-content: center;
          align-items: center;

          background-color: var(--active-color);
          border-radius: 2px;
        }

        &--inactive {
          background-color: #e1e1e1;
          border-radius: 2px;
        }
      }

      .affiliate-wrapper {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        .affiliate-header {
          display: flex;
          margin-bottom: 18px;
          align-items: center;

          .affiliate-image {
            max-width: fit-content;
            border-radius: 12px;
            border: 1px solid #dbdbdb;
            padding: 10px 43px;
            img {
              width: 152px !important;
              height: 66px !important;
              object-fit: contain;
            }
          }

          .affiliate-desc {
            color: #292426;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-left: 16px;
          }

          .affiliate-input {
            input[type="number"]::-webkit-outer-spin-button,
            input[type="number"]::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }

            /* Hide the spinner in number input for Firefox */
            input[type="number"] {
              -moz-appearance: textfield;
            }
          }
        }
      }
    }
  }

  .comission {
    display: flex;
    justify-content: space-between;
    width: 50%;
    .item {
      width: 50%;
      margin-right: 20px;

      .comission--input {
        input[type="number"]::-webkit-outer-spin-button,
        input[type="number"]::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Hide the spinner in number input for Firefox */
        input[type="number"] {
          -moz-appearance: textfield;
        }
      }

      .comission--widget {
        position: relative;
      }
    }
  }
}

.percentage {
  position: absolute;
  top: 28%;
  right: 15%;
}
