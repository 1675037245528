.template-filters {
  display: flex;
  align-self: flex-end;
}

.template-filter {
  margin-left: 20px;
}

.template-layout-sidebar {
  flex: 0 0 255px;
  padding: 20px 0;
  border-right: 1px solid #e1e3e5;
}

.template-layout-preview {
  flex-grow: 1;
  padding: 20px 0;
}

.plus-template-layout {
  position: relative;
  flex-grow: 1;
  height: calc(100% - 75px);
  width: calc(100% - 30px);
  margin: 30px auto;
}

.template-layout-header {
  display: flex;
  margin-bottom: 25px;
}

.interaction-wrapper {
  width: 100%;
}

.select-template {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.modal-layout-allocation {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 30px;
  label {
    margin-right: 10px;
  }
}

.advance-options-inputs {
  position: relative;
  .label-error {
    right: 5px;
    bottom: 5px;
    left: auto;
    font-size: 11px;
  }
}
