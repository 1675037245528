// Scc for devices smaller than desktop only
@media only screen and (max-width: 1200px) {
  .unauth-widget {
    padding: 50px;
  }
  .unauth-container {
    width: calc(100% - 80px);
  }
}

// Scss for tablet devices
@media only screen and (max-width: 992px) {
  .unauth-sidebar {
    display: none;
  }
  .unauth-container {
    width: calc(100% - 50px);
  }
}

// Scss for mobile devices
@media only screen and (max-width: 768px) {
  .unauth-widget {
    padding: 50px 15px;
  }
  .unauth-container {
    width: calc(100% - 30px);
  }
  .footer {
    flex-direction: column;
  }

  .widget-input {
    &.unauth-password-input {
      margin-bottom: 40px;
    }
  }
  .unauth-container-wrapper {
    &.unauth-login {
      .unauth-widget {
        padding: 50px 15px;
      }
    }
  }
}

// Scss for very small devices
@media only screen and (max-width: 430px) {
}

// Scc for desktop only {
@media only screen and (min-width: 992px) {
}
