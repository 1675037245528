.flex-third {
  flex: 0 0 33.33%;
}

.template-settings {
  .flex-third {
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
  }
}
