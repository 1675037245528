// Scc for devices smaller than desktop only
@media only screen and (max-width: 1200px) {
}

// Scss for tablet devices
@media only screen and (max-width: 992px) {
}

// Scss for mobile devices
@media only screen and (max-width: 768px) {
  .adv-body {
    .adv-product-actions,
    .adv-shopping-actions {
      flex: 0 0 60px;
    }

    .adv-shopping-actions {
      display: none;
    }
    .adv-page ul li {
      display: none;
      width: fit-content;
      margin-bottom: 10px;
      &.active {
        display: block;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    .adv-header-menu ul,
    .adv-header-cart {
      display: none;
    }
    .adv-product-image {
      flex: 0 0 50px;
      margin-right: 10px;
    }
  }
}

// Scss for very small devices
@media only screen and (max-width: 430px) {
}

// Scc for desktop only {
@media only screen and (min-width: 992px) {
}
