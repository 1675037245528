body {
  margin: 0;
  font-family: Poppins-Regular;
  font-size: 15px;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  border-radius: var(--border-radius-medium);
  overflow: hidden;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--secondary-color);
  border-radius: var(--border-radius-medium);
  overflow: hidden;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--border-color);
  border-radius: var(--border-radius-medium);
  overflow: hidden;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background: var(--default-icon-color);
}

.express,
.modal-express {
  img {
    max-width: 100%;
  }

  ul {
    padding: 0;
    margin: 0;
  }

  li {
    list-style: none;
  }

  a {
    text-decoration: none;
    color: inherit;
    &:hover {
      color: inherit;
    }
  }

  h1 {
    font-size: 64px;
    font-family: Poppins-SemiBold;
  }

  h2 {
    font-size: 40px;
    font-family: Poppins-Regular;
  }

  h3 {
    font-size: 30px;
    font-family: Poppins-SemiBold;
  }

  h4 {
    font-size: 21px;
    font-family: Poppins-SemiBold;
  }

  small {
    font-family: Poppins-Regular;
    font-size: 13px;
  }

  label {
    font-size: 13px;
  }

  input {
    outline: none;
  }

  input[type="color"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: none;
    border: none;
    cursor: pointer;

    &::-webkit-color-swatch-wrapper {
      padding: 0;
      border: none;
    }

    &::-moz-color-swatch-wrapper {
      padding: 0;
      border: none;
    }

    &::-moz-color-swatch {
      padding: 0;
      border: 1px solid var(--border-color);
      border-radius: var(--border-radius-small);
    }

    &::-webkit-color-swatch {
      padding: 0;
      border: 1px solid var(--border-color);
      border-radius: var(--border-radius-small);
    }
  }

  button.ant-btn,
  .ant-btn {
    min-height: 40px;
    line-height: 40px;
    height: 40px;
    padding: 0 20px;
    font-size: 12px;
    font-weight: normal;
    font-family: Poppins-Semibold;
    outline: inherit;
    text-shadow: none;
    border-radius: var(--border-radius-medium);

    &.button-small {
      min-height: 30px;
      line-height: 30px;
      height: 30px;
    }

    &.button-wide {
      min-width: 145px;
    }

    &.button-nopadding {
      padding: 0;
    }

    &:not(:disabled) {
      &.button-success {
        background-color: var(--success-color);
        border-color: var(--success-color);
        color: var(--background-color);
      }
      &.button-active {
        background-color: var(--active-color);
        border-color: var(--active-color);
        color: var(--background-color);
        span {
          color: var(--background-color);
        }
      }

      &.button-semi-active {
        background-color: var(--active-background-color);
        border-color: var(--active-background-color);
        color: var(--active-color);
        span {
          color: var(--active-color);
        }
      }

      &.button-danger {
        background-color: var(--danger-color);
        border-color: var(--danger-color);
        color: var(--background-color);
        span {
          color: var(--background-color);
        }
      }
      &.button-default {
        background-color: var(--default-color);
        border-color: var(--default-color);
        color: var(--stop-color);
        &.thin {
          font-weight: normal;
          font-family: Poppins-Regular;
        }
        &.fail {
          color: var(--fail-color);
          span {
            color: var(--fail-color);
          }
        }
        span {
          color: var(--stop-color);
        }
        box-shadow: none;
      }
    }
  }

  .button-icon {
    .icon {
      background-size: contain;
      vertical-align: middle;
    }
    img {
      margin-right: 5px;
      margin-left: 5px;
    }
    .icon {
      margin-right: 5px;
      margin-left: 5px;
    }
  }

  .button-dropdown {
    min-height: 40px;
    line-height: 38px;
    height: 40px;
    padding: 0 20px;
    font-size: 12px;
    font-family: Poppins-Semibold;
    outline: inherit;
    border-radius: var(--border-radius-medium);
    background-color: var(--background-color);
    color: var(--primary-color);
    cursor: pointer;
    .button-icon {
      .icon {
        margin-right: 0;
        margin-left: 10px;
      }
    }
  }

  .dropdown-medium {
    width: 300px;
  }

  .dropdown-arrow {
    margin-left: 5px;
  }

  .text-center {
    text-align: center;
  }

  .flex {
    display: flex;
  }

  .flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .flex-align-center {
    display: flex;
    align-items: center;
  }

  .flex-align-start {
    display: flex;
    align-items: flex-start;
  }

  .flex-center-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .flex-space-between {
    display: flex;
    justify-content: space-between;
  }

  .alignself-baseline {
    align-self: baseline;
  }

  .grow {
    flex-grow: 1;
  }

  .vertical-middle {
    vertical-align: middle;
  }

  .widget-double {
    display: flex;
    justify-content: center;
    align-items: center;
    .widget {
      width: 50%;
    }
  }

  .widget-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .widget-table {
    margin-top: 10px;
    .table-wrapper {
      padding: 0;
    }
  }

  .widget {
    padding: 30px;
    &--small {
      padding: 15px 30px;
    }
    &-shopify {
      padding: 20px;
    }
    background-color: var(--background-color);
    border-radius: var(--border-radius-large);
    overflow: hidden;

    .widget-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      font-family: Poppins-SemiBold;
      font-size: 21px;
      line-height: 21px;
      color: var(--primary-color);
    }
    .widget-description {
      font-size: 14px;
      font-family: Poppins-Regular;
      color: var(--font-color);
    }

    &.widget-max {
      max-width: 700px;
      margin: auto;
    }
  }

  .widget-icon {
    display: flex;
    flex-direction: column;
    .widget-content {
      margin: 15px 40px 0 50px;
    }
    .widget-form-description {
      margin-bottom: 0;
      text-align: left;
    }
    .ant-collapse-item {
      &:not(.ant-collapse-item-active) {
        .icon-minus {
          display: none;
        }
      }
      &.ant-collapse-item-active {
        .icon-plus {
          display: none;
        }
      }
    }
  }

  .widget-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .widget-info-row {
    display: flex;
    padding: 15px;
    font-size: 14px;
    background-color: #fff5ef;
    align-items: center;
    justify-content: space-between;
    border-radius: var(--border-radius-medium);
    input {
      background-color: var(--background-color);
    }
  }

  .widget-side-close {
    display: flex;
    justify-content: flex-end;
    width: 40px;
    height: 52px;
    align-items: center;
  }

  .widget-close-box {
    width: 20px;
    height: 20px;
    background-color: var(--secondary-color);
    border-radius: 100%;
  }

  .widget-s-title {
    font-size: 21px;
    line-height: 31px;
    font-family: Poppins-SemiBold;
    color: var(--primary-color);
    margin: 0;
  }

  .widget-switch-row {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
  }

  .widget-icon-box {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--border-radius-large);
    background-color: var(--secondary-color);
  }

  .capitalize {
    text-transform: capitalize;
  }

  .break-all {
    word-break: break-all;
  }

  .break-word {
    word-break: break-word;
  }

  .o-auto {
    overflow: auto;
  }

  .font-xsmall {
    font-size: 11px;
  }

  .semibold {
    font-family: Poppins-Semibold;
  }

  .italic {
    font-family: Poppins-Italic;
  }

  .font-label-active {
    color: var(--success-color);
  }

  .font-label-warning {
    color: var(--warning-color);
  }

  .font-label-info {
    color: ver(--label-active-font);
  }

  .font-active {
    color: var(--active-color);
  }

  .font-color {
    color: var(--font-color);
  }

  .font-primary {
    color: var(--primary-color);
  }

  .font-warning {
    color: var(--warning-color);
  }

  .font-error {
    color: var(--danger-color);
  }

  .font-success {
    color: var(--success-color);
  }

  .font-small {
    font-size: 14px;
  }

  .font-disabled {
    cursor: not-allowed;
  }

  .font-hover {
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }

  .loading-overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    z-index: 9999;
    background-color: #ffffff42;
  }

  .no-pointer-event {
    pointer-events: none;
  }

  .label-fixed {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 75px;
  }

  .label-active {
    padding: 0 10px;
    font-size: 13px;
    font-family: Poppins-Medium;
    color: var(--active-color);
    background-color: var(--label-background-color);
    border-radius: var(--border-radius-medium);
  }

  .label-warning {
    padding: 0 10px;
    font-size: 13px;
    font-family: Poppins-Medium;
    color: var(--warning-color);
    background-color: var(--label-background-color);
    border-radius: var(--border-radius-medium);
  }

  .label-default {
    padding: 0 10px;
    font-size: 13px;
    font-family: Poppins-Medium;
    color: var(--font-color);
    background-color: var(--default-color);
    border-radius: var(--border-radius-medium);
  }

  .label {
    font-size: 11px;
    font-family: Poppins-Medium;
    &.disabled {
      opacity: 0.8;
      cursor: not-allowed;
    }
  }

  .label-select {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 62px;
    padding: 3px 0;
    color: var(--font-color);
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius-medium);
    cursor: pointer;
    &.active {
      color: var(--success-color);
      border-color: var(--success-color);
      background-color: var(--default-color);
      cursor: default;
    }
    &:hover {
      border-color: var(--success-color);
    }
    &--danger {
      &:hover {
        transition: border-color 0.2s ease-in;
        border-color: var(--danger-color);
      }
    }
  }

  .label-success {
    padding: 5px 0;
    color: var(--success-color);
    border: 1px solid var(--default-color);
    background-color: var(--default-color);
    border-radius: var(--border-radius-medium);
  }

  .label-fail {
    padding: 5px 0;
    color: var(--stop-color);
    border: 1px solid var(--default-color);
    background-color: var(--default-color);
    border-radius: var(--border-radius-medium);
  }

  .label-inactive {
    padding: 5px 15px;
    margin: 0 5px;
    color: var(--font-disabled-color);
    border: 1px solid var(--border-color);
    background-color: var(--background-color);
    border-radius: var(--border-radius-medium);
  }

  .error {
    color: var(--stop-color);
  }

  .min70 {
    min-width: 70px;
  }

  .min50 {
    min-width: 50px;
  }

  .min80 {
    min-width: 80px;
  }

  .min100 {
    min-width: 100px;
  }

  .min150 {
    min-width: 150px;
  }

  .min200 {
    min-width: 200px;
  }

  .minh50 {
    min-height: 50px;
  }

  .minh70 {
    min-height: 70px;
  }

  .minh100 {
    min-height: 100px;
  }

  .max100 {
    max-width: 100px;
  }

  .max200 {
    max-width: 200px;
  }

  .mleft30 {
    margin-left: 30px;
  }

  .mleft20 {
    margin-left: 20px;
  }

  .mleft10 {
    margin-left: 10px;
  }

  .mleft5 {
    margin-left: 5px;
  }

  .mright5 {
    margin-right: 5px;
  }

  .mright10 {
    margin-right: 10px;
  }

  .mright20 {
    margin-right: 20px;
  }

  .mright30 {
    margin-right: 30px;
  }

  .mbot5 {
    margin-bottom: 5px;
  }

  .mbot10 {
    margin-bottom: 10px;
  }

  .mbot15 {
    margin-bottom: 15px;
  }

  .mbot20 {
    margin-bottom: 20px;
  }

  .mbot30 {
    margin-bottom: 30px;
  }

  .mtop5 {
    margin-top: 5px;
  }

  .mtop10 {
    margin-top: 10px;
  }

  .mtop20 {
    margin-top: 20px;
  }

  .mtop30 {
    margin-top: 30px;
  }

  .mtop40 {
    margin-top: 40px;
  }

  .mtop50 {
    margin-top: 50px;
  }

  .pleft10 {
    padding-left: 10px;
  }

  .pright10 {
    padding-right: 10px;
  }

  .ptop20 {
    padding-top: 20px;
  }

  .pbot20 {
    padding-bottom: 20px;
  }

  .ptop10 {
    padding-top: 10px;
  }

  .pbot10 {
    padding-bottom: 10px;
  }

  .ptop15 {
    padding-top: 15px;
  }

  .pbot15 {
    padding-bottom: 15px;
  }

  .semibold {
    font-family: Poppins-SemiBold;
  }

  .bold {
    font-family: Poppins-Bold;
  }

  .normal {
    font-family: Poppins;
    font-size: 14px;
    color: var(--primary-color);
  }

  .start-capital {
    text-transform: capitalize;
  }

  .default-input {
    width: 100%;
    padding: 10px 15px;
    font-size: 12px;
    line-height: 15px;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius-medium);
    background-color: transparent;
    outline: none;
    &:focus,
    &:hover {
      outline: none;
    }
    &.nostyle {
      padding: 5px 0;
      border: 0;
      min-height: 24px;
    }
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      opacity: 0.3;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      opacity: 0.3;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      opacity: 0.3;
    }
  }

  .max-4lines {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @supports (-webkit-line-clamp: 4) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }
  }

  .max-3lines {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @supports (-webkit-line-clamp: 3) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }

  .max-2lines {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @supports (-webkit-line-clamp: 2) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .max-1line {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @supports (-webkit-line-clamp: 1) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }

  .error-line {
    position: absolute;
    left: 0;
    margin: 0;
    padding: 0;
    min-height: 20px;
    margin-bottom: 5px;
    font-size: 12px;
    color: var(--fail-color);
    font-family: Poppins-Regular;
  }

  .label-error {
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 12px;
    line-height: 12px;
    color: var(--fail-color);
    text-align: right;
  }

  .error-show {
    display: block;
  }

  .error-hide {
    display: none;
  }

  .relative {
    position: relative;
  }

  .multiple-label-input {
    display: flex;
    justify-content: space-between;

    .label-input-wrapper {
      flex-basis: calc(50% - 7px);
      width: auto;
    }
  }

  .prefix-label-input {
    display: flex;
    justify-content: space-between;

    .label-input-wrapper {
      &:first-child {
        flex-basis: 160px;
        margin-right: 14px;
        width: auto;
      }
      &:last-child {
        flex-grow: 1;
        width: auto;
      }
    }
  }

  .label-input-wrapper {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 12px;
    background-color: var(--secondary-color);
    label {
      display: block;
      font-family: Poppins-Regular;
    }
    &.disabled {
      opacity: 0.5;
    }
    &.invalid {
      box-shadow: 0 3px 4px -2px var(--fail-light-color);
    }
    .error-line {
      display: inline-block;
      font-size: 10px;
      right: 19px;
      top: 5px;
      left: auto;
    }
  }

  .label-wrapper {
    margin-bottom: 20px;
  }

  .label-wrapper-title {
    margin-bottom: 5px;
    font-size: 14px;
    color: var(--font-color);
  }

  .label-box-wrapper {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    text-align: center;
    margin-bottom: 15px;
    &.disabled {
      .label-box {
        cursor: not-allowed;
        opacity: 0.4;
      }
    }
  }

  .label-box {
    display: grid;
    align-items: center;
    justify-content: center;
    height: 50px;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius-large);
    cursor: pointer;
    &:hover {
      border-color: var(--active-color);
    }
    &.active {
      background-color: var(--active-background-color);
      border-color: var(--active-color);
      color: var(--active-color);
      font-family: "Poppins-SemiBold";
    }
  }

  .label-input-dropdown {
    input {
      pointer-events: none;
    }
    label {
      cursor: pointer;
    }
    .icon-arrow-down {
      position: absolute;
      right: 15px;
      top: 28px;
    }
  }

  .label-input-name {
    position: absolute;
    left: 19px;
    top: 5px;
    font-family: Poppins-Regular;
    font-size: 12px;
    color: var(--font-color);
  }

  .label-input-select {
    .label-input {
      padding: 22px 30px 8px 20px;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
    }
  }

  .label-input {
    width: 100%;
    font-size: 14px;
    line-height: 14px;
    height: 50px;
    padding: 22px 20px 8px 20px;
    font-family: Poppins-Regular;
    color: var(--primary-color);
    background-color: transparent;
    border: none;
    box-shadow: none;
    outline: none;
  }

  .label-normal {
    font-size: 14px;
    .icon-info {
      vertical-align: middle;
    }
  }

  .widget-input {
    width: 100%;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 12px;
    background-color: var(--secondary-color);

    &.widget-input-flat {
      margin-bottom: 0;
    }

    &.widget-input-invalid {
      box-shadow: 0 3px 4px -2px var(--fail-light-color);
    }

    textarea {
      width: 100%;
      font-size: 14px;
      min-height: 100px;
      max-height: 250px;
      padding: 15px 20px;
      font-family: Poppins-Regular;
      color: var(--primary-color);
      background-color: transparent;
      border: none;
      box-shadow: none;
      outline: none;
    }

    input {
      width: 100%;
      font-size: 14px;
      height: 50px;
      padding: 5px 20px;
      font-family: Poppins-Regular;
      color: var(--primary-color);
      background-color: transparent;
      border: none;
      box-shadow: none;
      outline: none;
    }
  }

  .widget-form-title {
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 5px;
    font-family: Poppins-SemiBold;
    text-align: center;
  }

  .widget-form-description {
    margin-bottom: 30px;
    font-size: 14px;
    color: var(--font-color);
    font-family: Poppins-Regular;
    text-align: center;
  }

  .widget-form-info {
    margin-bottom: 15px;
    font-size: 12px;
    color: var(--success-color);
    font-family: Poppins-Regular;
  }

  .widget-form-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      min-height: 40px;
      padding: 0 20px;
    }
  }

  .widget-form-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .widget-form-row {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .widget-form-row-inline {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
    .widget-input {
      margin-bottom: 0;
      margin-right: 10px;
      margin-left: 10px;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }

      input {
        height: 60px;
        width: 100%;
        text-align: center;
        font-size: 30px;
      }
    }
  }

  .widget-input-devider {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
    span {
      display: block;
      width: 14px;
      height: 2px;
      background-color: var(--font-disabled-color);
    }
  }

  .widget-multiple-form-row {
    display: flex;
    justify-content: space-between;
    .widget-form-row {
      flex-basis: calc(50% - 7px);
    }
    &.widget-form-row-single {
      justify-content: center;
    }
  }

  .modal-content {
    margin: 0 -22px;
    padding: 0 24px;
    max-height: 400px;
    overflow-y: auto;
    .table-wrapper {
      padding: 0;
    }
  }
  .modal-content-table {
    margin: 0 30px;
  }
  .modal-content-space {
    margin: 30px 0;
  }

  .modal-color-row {
    padding: 20px;
    margin-bottom: 15px;
    background-color: var(--secondary-color);
    border-radius: var(--border-radius-large);
  }

  .modal-tabs {
    .ant-tabs-nav {
      margin: -10px -50px 20px;
      .ant-tabs-nav-wrap {
        padding: 0 50px;
      }
      .ant-tabs-tab {
        margin-left: 0;
        padding: 12px 20px;
        &.ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: var(--primary-color);
          }
        }

        .ant-tabs-tab-btn {
          font-family: Poppins;
          font-weight: 600;
          color: var(--font-color);
        }
      }
    }
  }

  .modal-label {
    font-family: Poppins;
    font-weight: 400;
    font-size: 14px;
    color: var(--font-color);
  }

  .modal-inputrow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px 10px 20px;
    background-color: var(--background-color);
    border-radius: var(--border-radius-large);
    &.modal-inputrow-disabled {
      opacity: 0.5;
    }
  }

  .modal-title {
    margin: 0 auto 10px auto;
    font-family: Poppins-Semibold;
    font-size: 21px;
    text-align: center;
    color: var(--primary-color);
  }
  .modal-description {
    margin: 0 auto 30px auto;
    font-family: Poppins-Regular;
    font-size: 14px;
    text-align: center;
    color: var(--font-color);
  }
  .modal-line {
    display: flex;
    padding: 10px 0;
    border-top: 1px solid var(--border-color);
  }

  .modal-line-title {
    flex: 0 0 25%;
    padding-right: 20px;
    font-family: Poppins-Semibold;
    font-size: 13px;
    color: var(--primary-color);
  }

  .modal-line-action {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    .modal-line-edit {
      display: flex;
      margin-left: 10px;
      vertical-align: top;
      padding: 3px 5px;
      font-size: 11px;
      border: 1px solid var(--border-color);
      border-radius: var(--border-radius-medium);
      color: var(--font-color);
      &:hover {
        border-color: var(--active-color);
        cursor: pointer;
      }
    }
  }

  .modal-line-description {
    font-family: Poppins-Regular;
    font-size: 13px;
    color: var(--primary-color);
    flex-grow: 1;
    max-width: calc(100% - 250px);
    .modal-line-description-main {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      @supports (-webkit-line-clamp: 1) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
    .modal-line-description-extra {
      display: block;
      color: var(--font-color);
      font-size: 12px;
    }
  }

  .modal-line-button {
    padding-top: 10px;
    border-top: 1px solid var(--border-color);
  }

  .modal-confirm-label {
    font-size: 14px;
    font-family: Poppins-Regular;
    margin-bottom: 10px;
    span {
      display: block;
    }
  }

  .table-wrapper {
    padding: 15px 25px;
    margin-bottom: 30px;
    border-radius: var(--border-radius-medium);
    background-color: var(--background-color);

    &--modal {
      padding: 0;
      margin-bottom: 0;
    }

    &.table-wrapper-tabs {
      padding: 0 25px 15px;
      .content-list {
        border-bottom: 1px solid var(--border-color);
      }
    }

    &.table-scoll-wrapper {
      padding: 15px 5px;
      .table-scrollable {
        padding: 0 20px;
      }
      .table-title {
        padding: 0 20px;
      }
    }
    table {
      position: relative;
      width: 100%;
      .table-filter {
        position: relative;
        cursor: pointer;
        &.down {
          &::before {
            background-image: url("../../../assets//images/icons/arrow-down-icon.svg");
            background-position: bottom;
          }
        }
        &.up {
          &::before {
            background-image: url("../../../assets//images/icons/arrow-up-icon.svg");
          }
        }
        &::before {
          content: "";
          display: inline-block;
          width: 15px;
          height: 15px;
          margin-right: 5px;
          background-image: url("../../../assets//images/icons/filter-arrows-icon.svg");
          background-position: center;
          background-repeat: no-repeat;
        }
        .anticon {
          position: absolute;
          right: 5px;
          top: calc(50% - 7px);
        }
      }

      .table-row-open {
        td {
          border-bottom: 0;
          &.table-link {
            color: var(--primary-color);
            font-family: Poppins-Semibold;
            svg {
              path {
                fill: var(--stop-color);
              }
            }
          }
        }
      }

      .table-row-stop {
        td {
          &:not(.table-link) {
            color: var(--stop-color);
          }
        }
      }

      &.table-child {
        th {
          border-bottom: 1px solid var(--border-color);
        }
        tr {
          td {
            padding-top: 8px;
            padding-bottom: 8px;
            border-bottom: none;
          }
          &:last-child {
            td {
              border-bottom: none;
            }
          }
        }
        .ant-empty-normal {
          margin-bottom: 12px;
        }
      }

      th {
        font-family: Noto Sans;
        font-size: 13px;
        font-weight: 400;
        line-height: 17.71px;
        color: #63686a;
        border-bottom: 1px solid #e1e3e5;
        padding: 8px;
        text-align: left;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
      td {
        padding: 10px 8px;
        vertical-align: middle;
        font-family: Noto Sans;
        font-size: 13px;
        font-weight: 400;
        line-height: 17.71px;
        text-align: left;
        color: #202223;

        .advertiser-name {
          display: flex;
          align-items: center;
        }

        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
      tr {
        border-bottom: 1px solid var(--border-color);
        &:last-child {
          td {
            border-bottom: none;
          }
        }
        td {
          &.table-child-wrap {
            padding: 0 30px 20px 30px;
            border-bottom: 3px solid var(--border-color);
          }
        }
      }
    }
  }
  .tbody-fixed {
    display: block;
    max-height: 500px;
    overflow: auto;
    tr {
      display: table;
    }
  }
  .table-title {
    margin-bottom: 10px;
    font-size: 15px;
    font-family: Poppins-SemiBold;
    &--express {
      font-family: Noto Sans;
      font-weight: 500;
      line-height: 20.43px;
      letter-spacing: 0.01em;
    }
  }

  .table-image {
    width: 30px;
    height: 30px;
    object-fit: cover;
    object-position: center;
    border-radius: 6px;
    &.table-image-big {
      width: 40px;
      height: 40px;
    }
  }

  .loader-absolute {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(255 255 255 / 80%);
    z-index: 2;
    .loader-td {
      height: auto;
    }
  }

  .loader-td {
    position: relative;
    height: 165px;
  }

  .table-scrollable {
    position: relative;
    overflow-y: auto;
    min-height: 340px;
    max-height: 340px;
    table {
      position: initial;
      thead {
        position: sticky;
        width: 100%;
        left: 0;
        top: 0;
        background-color: var(--background-color);
        box-shadow: none;
        tr {
          th {
            min-width: 80px;
          }
        }
      }
    }
  }

  .table-campaign-daily {
    max-height: 500px;
    width: 100%;
    .loader-absolute {
      max-height: 500px;
    }
  }

  .table-offers {
    max-height: 360px;
    min-height: 360px;
  }

  .td-100 {
    width: 100px;
    overflow-x: auto;
  }

  .td-150 {
    width: 150px;
    overflow-x: auto;
  }

  .td-200 {
    width: 200px;
    overflow-x: auto;
  }

  .td-250 {
    width: 250px;
    overflow-x: auto;
  }

  .td-300 {
    width: 300px;
    overflow-x: auto;
  }

  .td-min-100 {
    min-width: 100px;
  }

  .table-actions {
    display: flex;
    align-items: center;
  }

  .tabindex {
    outline-color: var(--border-color);
    border-radius: var(--border-radius-large);
  }

  .dropdown-icon {
    display: inline-flex;
    align-items: center;
    svg {
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }
  }

  .table-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;
    &.icon-remove {
      margin-left: 30px;
    }
  }

  .table-icon-bordered {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    padding: 5px;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius-medium);
    color: var(--font-color);
    cursor: pointer;
    &:hover {
      border-color: var(--danger-color);
      color: var(--danger-color);
    }
  }

  .icon-remove-square {
    width: 16px;
    height: 16px;
    background-image: url("../../../assets/images/icons/remove-square-icon.svg");

    &:hover {
      cursor: pointer;
      background-image: url("../../../assets/images/icons/remove-square-active-icon.svg");
    }
  }

  .icon-bookmark {
    background-image: url("../../../assets/images/icons/bookmark-icon.svg");
    height: 14px;
    &:hover {
      background-image: url("../../../assets/images/icons/bookmark-active-icon.svg");
    }
  }

  .icon-terms {
    background-image: url("../../../assets/images/icons/terms-icon.svg");
    height: 14px;
    &:hover {
      background-image: url("../../../assets/images/icons/terms-active-icon.svg");
    }
  }

  .icon-start {
    background-image: url("../../../assets/images/icons/start-icon.svg");
    height: 14px;
  }

  .icon-stop {
    background-image: url("../../../assets/images/icons/stop-icon.svg");
    height: 14px;
  }

  .table-link {
    color: var(--active-color);
    cursor: pointer;
    &:hover {
      color: var(--active-color);
      opacity: 0.7;
    }
    svg {
      path {
        fill: var(--active-color);
      }
    }
  }

  .table-hover {
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }

  .table-toggle {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  .pagination {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
    color: var(--font-color);
    font-size: 14px;
    font-family: Poppins-Regular;
    border-bottom: 1px solid var(--border-color);
    ul {
      display: flex;
      align-items: center;
      padding: 0;
      margin: 0;
      li {
        margin: 0 5px;
        cursor: pointer;
        &:not(.active) {
          &:hover {
            opacity: 0.7;
          }
        }
        &.active {
          span {
            color: var(--background-color);
            background-color: var(--success-color);
          }
        }
      }
    }
    .icon-pagination {
      margin: 0 10px;
    }
  }

  .pagination-next,
  .pagination-prev {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }

  .pagination-next {
    right: 0;
    .pagination-box {
      background-image: url("../../../assets/images/icons/arrow-right-icon.svg");
    }
  }

  .pagination-prev {
    left: 0;
    .pagination-box {
      background-image: url("../../../assets/images/icons/arrow-left-icon.svg");
    }
  }

  .pagination-label {
    margin: 0 10px;
  }

  .pagination-box {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 26px;
    height: 26px;
    padding: 0 5px;
    background-repeat: no-repeat;
    background-color: var(--background-color);
    background-position: center;
    border-radius: var(--border-radius-medium);
  }

  .dropdown-label {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    color: var(--font-color);
    margin-right: 15px;
    &--express {
      font-family: Noto Sans;
      font-size: 13px;
      font-weight: 400;
      line-height: 17.71px;
      text-align: left;
      color: #202223;
      margin-right: 0;
    }

    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
    .label-count {
      margin-left: 5px;
      color: var(--primary-color);
    }
    &::after {
      content: "";
      display: inline-block;
      width: 10.5px;
      height: 6px;
      margin-left: 10px;
      background-image: url("../../../assets//images/icons/arrow-down-icon.svg");
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .dropdown-search {
    position: relative;
    margin-bottom: 20px;
    input {
      padding-left: 35px;
      padding-right: 35px;
    }
    .search-icon {
      position: absolute;
      top: 10px;
      left: 10px;
      color: var(--font-disabled-color);
      font-size: 16px;
    }
    .close-icon {
      position: absolute;
      text-align: center;
      width: 20px;
      height: 20px;
      right: 10px;
      top: 8px;
      .icon {
        display: block;
        width: 8px;
        height: 8px;
        margin-top: 6px;
        margin-left: 6px;
      }
      background-color: var(--secondary-color);
      border-radius: 100%;
      cursor: pointer;
    }
  }

  .vertical-tabs {
    display: flex;
    align-items: center;
    .vertical-tab-content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
    }

    .vertical-tab-list {
      flex: 0 0 50px;
      ul {
        position: relative;
        margin: 10px 0;
        height: 50px;
        &::before {
          content: "";
          position: absolute;
          width: 4px;
          height: 100%;
          left: 0;
          top: 0;
          background-color: var(--secondary-color);
          border-radius: var(--border-radius-medium);
        }

        &::after {
          content: "";
          position: absolute;
          width: 4px;
          height: 50%;
          left: 0;
          top: 0;
          background-color: var(--success-color);
          border-radius: var(--border-radius-medium);
          transition: top 0.3s;
        }
        &.bottom {
          &::after {
            top: 50%;
          }
          .list-bottom {
            color: var(--primary-color);
          }
        }
        &.top {
          &::after {
            top: 0;
          }
          .list-top {
            color: var(--primary-color);
          }
        }
        li {
          position: relative;
          display: flex;
          align-items: center;
          height: 25px;
          margin: 0 0px 5px 10px;
          font-size: 12px;
          line-height: 12.5px;
          color: var(--font-color);
          padding-left: 10px;
          cursor: pointer;
        }
      }
    }
  }

  .add-other-offers {
    width: 100%;
    font-family: Noto Sans;
    font-size: 13px;
    font-weight: 500;
    line-height: 17.71px;
    text-align: center;
    color: #007a5c;
    cursor: pointer;
    margin: 25px 0 10px 0;
  }

  .express-empty {
    border: none !important;
    .ant-empty {
      margin: 30px 0 10px 0;
    }
  }

  .status-active {
    color: #007a5c !important;
  }

  .filter-dropdown {
    padding: 8px 15px;
    border: 1px solid #e1e3e5;
    border-radius: 4px;
    margin-right: 12px;
  }

  .cpa-active {
    color: #007a5c;
  }

  .dropdown-cpa {
    cursor: pointer;
    ul {
      cursor: pointer;
    }
  }

  .express-button-modal {
    margin-top: 10px;
    text-align: right;
    button {
      min-height: 36px;
      line-height: 36px;
      height: 36px;
      border: none;
      font-family: "Noto Sans";
      box-shadow: 0 1px 0 #d4d4d4;
      min-width: 100px;
      &:focus,
      &:visited {
        box-shadow: none;
      }
      span {
        font-size: 13px;
        font-weight: 500;
      }
    }
  }
}
