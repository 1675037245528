@font-face {
  font-family: Poppins-Black;
  src: url(../fonts/Poppins/Poppins-Black.ttf) format("truetype");
}

@font-face {
  font-family: Poppins-BlackItalic;
  src: url(../fonts/Poppins/Poppins-BlackItalic.ttf) format("truetype");
}

@font-face {
  font-family: Poppins-Bold;
  src: url(../fonts/Poppins/Poppins-Bold.ttf) format("truetype");
}

@font-face {
  font-family: Poppins-BoldItalic;
  src: url(../fonts/Poppins/Poppins-BoldItalic.ttf) format("truetype");
}

@font-face {
  font-family: Poppins-Light;
  src: url(../fonts/Poppins/Poppins-Light.ttf) format("truetype");
}

@font-face {
  font-family: Poppins-LightItalic;
  src: url(../fonts/Poppins/Poppins-LightItalic.ttf) format("truetype");
}

@font-face {
  font-family: Poppins-Medium;
  src: url(../fonts/Poppins/Poppins-Medium.ttf) format("truetype");
}

@font-face {
  font-family: Poppins-MediumItalic;
  src: url(../fonts/Poppins/Poppins-MediumItalic.ttf) format("truetype");
}

@font-face {
  font-family: Poppins-Regular;
  src: url(../fonts/Poppins/Poppins-Regular.ttf) format("truetype");
}

@font-face {
  font-family: Poppins-Italic;
  src: url(../fonts/Poppins/Poppins-Italic.ttf) format("truetype");
}

@font-face {
  font-family: Poppins-SemiBold;
  src: url(../fonts/Poppins/Poppins-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: Poppins-Thin;
  src: url(../fonts/Poppins/Poppins-Thin.ttf) format("truetype");
}

@font-face {
  font-family: Poppins-ThinItalic;
  src: url(../fonts/Poppins/Poppins-ThinItalic.ttf) format("truetype");
}
