.frame {
  align-items: flex-start;
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px;
  position: relative;

  .text-wrapper {
    color: #292426;
    font-family: "Poppins-SemiBold", Helvetica;
    font-size: 21px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
  }

  .title {
    color: #292426;
    font-family: "Poppins-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: 1035px;
  }

  .rectangle {
    background-color: var(--background-color);
    height: 4px;
    position: relative;
    width: 51px;
  }

  .div-2 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-wrap: wrap;
    gap: 20px 20px;
    position: relative;
    width: 100%;

    .group {
      background-color: #f2f2f2;
      border-radius: 12px;
      height: 40px;
      position: relative;
      min-width: 160px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      padding: 0 30px;
    }

    .line {
      align-items: center;
      border-radius: 12px;
      display: inline-flex;
      gap: 4px;
      justify-content: center;
      padding: 2px 12px;
      position: relative;
    }

    .text-wrapper-2 {
      font-family: "Poppins-Regular", Helvetica;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: normal;
      margin-top: -1px;
      position: relative;
      width: fit-content;
    }

    .text-wrapper-3 {
      color: var(--background-color);
      font-family: "Poppins-Regular", Helvetica;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: normal;
      margin-top: -1px;
      position: relative;
      width: fit-content;
    }

    .line-2 {
      align-items: center;
      background-color: #48c8ff;
      border-radius: 12px;
      display: inline-flex;
      gap: 10px;
      justify-content: center;
      padding: 2px 12px;
      position: relative;
    }

    .line-3 {
      align-items: center;
      background-color: #3ab78f;
      border-radius: 12px;
      display: inline-flex;
      gap: 4px;
      justify-content: center;
      padding: 2px 12px;
      position: relative;
    }

    .line-4 {
      align-items: center;
      background-color: #ffa048;
      border-radius: 12px;
      display: inline-flex;
      gap: 10px;
      justify-content: center;
      padding: 2px 12px;
      position: relative;
    }

    .line-5 {
      align-items: center;
      background-color: #ff4848;
      border-radius: 12px;
      display: inline-flex;
      gap: 10px;
      justify-content: center;
      padding: 2px 12px;
      position: relative;
    }

    .rectangle-2 {
      background-color: #f2f2f2;
      height: 1px;
      position: relative;
      width: 160px;
    }
  }

  .rectangle-3 {
    align-self: stretch;
    background-color: #d6dae5;
    height: 1px;
    position: relative;
    width: 100%;
  }

  .text-wrapper-4 {
    color: #292426;
    font-family: "Poppins-SemiBold", Helvetica;
    font-size: 21px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
  }

  .line-wrapper {
    border: 2px dashed;
    border-color: #cfd3df;
    border-radius: 12px;
    height: 40px;
    position: relative;
    width: 160px;
    display: flex;
    align-items: center;
  }

  .line-6 {
    align-items: center;
    border-radius: 12px;
    display: inline-flex;
    gap: 4px;
    justify-content: center;
    padding: 2px 12px;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .plus {
    height: 20px;
    position: relative;
    width: 20px;
  }
}

.ant-drawer-content {
  border-radius: 12px 0px 0px 12px;
  .ant-drawer-header {
    padding: 20px 28px;
  }
  .ant-drawer-header-title {
    flex-direction: row-reverse;
    .ant-drawer-title {
      color: #292426;
      font-feature-settings: "clig" off, "liga" off;
      font-family: Poppins;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .ant-drawer-close {
      margin-right: 0;
      width: 16px;
      height: 16px;
      color: #a3a8b9;
    }
  }
}

.drawer-content {
  padding: 0 4px;
  .drawer {
    &-paragraph {
      color: var(--font-color);
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 20px;
    }
    &-action {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #dbdbdb;
      border-radius: 12px;
      padding: 11px 19px;
      height: fit-content;
      margin-bottom: 20px;

      .info {
        span {
          color: var(--font-color);
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      &-color {
        display: flex;
        align-items: center;
        .hex {
          margin-right: 13px;
          color: #000;
          text-align: right;
          font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: uppercase;
        }

        .box {
          width: 28px;
          height: 28px;
          border-radius: 3px;
        }
      }
    }
    &-delete {
      padding: 16px 12px;
      color: #ff3c5f;
      font-family: "Poppins-SemiBold", Helvetica;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: normal;
      width: fit-content;
      cursor: pointer;
    }
  }
}
