.preview-desktop {
  .preview-wrapper {
    background-color: rgba(0, 0, 0, 0.2588235294);
    &.left {
      justify-content: flex-start;
    }
    &.right {
      justify-content: flex-end;
    }
    &.top {
      align-items: flex-start;
    }
    &.bottom {
      align-items: flex-end;
    }
    &.topLeft {
      justify-content: flex-start;
      align-items: flex-start;
    }
    &.topRight {
      justify-content: flex-end;
      align-items: flex-start;
    }
    &.bottomLeft {
      justify-content: flex-start;
      align-items: flex-end;
    }
    &.bottomRight {
      align-items: flex-end;
      justify-content: flex-end;
    }
  }

  .preview-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: auto;
  }
  // SWIPER
  .preview-popup {
    position: relative;
    padding: 20px 15px;
    background-color: #fff;
    border: 1px solid transparent;
    box-shadow: 0 0 9px 0px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    font-family: "Poppins";
    box-shadow: 0 0 10px -4px #0000009e;
    max-width: 974px;
  }

  .preview-minimize {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    border-radius: 100%;
    background-color: #e2e2e2;
    cursor: pointer;
    svg {
      width: 10px;
    }
    &:hover {
      background-color: #f2f2f2;
    }
  }

  .preview-title {
    display: flex;
    align-items: center;
    justify-content: center;
    .preview-title-label {
      font-size: 22px;
      display: inline-block;
      vertical-align: middle;
    }
  }

  .preview-title-icon {
    svg {
      max-width: 20px;
      max-height: 20px;
      margin-right: 5px;
    }
  }

  .preview-description {
    text-align: center;
  }

  .preview-swiper {
    position: relative;
    padding: 0 40px;
    // padding: 0 10px;
    overflow: hidden;
    .swiper {
      position: initial;
      padding: 20px 5px;
      .swiper-overflow {
        overflow: hidden;
      }
      .swiper-slide {
        height: auto;
      }
      .swiper-item {
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        .hidden-input {
          display: none;
        }
        &.display {
          & > label {
            cursor: pointer;
            &:hover {
              .switch-text {
                opacity: 0.6;
              }
            }
          }
        }
        & > label {
          display: flex;
          flex-direction: column;
          padding: 20px;
          height: 100%;
          border-radius: 8px;
          border: 1px solid #d6dae5;
          background-color: #ffffff;
        }
      }
      .swiper-pagination-fraction,
      .swiper-pagination-custom,
      .swiper-horizontal > .swiper-pagination-bullets,
      .swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: 0;
      }

      .swiper-button-next {
        width: 34px;
        height: 34px;
        right: 0;
        &::after {
          content: none;
        }

        &:hover {
          opacity: 0.7;
        }
      }

      .swiper-pagination-bullet {
        background-color: #a3a8b9;
      }

      .swiper-button-prev {
        width: 34px;
        height: 34px;
        left: 0;

        &::after {
          content: none;
        }

        &:hover {
          opacity: 0.7;
        }
      }
    }
    .item-image {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 5px;
      img {
        max-width: 100%;
        width: 100%;
        height: 100%;
        margin: auto;
        margin-top: 0;
        border-radius: 8px;
        object-fit: cover;
        object-position: center;
        overflow: hidden;
        pointer-events: none;
        &.image-xsmall {
          width: 50px;
          height: 50px;
        }
        &.image-small {
          width: 90px;
          height: 90px;
        }
        &.image-medium {
          width: 150px;
          height: 150px;
        }
        &.image-large {
          width: 200px;
          height: 200px;
        }
      }
    }

    .item-content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      text-align: center;
    }

    .item-description {
      margin-bottom: 10px;
    }

    .item-get {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }

    .item-get-center {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .item-title {
      font-weight: 600;
      font-size: 15px;
    }

    .item-action-get {
      padding: 6px 12px;
      margin: 0 15px;
      &:hover {
        opacity: 0.7;
        cursor: pointer;
      }
    }

    .item-delay-email {
      display: block;
      margin-bottom: 10px;
      font-size: 13px;
      font-weight: 500;
    }

    .item-share-icon {
      margin-left: 15px;
    }

    .item-info-icon,
    .item-share-icon {
      position: relative;
      display: flex;
      align-items: center;
      min-width: 15px;
      cursor: pointer;
      &:hover {
        opacity: 0.6;
      }
      &::before {
        content: "";
        position: absolute;
        right: -12px;
        top: -10px;
        width: 40px;
        height: 40px;
      }
    }

    .switch-wrapper {
      display: flex;
      margin: 0 15px;
      label {
        display: inline-flex;
        align-items: center;
        vertical-align: middle;
        cursor: pointer;
        .switch-toggle {
          position: relative;
          display: inline-block;
          width: 30px;
          height: 18px;
          margin-right: 8px;
          border-radius: 30px;
          transition: right 0.2s linear;
          background-color: #f1f2f3;
          .switch-toggle-before {
            position: absolute;
            top: 3px;
            right: calc(100% - 13px - 3px);
            width: 12px;
            height: 12px;
            border-radius: 100%;
            background-color: #d7d7d7;
            transition: right 0.2s ease-in-out;
          }
        }
        .switch-text {
          .unchecked-text {
            display: inline-block;
          }
          .checked-text {
            display: none;
            font-weight: 500;
          }
        }
      }
    }

    .hidden-input:checked + .item-layout {
      position: relative;
      border-color: transparent;
      border-color: #3ab78f !important;
      border: 1px solid;
      & .switch-toggle {
        background-color: #3ab78f;
        opacity: 1;
        .switch-toggle-before {
          right: 3px;
          left: auto;
          background-color: #ffffff;
        }
      }
      & .switch-text {
        font-family: inherit;
        font-weight: 400;
        .checked-text {
          display: inline-block;
        }
        .unchecked-text {
          display: none;
        }
      }
      & .selected {
        .button-select {
          display: none;
        }
        .button-selected {
          display: inline-block;
        }
      }
    }
  }

  .item-email-label {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    padding: 10px;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
    .item-email-icon {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 5px;
      border-radius: 100%;
    }
  }

  .item-email-wrapper {
    .item-email-title {
      padding: 5px 0;
      font-size: 13px;
      font-weight: 500;
      text-align: center;
    }
  }

  .item-email-input {
    display: flex;
    align-items: center;
    justify-content: center;
    input {
      all: unset;
      display: block;
      padding: 5px 10px;
      margin-left: 10px;
      font-size: 13px;
      line-height: 18px;
      flex-grow: 1;
      font-weight: 300;
      background-color: #ffffff;
      color: #213038;
      border: 1px solid #ddd;
    }
    .button {
      padding: 6px 12px;
      font-size: 13px;
      line-height: 18px;
      margin: 0 10px;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

  .item-email-terms {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    font-size: 11px;
    label {
      position: relative;
      padding-left: 20px;
      font-size: 11px;
      line-height: 18px;
      a {
        text-decoration: none;
        color: inherit;
        opacity: 0.8;
        &:hover {
          opacity: 0.5;
        }
      }
    }
    .checkbox-custom {
      position: relative;
      visibility: hidden;
      display: none;
      & + .checkboxsvg {
        position: absolute;
        width: 15px;
        height: 15px;
        left: 0;
        top: 1px;
        background-image: url("../images/checkbox-uncheck.svg");
        background-size: contain;
        background-position: center;
      }
      &:hover {
        & + .checkboxsvg {
          opacity: 0.6;
          cursor: pointer;
        }
      }
    }
    .checkbox-custom:checked {
      &:hover {
        & + .checkboxsvg {
          opacity: 0.9;
          cursor: pointer;
        }
      }
      & + .checkboxsvg {
        content: "";
        position: absolute;
        background-image: url("../images/checkbox-checked.svg");
        background-position: center;
      }
    }
  }

  .item-layout,
  .item-title-text,
  .item-image,
  .item-description {
    cursor: pointer;
  }

  .preview-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 15px;
    font-size: 12px;
    svg {
      min-width: 24px;
      margin-right: 10px;
    }
  }

  .max-2lines {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .max-1lines {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}
