// Scc for devices smaller than desktop only
@media only screen and (max-width: 1200px) {

  .content{
    &.campaign-content{
      .content-layout{
        .widget{
          padding: 30px;
        }
      }
    }
  } 
}

// Scss for tablet devices
@media only screen and (max-width: 992px) {
}

// Scss for mobile devices
@media only screen and (max-width: 768px) {
  .auth {
    flex-direction: column-reverse;
    .sidebar {
      height: 60px;
      max-width: 100%;
      width: 100%;
      .logo {
        display: none;
      }
    }

    .sidebar-list {
      display: flex;
      flex-direction: row;
      li {
        flex-grow: 1;
        a {
          padding: 0;
          .list-link {
            width: auto;
            img {
              margin: 0;
            }
          }
        }
        .list-label {
          display: none;
        }
      }
      &.toggler {
        display: none;
      }
    }

    .header {
      padding: 0 15px;
    }
    .content{
      .footer{
        padding: 20px 15px;
      }   
    } 

    .wrapper {
      max-height: calc(100vh - 60px);
    }

    .content-layout {
      padding: 30px 15px;
    }
  }
  .toggle-menu {
    display: none;
  }
}

// Scss for very small devices
@media only screen and (max-width: 430px) {
}

// Scc for desktop only {
@media only screen and (min-width: 992px) {
}
