.email-table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px 0px;
  .title {
    font-size: 20px;
    font-weight: 600;
  }
  .add-template {
    display: flex;
    align-items: center;
    .icon {
      margin-right: 5px;
    }
  }
}

.edit-button {
  color: #72778a;
  text-align: center;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 6px;
  border: 1px solid #d6dae5;
}

.remove-button {
  color: #ff3d60;
  text-align: center;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 6px;
  border: 1px solid #ff3d60;
}

.cancel-btn {
  color: #3ab78f;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 10px;
  border: 1px solid #3ab78f;
  padding: 0px 50px !important;
}

.success-btn {
  border-radius: 10px;
  padding: 0px 50px !important;
}
