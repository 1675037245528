.icon {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.icon-minus {
  width: 11px;
  height: 1.5px;
  background-image: url("../../assets/images/icons/minus-icon.svg");
}

.icon-lock {
  width: 14px;
  height: 16px;
  background-image: url("../../assets/images/icons/lock-icon.svg");
}

.icon-info {
  width: 12px;
  height: 12px;
  background-image: url("../../assets/images/icons/info-icon.svg");
}

.icon-email {
  width: 16px;
  height: 12px;
  background-image: url("../../assets/images/icons/email-icon.svg");
}

.icon-share {
  width: 16px;
  height: 15px;
  background-image: url("../../assets/images/icons/share-icon.svg");
}

.icon-settings {
  width: 18px;
  height: 18px;
  background-image: url("../../assets/images/icons/cookies-icon.svg");
}

.icon-plus {
  width: 11px;
  height: 11px;
  background-image: url("../../assets/images/icons/plus-icon.svg");
}

.icon-remove {
  width: 10.8px;
  height: 10.8px;
  background-image: url("../../assets/images/icons/remove-icon.svg");
}

.icon-user-image {
  width: 24px;
  height: 24px;
  background-image: url("../../assets/images/icons/user-image-icon.svg");
}

.icon-giftbox {
  width: 44px;
  height: 44px;
  background-image: url("../../assets/images/icons/gift-box-icon.svg");
}

.icon-remove {
  width: 10.8px;
  height: 10.8px;
  background-image: url("../../assets/images/icons/remove-icon.svg");
}

.icon-pagination {
  width: 15px;
  height: 3px;
  background-image: url("../../assets/images/icons/pagination-dots-icon.svg");
}

.icon-fourdots {
  width: 11px;
  height: 11px;
  background-image: url("../../assets/images/icons/fourdots-icon.svg");
}

.icon-layout-template {
  width: 12px;
  height: 12px;
  background-image: url("../../assets/images/icons/layout-template-icon.svg");
  &.active {
    background-image: url("../../assets/images/icons/layout-template-active-icon.svg");
  }
}

.icon-layout-settings {
  width: 12px;
  height: 12px;
  background-image: url("../../assets/images/icons/layout-settings-icon.svg");
  &.active {
    background-image: url("../../assets/images/icons/layout-settings-active-icon.svg");
  }
}

.icon-stop {
  background-image: url("../../assets/images/icons/stop-active-icon.svg");
}

.icon-arrow-down {
  width: 10.5px;
  height: 6px;
  background-image: url("../../assets//images/icons/arrow-down-icon.svg");
}

.icon-message {
  width: 14px;
  height: 14px;
  background-image: url("../../assets//images/icons/message-icon.svg");
}

.icon-close-circle {
  width: 14px;
  height: 14px;
  background-image: url("../../assets//images/icons/close-circle-icon.svg");
}

.icon-desktop {
  width: 14.31px;
  height: 12px;
  background-image: url("../../assets//images/icons/desktop-icon.svg");
  &.active {
    background-image: url("../../assets//images/icons/desktop-active-icon.svg");
  }
}

.icon-mobile {
  width: 8.79px;
  height: 12.56px;
  background-image: url("../../assets//images/icons/mobile-icon.svg");
  &.active {
    background-image: url("../../assets//images/icons/mobile-active-icon.svg");
  }
}

.filter-asc {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-image: url("../../assets//images/icons/arrow-down-icon.svg");
  background-repeat: no-repeat;
}

.filter-desc {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-image: url("../../assets//images/icons/arrow-up-icon.svg");
  background-repeat: no-repeat;
}
