.bold-text {
  color: var(--primary-color);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.copy-wrapper {
  position: relative;
  display: flex;
  width: 100%;
  height: 230px;

  .copy-textarea {
    width: 100%;
    border-radius: 12px;
    border: 1px solid #dbdbdb;
    background: var(--secondary-color);
    padding: 12px 22px;
    color: var(--primary-color);
    font-family: "Courier New";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    resize: none;
    overflow: auto;
  }

  .copy-button {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .copy-image {
    margin-right: 5px;
  }
}
