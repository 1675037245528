@font-face {
  font-family: "Noto Sans";
  font-weight: 400;
  src: url(../../../assets/fonts/Noto_Sans/NotoSans-Regular.ttf)
    format("truetype");
}

@font-face {
  font-family: "Noto Sans";
  font-weight: 500;
  src: url(../../../assets/fonts/Noto_Sans/NotoSans-Medium.ttf)
    format("truetype");
}
