.onboarding-header {
  ul {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    li {
      position: relative;
      margin-right: 10px;
      flex-grow: 1;
      text-align: center;
      font-size: 13px;
      color: var(--font-disabled-color);
      line-height: 30px;
      pointer-events: none;
      &:last-child {
        margin-right: 0;
      }
      img {
        display: none;
        margin-right: 5px;
      }
      &::after {
        content: "";
        display: block;
        height: 4px;
        width: 100%;
        background-color: var(--font-disabled-color);
        border-radius: var(--border-radius-large);
      }
      &.current-step {
        color: var(--primary-color);
        &::after {
          background-color: var(--primary-color);
        }
      }
      &.completed-step {
        color: var(--success-color);
        &::after {
          background-color: var(--success-color);
        }
        img {
          display: inline-block;
        }
      }
    }
  }
}

.onboarding-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}

.unauth-boxes-wrapper {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 30px;
}

.unauth-affiliate-box {
  margin: 0 10px 30px 10px;
}

.user-type {
  .unauth-box {
    flex-basis: calc(50% - 15px);
  }
}
.affiliate {
  .widget-form-error {
    margin-top: 10px;
  }
  .unauth-label {
    margin-bottom: 0;
  }
  .unauth-box {
    flex-basis: calc(50% - 15px);
    &:last-child {
      margin-right: 0;
    }
  }
}

.unauth-link {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 30px;
}

.unauth-box {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin-right: 15px;
  border-radius: var(--border-radius-large);
  border: 2px solid var(--border-color);
  cursor: pointer;
  &.active {
    border-color: var(--active-color);
    background-color: var(--active-background-color);
  }
  &:last-child {
    margin-right: 0;
  }
}

.box-content {
  min-height: 44px;
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
  color: var(--primary-color);
}

.box-image {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 110px;
}

.unauth {
  .label-warning,
  .label-active {
    margin-right: 10px;
    margin-bottom: 10px;
    & + span {
      display: inline-block;
      margin-bottom: 10px;
    }
  }
}

.unauth-box-active {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 15px;
  right: 15px;
  width: 30px;
  height: 30px;
  background-color: var(--active-color);
  border-radius: 100%;
}

.no-data {
  margin-bottom: 30px;
}
