.adv-body {
  background-color: var(--secondary-color);
  font-size: 12px;
  min-height: 100%;
  width: 100%;
  &.overflow {
    overflow: auto;
  }
}

.compact-preview-wrapper {
  background-color: rgba(0, 0, 0, 0.2588235294);
}

.adv-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  //   height: 5vh;
  height: 30px;
  //   padding: 0 2vw;
  padding: 0 15px;
  background-color: var(--background-color);
}
.adv-browser-line {
  //   height: 5vh;
  height: 20px;
}
.adv-header-menu {
  display: flex;
  ul {
    // margin-right: 5vw;
    margin-right: 30px;
    li {
      display: inline-block;
      //   margin-left: 2vw;
      margin-left: 10px;
    }
  }
}
.adv-page {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  //   padding: 2vh 2vw;
  padding: 10px 15px;
  > ul {
    // margin-bottom: 2vh;
    margin-bottom: 15px;
    li {
      position: relative;
      display: inline-block;
      //   margin-right: 2vw;
      margin-right: 10px;

      &.active {
        &::before {
          content: "";
          position: absolute;
          left: 0;
          bottom: -4px;
          width: 100%;
          height: 4px;
          background: var(--active-color);
          border-radius: var(--border-radius-medium);
        }
      }
    }
  }
}
.adv-content {
  //   padding: 2vh 2vw;
  padding: 10px 15px;
  background: var(--background-color);
  flex-grow: 1;
  border-radius: var(--border-radius-large);
  overflow: hidden;
}

.adv-product-row {
  display: flex;
  align-items: center;
  max-width: 100%;
  border-bottom: 1px dashed #ddd;
  //   margin-bottom: 1vh;
  margin-bottom: 5px;
}
.adv-product-image {
  //   flex: 0 0 10vw;
  flex: 0 0 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    // max-height: 8vh;
    max-height: 60px;
    object-fit: contain;
  }
}
.adv-product-content {
  display: flex;
  flex-direction: column;
  //   padding: 1vh 1vw;
  padding: 5px 0;
  flex-grow: 1;
  //   height: 15vh;
  height: 100px;
}
.adv-product-actions {
  display: flex;
  justify-content: space-between;
  //   flex: 0 0 20vw;
  flex: 0 0 150px;
  //   padding: 1vh 1vw;
  padding: 5px 10px;
  //   height: 15vh;
  height: 100px;
}
.adv-pre-action {
  display: inline-block;
  border-radius: var(--border-radius-large);
  background-color: var(--secondary-color);
  //   margin-bottom: 1vh;
  //   height: 3vh;
  height: 30px;
  width: 45%;
}
.adv-pre-content {
  display: inline-block;
  border-radius: var(--border-radius-large);
  background-color: var(--secondary-color);
  //   margin-bottom: 1vh;
  //   height: 2vh;
  margin-bottom: 10px;
  height: 20px;
  width: 60%;
  &.large {
    // height: 3vh;
    height: 30px;
    width: 40%;
  }
}
.adv-shopping-buttons {
  display: flex;
  justify-content: space-between;
}
.adv-shopping-actions {
  display: flex;
  justify-content: space-between;
  //   flex: 0 0 20vw;
  padding: 5px 10px;
  flex: 0 0 150px;
  //   padding: 1vh 1vw;
  //   height: 5vh;
  height: 50px;
}
.adv-success-button {
  //   padding: 0.5vh 1.5vw;
  padding: 5px 15px;
  margin-right: 10px;
  //   border-radius: 1vw;
  border-radius: var(--border-radius-large);
  background: var(--success-color);
  color: var(--background-color);
  //   margin-right: 2vw;
}
.adv-buttons {
  display: flex;
  align-items: center;
}

.adv-default-button {
  padding: 5px 15px;
  //   padding: 0.5vh 1.5vw;
  //   border-radius: 1vw;
  border-radius: var(--border-radius-large);
  background: var(--default-color);
}

@import url("./responsive.scss");
