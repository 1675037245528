.preview-wrapper {
  background-color: rgba(0, 0, 0, 0.2588235294);
  &.left {
    justify-content: safe flex-start;
  }
  &.right {
    justify-content: safe flex-end;
  }
  &.top {
    align-items: safe flex-start;
  }
  &.bottom {
    align-items: safe flex-end;
  }
  &.topLeft {
    justify-content: safe flex-start;
    align-items: safe flex-start;
  }
  &.topRight {
    justify-content: safe flex-end;
    align-items: safe flex-start;
  }
  &.bottomLeft {
    justify-content: safe flex-start;
    align-items: safe flex-end;
  }
  &.bottomRight {
    align-items: safe flex-end;
    justify-content: safe flex-end;
  }
}

.preview-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: safe center;
  justify-content: safe center;
  width: 100%;
  height: 100%;
  overflow: auto;
  &.center {
    .preview-popup {
      margin: auto;
    }
  }
  &.topRight {
    .preview-popup {
      margin: 0 0 auto auto;
    }
  }
  &.top {
    .preview-popup {
      margin: 0 auto auto auto;
    }
  }
  &.topLeft {
    .preview-popup {
      margin: 0 auto auto 0;
    }
  }
  &.left {
    .preview-popup {
      margin: auto auto auto 0;
    }
  }
  &.center {
    .preview-popup {
      margin: auto;
    }
  }
  &.right {
    .preview-popup {
      margin: auto 0 auto auto;
    }
  }
  &.bottomRight {
    .preview-popup {
      margin: auto 0 0 auto;
    }
  }
  &.bottom {
    .preview-popup {
      margin: auto auto 0 auto;
    }
  }
  &.bottomLeft {
    .preview-popup {
      margin: auto auto 0 0;
    }
  }
}
// SWIPER
.preview-popup {
  position: relative;
  padding: 20px 35px;
  background-color: #fff;
  border: 1px solid transparent;
  box-shadow: 0 0 9px 0px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  font-family: "Poppins";
  box-shadow: 0 0 10px -4px #0000009e;
}

.preview-popup-sixpack {
  max-width: 1020px;
}

.preview-popup-1 {
  max-width: 450px;
}

.preview-popup-2 {
  max-width: 720px;
}

.preview-minimize {
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  background-color: #e2e2e2;
  cursor: pointer;
  svg {
    width: 10px;
  }
  &:hover {
    background-color: #f2f2f2;
  }
}

.preview-title {
  display: flex;
  align-items: center;
  justify-content: center;
  .preview-title-label {
    font-size: 22px;
    display: inline-block;
    vertical-align: middle;
  }
}

.preview-title-icon {
  svg {
    max-width: 20px;
    max-height: 20px;
    margin-right: 5px;
  }
}

.preview-description {
  text-align: center;
}

.item-info-icon {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 15px;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
  &::before {
    content: "";
    position: absolute;
    right: -12px;
    top: -10px;
    width: 40px;
    height: 40px;
  }
}

.preview-swiper {
  position: relative;
  padding: 0 40px;
  overflow: hidden;

  .swiper {
    position: initial;
    padding: 20px 5px;
    &.swiper-disabled {
      padding-bottom: 0;
    }
    .swiper-overflow {
      overflow: hidden;
    }
    .swiper-slide {
      width: 270px;
      height: auto;
      margin-right: 20px;
      padding: 2px;
    }
    .swiper-item {
      position: relative;
      height: 100%;
      .hidden-input {
        display: none;
      }
      &.display {
        & > label {
          cursor: pointer;
          &:hover {
            .switch-text {
              opacity: 0.6;
            }
          }
        }
      }
      & > label {
        display: flex;
        flex-direction: column;
        padding: 20px;
        height: 100%;
        border-radius: 8px;
        border: 1px solid #d6dae5;
        background-color: #ffffff;
      }
    }
    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal {
      bottom: 0;
    }

    .swiper-button-next {
      width: 34px;
      height: 34px;
      right: 0;
      &::after {
        content: none;
      }

      &:hover {
        opacity: 0.7;
      }
    }

    .swiper-pagination-bullet {
      background-color: #a3a8b9;
    }

    .swiper-button-prev {
      width: 34px;
      height: 34px;
      left: 0;

      &::after {
        content: none;
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }
  .item-image {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    img {
      max-width: 100%;
      width: 100%;
      height: 100%;
      margin: auto;
      margin-top: 0;
      border-radius: 8px;
      object-fit: cover;
      object-position: center;
      overflow: hidden;
      pointer-events: none;
      &.image-xsmall {
        width: 50px;
        height: 50px;
      }
      &.image-small {
        width: 90px;
        height: 90px;
      }
      &.image-medium {
        width: 150px;
        height: 150px;
      }
      &.image-large {
        width: 200px;
        height: 200px;
      }
    }
  }

  .swiper-load-more {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;

    .button-load-more {
      padding: 6px 12px;
      background-color: #383838;
      color: #ffffff;
      font-size: 14px;
      font-weight: 600;
      border-radius: 6px;
      cursor: pointer;
      &:hover {
        opacity: 0.6;
      }
    }
  }

  .item-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    text-align: center;
  }

  .item-description {
    margin-bottom: 10px;
  }

  .item-get {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .item-get-center {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .item-title {
    font-weight: 600;
    font-size: 15px;
  }

  .item-action-get {
    padding: 10px 25px;
    margin: 0 15px;
    flex-grow: 1;
    max-width: 200px;
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }

  .item-delay-email {
    display: block;
    margin-bottom: 10px;
    font-size: 13px;
    font-weight: 500;
  }
}

.switch-wrapper {
  display: flex;
  margin: 0 15px;
  label {
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    cursor: pointer;
    .switch-toggle {
      position: relative;
      display: inline-block;
      width: 30px;
      height: 18px;
      margin-right: 8px;
      border-radius: 30px;
      transition: right 0.2s linear;
      background-color: #f1f2f3;
      .switch-toggle-before {
        position: absolute;
        top: 3px;
        right: calc(100% - 13px - 3px);
        width: 12px;
        height: 12px;
        border-radius: 100%;
        background-color: #d7d7d7;
        transition: right 0.2s ease-in-out;
      }
    }
    .switch-text {
      .unchecked-text {
        display: inline-block;
      }
      .checked-text {
        display: none;
        font-weight: 500;
      }
    }
  }
}

.hidden-input:checked + .item-layout {
  // position: relative;
  // border-color: transparent;
  // border-color: #3ab78f !important;
  // border: 1px solid;
  & .switch-toggle {
    background-color: #3ab78f;
    opacity: 1;
    .switch-toggle-before {
      right: 3px;
      left: auto;
      background-color: #ffffff;
    }
  }
  & .switch-text {
    font-family: inherit;
    font-weight: 400;
    .checked-text {
      display: inline-block;
    }
    .unchecked-text {
      display: none;
    }
  }
  & .selected {
    .button-select {
      display: none;
    }
    .button-selected {
      display: inline-block;
    }
  }
}

.feature-item {
  margin: 10px 0;
  border-left: 1px solid #d6dae5;
  border-right: 1px solid #d6dae5;
  border-bottom: 1px solid #d6dae5;
  border-radius: 10px;
  overflow: hidden;

  .item-title {
    font-weight: 600;
    font-size: 15px;
  }

  .item-email-wrapper {
    .item-email-title {
      text-align: left;
    }
  }

  .item-info-icon {
    margin: 0 10px;
  }

  &.wide {
    margin: 10px 45px;
  }

  .item-layout {
    position: relative;
    display: block;
  }
  .hidden-input {
    display: none;
  }

  .item-image {
    width: 100%;
    height: 300px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .item-content {
    padding: 15px 20px;
    height: 100px;
    display: flex;
  }

  .item-content-info {
    flex-grow: 1;
    margin-right: 10px;
  }

  .item-email {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    background-color: #f2f2f2;

    .label-wrapper {
      position: absolute;
      bottom: -12px;
      left: 50%;
      transform: translate(-50%, 0);
      .label-error {
        font-size: 10px;
      }
    }
  }

  .item-email-wrapper {
    margin-right: 20px;
  }

  .item-action-get {
    padding: 12px 25px;
  }

  .item-get-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 5px;
  }
}

.item-email-label {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  padding: 10px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  .item-email-icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    border-radius: 100%;
  }
}

.item-email-wrapper {
  .item-email-title {
    padding: 0;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
  }
}

.item-email-input {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  input {
    all: unset;
    display: block;
    padding: 10px;
    margin-left: 0;
    font-size: 13px;
    line-height: 18px;
    flex-grow: 1;
    font-weight: 300;
    background-color: #ffffff;
    color: #213038;
    border: 1px solid #ddd;
  }
  .button {
    padding: 10px 24px;
    font-size: 13px;
    line-height: 18px;
    margin: 0 0 0 10px;
    border: none;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

.item-email-terms {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  font-size: 11px;
  label {
    position: relative;
    padding-left: 20px;
    font-size: 11px;
    line-height: 18px;
    a {
      text-decoration: none;
      color: inherit;
      opacity: 0.8;
      &:hover {
        opacity: 0.5;
      }
    }
  }
  .checkbox-custom {
    position: relative;
    visibility: hidden;
    display: none;
    & + .checkboxsvg {
      position: absolute;
      width: 15px;
      height: 15px;
      left: 0;
      top: 1px;
      background-image: url("../images/checkbox-uncheck.svg");
      background-size: contain;
      background-position: center;
    }
    &:hover {
      & + .checkboxsvg {
        opacity: 0.6;
        cursor: pointer;
      }
    }
  }
  .checkbox-custom:checked {
    &:hover {
      & + .checkboxsvg {
        opacity: 0.9;
        cursor: pointer;
      }
    }
    & + .checkboxsvg {
      content: "";
      position: absolute;
      background-image: url("../images/checkbox-checked.svg");
      background-position: center;
    }
  }
}

.item-layout,
.item-title-text,
.item-image,
.item-description {
  cursor: pointer;
}

.preview-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 15px;
  font-size: 12px;
  svg {
    min-width: 24px;
    margin-right: 10px;
  }
}

.max-2lines {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.max-1lines {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
