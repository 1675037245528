.react-grid-item {
  &.react-grid-placeholder {
    background-color: var(--primary-color);
    border-radius: var(--border-radius-large);
  }
}

.react-grid-layout {
  margin: -15px;
  transition: none;
  .react-grid-item {
    transition: none;
  }
}
