.fm-template-wrapper {
  &.left {
    justify-content: flex-start;
  }
  &.right {
    justify-content: flex-end;
  }
  &.top {
    align-items: flex-start;
  }
  &.bottom {
    align-items: flex-end;
  }
  &.topLeft {
    justify-content: flex-start;
    align-items: flex-start;
  }
  &.topRight {
    justify-content: flex-end;
    align-items: flex-start;
  }
  &.bottomLeft {
    justify-content: flex-start;
    align-items: flex-end;
  }
  &.bottomRight {
    align-items: flex-end;
    justify-content: flex-end;
  }
}

.feature-mobile-item {
  margin: 10px;
  border: 1px solid #d6dae5;
  border-radius: 10px;
  overflow: hidden;

  .label-wrapper {
    min-height: auto;
    line-height: 14px;
  }

  .item-get {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .switch-wrapper {
    margin: 0;
  }

  .item-email-wrapper {
    .item-email-title {
      text-align: left;
    }
  }

  .item-description {
    margin-bottom: 10px;
  }

  &.wide {
    margin: 10px 45px;
  }

  .item-layout {
    position: relative;
    display: block;
    overflow: hidden;
    border-radius: 10px;
  }
  .hidden-input {
    display: none;
  }

  .item-image {
    width: 100%;
    height: 140px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .item-description {
    margin-bottom: 10px;
  }

  .item-content {
    padding: 15px 20px;
    min-height: 100px;
  }

  .item-content-info {
    flex-grow: 1;
    margin-right: 10px;
  }

  .item-email {
    position: relative;
    padding: 15px 20px;
    background-color: #f2f2f2;
    border-top: 1px solid #d6dae5;
  }

  .item-email-wrapper {
    margin-right: 0;
  }

  .item-get-center {
    flex-grow: 1;
    text-align: center;
    max-width: 300px;
  }

  .item-action-get {
    padding: 8px 20px;
  }

  .item-get-icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 10px;
    .item-info-icon {
      margin: 5px 10px;
    }
  }

  .item-email-input {
    input {
      margin-left: 0;
    }
  }

  .item-email-terms {
    display: block;
    margin-top: 5px;
    font-size: 11px;
    margin-bottom: 0;
    label {
      position: relative;
      padding-left: 20px;
      font-size: 11px;
      line-height: 18px;
      a {
        text-decoration: none;
        color: inherit;
        opacity: 0.8;
        &:hover {
          opacity: 0.5;
        }
      }
    }
    .checkbox-custom {
      position: relative;
      visibility: hidden;
      display: none;
      & + .checkboxsvg {
        position: absolute;
        width: 15px;
        height: 15px;
        left: 0;
        top: 1px;
        background-image: url("../images/checkbox-uncheck.svg");
        background-size: contain;
        background-position: center;
      }
      &:hover {
        & + .checkboxsvg {
          opacity: 0.6;
          cursor: pointer;
        }
      }
    }
    .checkbox-custom:checked {
      &:hover {
        & + .checkboxsvg {
          opacity: 0.9;
          cursor: pointer;
        }
      }
      & + .checkboxsvg {
        content: "";
        position: absolute;
        background-image: url("../images/checkbox-checked.svg");
        background-position: center;
      }
    }
  }
}

.fm-template-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
}

// SWIPER
.fm-template-popup {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  margin-top: 35px;
  margin-bottom: 35px;
  margin-left: 8px;
  margin-right: 8px;
  max-width: 475px;
  width: calc(100% - 16px);
  height: auto;
  max-height: calc(100% - 70px);
  background-color: #fff;
  border-radius: 12px;
  font-family: "Poppins";
  overflow: auto;
}

.preview-minimize {
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  background-color: #e2e2e2;
  cursor: pointer;
  font-weight: 900;
  svg {
    width: 10px;
  }
  &:hover {
    background-color: #f2f2f2;
  }
}

.fm-template-title {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  .fm-template-title-label {
    font-size: 22px;
    display: inline-block;
    vertical-align: middle;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

.fm-template-title-icon {
  svg {
    max-width: 20px;
    max-height: 20px;
    margin-right: 5px;
  }
}

.fm-template-description {
  text-align: center;
  padding: 0 10px;
}

.fm-template-filters {
  margin: 10px auto;
}

.fm-template-filters-popup-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #0000004d;
  z-index: 100;
}
.fm-template-filters-popup {
  position: absolute;
  width: 100%;
  height: 70%;
  max-height: calc(100% - 50px);
  padding: 25px;
  overflow: auto;
  background-color: #fff;
  border-radius: 12px;
  bottom: 0;
  box-shadow: 0px -4px 8px -5px #0000004d;
}

.fm-template-filter-list {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #d6dae5;
  border-radius: 6px;
  ul {
    padding: 0;
    li {
      list-style: none;
      margin-top: 5px;
      font-size: 14px;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
      .circle {
        display: inline-block;
        height: 10px;
        width: 10px;
        margin-right: 5px;
        border: 1px solid transparent;
        border-radius: 100%;
      }
    }
  }
}

.fm-template-filter-title {
  font-size: 15px;
  font-weight: 500;
}

.fm-template-filter-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  .fm-template-filter-button {
    flex-grow: 1;
    text-align: center;
    padding: 5px 0;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid #939393;
    border-radius: 6px;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
    &:first-child {
      margin-right: 5px;
    }
    &:last-child {
      margin-left: 5px;
    }
  }
}

.fm-template-filters-trigger {
  padding: 5px 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  border-radius: 12px;
  cursor: pointer;
  span {
    margin-left: 5px;
  }
  &:hover {
    opacity: 0.7;
  }
}

.fm-template-swiper {
  position: relative;
  flex-grow: 1;
  .swiper {
    position: initial;
    padding: 7px 10px;
    .swiper-overflow {
      overflow: hidden;
    }
    .swiper-slide {
      height: auto;
    }
    .swiper-item {
      position: relative;
      height: 100%;
      input {
        display: none;
      }
      &.display {
        & > label {
          cursor: pointer;
          &:hover {
            .switch-text {
              opacity: 0.6;
            }
          }
        }
      }
      & > label {
        display: flex;
        flex-direction: column;
        padding: 20px;
        height: 100%;
        border-radius: 8px;
        border: 1px solid #d6dae5;
        background-color: #ffffff;
      }
    }
    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal {
      bottom: 0;
    }

    .swiper-pagination-bullet {
      margin: 0 5px;
    }

    .swiper-navigation {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      .swiper-pagination {
        position: relative;
        width: auto;
        margin: 0 20px;
      }
    }

    .swiper-button-next {
      position: relative;
      width: 30px;
      height: 30px;
      right: 0;
      margin: 0;
      &::after {
        content: none;
      }

      &:hover {
        opacity: 0.7;
      }
    }

    .swiper-button-prev {
      position: relative;
      width: 30px;
      height: 30px;
      left: 0;
      margin: 0;

      &::after {
        content: none;
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }
  .fm-template-item-image {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      min-width: 64px;
      width: 64px;
      height: 64px;
      margin: auto;
      margin-top: 0;
      border-radius: 8px;
      object-fit: cover;
      object-position: center;
      overflow: hidden;
      pointer-events: none;
    }
  }

  .fm-template-item-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-left: 10px;
    min-height: 64px;
  }

  .fm-template-item-description {
    margin-bottom: 5px;
  }

  .fm-template-item-get {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    .compact-item-action-get {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
    }
  }

  .fm-template-item-get-center {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .fm-template-item-title {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
  }

  .fm-template-item-icons {
    display: flex;
    min-width: 75px;
  }

  .fm-template-item-email-icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    min-width: 28px;
    min-height: 28px;
    border: 1px solid transparent;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }

  .fm-template-item-info-icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    min-width: 28px;
    min-height: 28px;
    border-radius: 100%;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }

  .fm-template-item-share-icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    min-width: 28px;
    min-height: 28px;
    border-radius: 100%;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }

  .fm-template-item-action-get {
    width: 100%;
    padding: 5px 7px;
    margin-right: 0;
    border-radius: 6px;
    text-align: center;
    line-height: 16px;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }

  .switch-wrapper {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    margin: 0;
    label {
      display: inline-flex;
      align-items: center;
      vertical-align: middle;
      cursor: pointer;
      .switch-toggle {
        position: relative;
        display: inline-block;
        width: 30px;
        height: 18px;
        margin-right: 8px;
        border-radius: 30px;
        transition: right 0.2s linear;
        background-color: #f1f2f3;
        .switch-toggle-before {
          position: absolute;
          top: 3px;
          right: calc(100% - 13px - 3px);
          width: 12px;
          height: 12px;
          border-radius: 100%;
          background-color: #d7d7d7;
          transition: right 0.2s ease-in-out;
        }
      }
      .switch-text {
        .unchecked-text {
          display: inline-block;
        }
        .checked-text {
          display: none;
          font-weight: 500;
        }
      }
    }
  }

  .fm-template-item-label {
    display: block;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 6px;
    border: 1px solid transparent;
  }

  .fm-template-item-layout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .input:checked + .fm-template-item-label {
    position: relative;
    border-color: transparent;
    border-color: #3ab78f;
    border: 1px solid;

    & .switch-toggle {
      background-color: #3ab78f;
      opacity: 1;
      .switch-toggle-before {
        right: 3px;
        left: auto;
        background-color: #ffffff;
      }
    }
    & .switch-text {
      font-family: inherit;
      font-weight: 400;
      .checked-text {
        display: inline-block;
      }
      .unchecked-text {
        display: none;
      }
    }
    & .selected {
      .button-select {
        display: none;
      }
      .button-selected {
        display: inline-block;
      }
    }
  }
}

.fm-template-footer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  font-size: 12px;
  svg {
    min-width: 24px;
    margin-right: 10px;
  }

  &:hover {
    cursor: pointer;
    .preview-tooltip {
      display: block;
    }
  }
}

.fm-template-item-layout,
.fm-template-item-title-text,
.fm-template-item-image,
.fm-template-item-description {
  cursor: pointer;
}

.preview-tooltip {
  display: none;
  position: absolute;
  max-width: calc(100% - 20px);
  bottom: calc(100% + 10px);
  left: 10px;
  padding: 10px;
  background-color: #fef8de;
  color: #333333;
  z-index: 10;
  &::after {
    content: "";
    position: absolute;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #fef8de;
    bottom: -10px;
    left: calc(50% - 20px);
  }
}

.max-2lines {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.max-1lines {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
