@font-face {
    font-family: 'Currys';
    src: url('./fonts/currys/CurrysSans-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Currys';
    src: url('./fonts/currys/CurrysSans-Headline.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Currys';
    src: url('./fonts/currys/CurrysSans-Headline.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}