@import "./_fonts.scss";

body {
  .tox {
    &::selection{
        background-color: var(--success-color);
    }
    .tox-statusbar__branding {
      display: none;
    }
    .tox-tbtn {
      background-color: var(--secodary-color);
      &:hover {
        background-color: var(--background-color);
      }
    }

    .tox-tinymce {
      font-family: "Poppins";
    }
    .tox-collection--list {
      .tox-collection__item {
        &:hover {
          background-color: var(--secondary-color);
          .tox-collection__item-label {
            > * {
              background-color: var(--secondary-color) !important;
            }
          }
        }
      }
      .tox-collection__item-label {
        > * {
          background-color: var(--background-color) !important;
        }
      }
      .tox-collection__item--enabled {
        background-color: var(--background-color);
      }
      .tox-collection__item--active {
        background-color: var(--background-color);
      }
    }
    .tox-menu {
      background-color: var(--background-color);
    }

    .tox-statusbar {
      background-color: var(--secondary-color);
    }

    &.tox:not(.tox-tinymce-inline) {
      .tox-editor-header {
        background-color: var(--secondary-color);
        .tox-toolbar-overlord {
          background-color: var(--secondary-color);
        }
        .tox-toolbar__primary {
          background-color: var(--secondary-color);
        }
      }
    }
  }
}
