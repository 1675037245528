@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;600;700&display=swap");
@import url(./_curryFont.scss);
@import url(./_newlookFont.scss);
@import url(./_screwfixFont.scss);

.oli-wrapper {
  position: absolute;
  display: flex;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #00000005;
  button {
    position: relative;
    transition: none;
    border: none;
    padding: 0;
    .inner-button {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      overflow: hidden;
      margin: 0 auto;
      padding: 10px 0;
      svg {
        max-width: 50%;
        max-height: 100px;
        margin: 5px auto 5px;
      }
      .inner-button-text {
        padding: 0 15px;
      }
    }
  }
  .button-number {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 24px;
    height: 24px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    border-radius: 100%;
    background-color: #ff0000;
    color: #ffffff;
  }
  &.left,
  &.right {
    .button-wrapper {
      position: relative;
      width: 50px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      // white-space: nowrap;
      button {
        &.no-rotation {
          .inner-button {
            white-space: initial;
          }
        }
        &:not(.no-rotation) {
          position: absolute;
          top: 50%;
          left: 50%;
          height: 50px;
        }
      }
    }
  }

  &.topLeft {
    align-items: flex-start;
    justify-content: flex-start;

    .button-number {
      top: auto;
      bottom: -5px;
    }
  }
  &.left {
    align-items: center;
    justify-content: flex-start;
    button {
      &:not(.no-rotation) {
        transform: translate(-50%, -50%) rotate(-270deg);
      }
    }
  }
  &.topRight {
    align-items: flex-start;
    justify-content: flex-end;

    .button-number {
      top: auto;
      bottom: -5px;
      right: auto;
      left: -5px;
    }
  }
  &.right {
    align-items: center;
    justify-content: flex-end;
    button {
      &:not(.no-rotation) {
        transform: translate(-50%, -50%) rotate(270deg);
      }
    }
  }
  &.bottomRight {
    align-items: flex-end;
    justify-content: flex-end;

    .button-number {
      left: -5px;
      right: auto;
    }
  }
  &.bottom {
    align-items: flex-end;
    justify-content: center;
  }
  &.bottomLeft {
    align-items: flex-end;
    justify-content: flex-start;
  }
  &.top {
    align-items: flex-start;
    justify-content: center;
    .button-number {
      top: auto;
      bottom: -5px;
    }
  }
}
