// Scss for mobile devices
@media only screen and (max-width: 768px) {
  .react-grid-layout {
    margin: -15px;
  }
}

// Scss for tablet devices
@media only screen and (min-width: 768px) and (max-width: 992px) {
}

// Scc for desktop only {
@media only screen and (min-width: 992px) {
}
