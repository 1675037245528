// Scss for mobile devices
@media only screen and (max-width: 768px) {
  .onboarding-header {
    ul {
      max-width: calc(100vw - 30px);
      overflow-y: auto;
      li {
        min-width: 130px;
      }
    }
  }
}
