.preview-f-filter {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  ul {
    display: flex;
    overflow: hidden;
    padding: 0 2.5px;
    margin: 15px 0 0;
    background-color: transparent;
    border: 1px solid #939393;
    border-radius: 6px;
    &.swiper-filters-container {
      position: relative;
      padding: 0 60px;
      .swiper-container {
        position: initial;
      }

      .swiper-button-prev {
        display: block;
        left: 0;
        height: 40px;
        width: 40px;

        &::after {
          content: none;
        }
      }
      .swiper-button-next {
        display: block;
        right: 0;
        height: 40px;
        width: 40px;
        &::after {
          content: none;
        }
      }

      .no-swipe {
        .swiper-button-prev,
        .swiper-button-next {
          display: none;
        }
      }
    }

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      border-radius: 6px;
      overflow: hidden;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      border-radius: 6px;
      background-color: #939393; /* Scrollbar thumb (the draggable part) */
      border-radius: 10px; /* Roundness of the thumb */
      overflow: hidden;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background-color: #939393; /* Scrollbar thumb (the draggable part) */
      border-radius: 10px; /* Roundness of the thumb */
      border: 2px solid #333; /* Padding around thumb */
      overflow: hidden;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      cursor: pointer;
    }

    li {
      display: inline-block;
      flex: 0 0 auto;
      padding: 5px 20px;
      margin: 5px 2.5px;
      font-size: 16px;
      color: #939393;
      border-radius: 6px;
      border: 1px solid transparent;
      &.border-underline {
        border: none;
        border-radius: 0 !important;
        border-bottom: 3px solid transparent;
      }
      cursor: pointer;
      &.active {
        font-weight: 600;
        color: #0053a0;
        border: 1px solid #0053a0;
        background-color: #e5eef5;
      }
      &:hover {
        opacity: 0.7;
      }
    }
  }
}

.preview-f-openfilters {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.preview-f-closedfilters {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  .preview-f-button {
    &:last-child {
      margin-right: 0;
    }
  }
}
