.pre-count-and-select-all {
  display: flex;
  align-items: center;
  font-size: 14px;

  &--jb {
    justify-content: space-between;
  }

  &--center {
    justify-content: center;
  }

  &--left {
    justify-content: flex-start;
  }

  &--right {
    justify-content: flex-end;
  }

  span {
    font-weight: 600;
  }

  .select-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    span {
      font-weight: 400;
    }
    .newlook {
      margin-right: 5px;
    }
  }

  .switch-toggle {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 18px;
    margin-right: 8px;
    border-radius: 30px;
    transition: right 0.2s linear;
    // background-color: #f1f2f3;

    &--selected {
      // background-color: #3ab78f;
      opacity: 1;
    }

    .switch-toggle-before {
      position: absolute;
      top: 3px;
      right: calc(100% - 13px - 3px);
      width: 12px;
      height: 12px;
      border-radius: 100%;
      background-color: #d7d7d7;
      transition: right 0.2s ease-in-out;

      &--selected {
        right: 3px;
        left: auto;
        background-color: #ffffff;
      }
    }
  }

  .selected {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px 0 5px;
    font-size: 14px;
    span {
      margin-bottom: 5px;
      font-weight: 600;
    }
  }

  .select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    span {
      margin: 0;
      font-weight: 400;
      margin-right: 5px;
    }
  }

  .checkbox {
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 1px solid #c6c6c6;
    margin-right: 8px;
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &.checked {
      background-color: #c6c6c6;
    }
  }
}
