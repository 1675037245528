.preview-f-wrapper {
  background-color: rgba(0, 0, 0, 0.2588235294);
  &.left {
    justify-content: safe flex-start;
  }
  &.right {
    justify-content: safe flex-end;
  }
  &.top {
    align-items: safe flex-start;
  }
  &.bottom {
    align-items: safe flex-end;
  }
  &.topLeft {
    justify-content: safe flex-start;
    align-items: safe flex-start;
  }
  &.topRight {
    justify-content: safe flex-end;
    align-items: safe flex-start;
  }
  &.bottomLeft {
    justify-content: safe flex-start;
    align-items: safe flex-end;
  }
  &.bottomRight {
    align-items: safe flex-end;
    justify-content: safe flex-end;
  }
}

.preview-f-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: safe center;
  justify-content: safe center;
  width: 100%;
  height: 100%;
  overflow: auto;
  &.center {
    .preview-f-popup {
      margin: auto;
    }
  }
  &.topRight {
    .preview-f-popup {
      margin: 0 0 auto auto;
    }
  }
  &.top {
    .preview-f-popup {
      margin: 0 auto auto auto;
    }
  }
  &.topLeft {
    .preview-f-popup {
      margin: 0 auto auto 0;
    }
  }
  &.left {
    .preview-f-popup {
      margin: auto auto auto 0;
    }
  }
  &.center {
    .preview-f-popup {
      margin: auto;
    }
  }
  &.right {
    .preview-f-popup {
      margin: auto 0 auto auto;
    }
  }
  &.bottomRight {
    .preview-f-popup {
      margin: auto 0 0 auto;
    }
  }
  &.bottom {
    .preview-f-popup {
      margin: auto auto 0 auto;
    }
  }
  &.bottomLeft {
    .preview-f-popup {
      margin: auto auto 0 0;
    }
  }
}
// SWIPER
.preview-f-popup {
  position: relative;
  padding: 20px 35px;
  background-color: #fff;
  border: 1px solid transparent;
  box-shadow: 0 0 9px 0px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  font-family: "Poppins";
  box-shadow: 0 0 10px -4px #0000009e;
}

.preview-f-popup {
  width: 1020px;
}

.preview-f-minimize {
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  background-color: #e2e2e2;
  cursor: pointer;
  svg {
    width: 10px;
  }
  &:hover {
    background-color: #f2f2f2;
  }
}

.preview-f-title {
  display: flex;
  align-items: center;
  justify-content: center;
  .preview-f-title-label {
    font-size: 22px;
    display: inline-block;
    vertical-align: middle;
  }
}

.preview-f-title-icon {
  svg {
    max-width: 20px;
    max-height: 20px;
    margin-right: 5px;
  }
}

.preview-f-description {
  text-align: center;
}

.preview-f-openfilters {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.preview-f-closedfilters {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}

.preview-f-button {
  padding: 5px 20px;
  margin-right: 17px;
  font-size: 14px;
  font-weight: 600;
  color: #0053a0;
  background-color: #e5eef5;
  border: 1px solid #0053a0;
  border-radius: 6px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
}

.preview-f-swiper {
  position: relative;
  padding: 0 40px;
  width: 950px;
  max-width: 90vw;
  overflow: hidden;

  .swiper {
    position: initial;
    padding: 15px 5px;
    &.swiper-disabled {
      padding-bottom: 0;
    }
    .swiper-overflow {
      overflow: hidden;
    }
    .swiper-slide {
      width: auto;
      height: auto;
      margin: 0px 7.5px;
      padding: 2px;
    }
    .swiper-item {
      position: relative;
      height: 100%;
      width: 195px;
      display: flex;
      flex-direction: column;

      .hidden-input {
        display: none;
      }
      &.display {
        & > label {
          cursor: pointer;
          &:hover {
            .switch-text {
              opacity: 0.6;
            }
          }
        }
      }
      & > label {
        display: flex;
        flex-direction: column;
        padding: 15px;
        flex-grow: 1;
        height: 100%;
        border-radius: 8px;
        border: 1px solid #d6dae5;
        background-color: #ffffff;
      }
    }
    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal {
      bottom: 0;
    }

    .swiper-button-next {
      width: 34px;
      height: 34px;
      right: 0;
      &::after {
        content: none;
      }

      &:hover {
        opacity: 0.7;
      }
    }

    .swiper-pagination-bullet {
      background-color: #a3a8b9;
    }

    .swiper-button-prev {
      width: 34px;
      height: 34px;
      left: 0;

      &::after {
        content: none;
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }
  .item-filters-image {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    img {
      max-width: 100%;
      width: 100%;
      height: 100%;
      margin: auto;
      margin-top: 0;
      border-radius: 8px;
      object-fit: cover;
      object-position: center;
      overflow: hidden;
      pointer-events: none;
      &.image-xsmall {
        width: 50px;
        height: 50px;
      }
      &.image-small {
        width: 90px;
        height: 90px;
      }
      &.image-medium {
        width: 150px;
        height: 150px;
      }
      &.image-large {
        width: 200px;
        height: 200px;
      }
    }
  }

  .swiper-load-more {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;

    .button-load-more {
      padding: 6px 12px;
      background-color: #383838;
      color: #ffffff;
      font-size: 14px;
      font-weight: 600;
      border-radius: 6px;
      cursor: pointer;
      &:hover {
        opacity: 0.6;
      }
    }
  }

  .item-filters-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    text-align: center;
  }

  .item-filters-description {
    margin-bottom: 10px;
  }

  .item-filters-get {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .item-filters-get-center {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .item-filters-title {
    font-weight: 600;
    font-size: 15px;
  }

  .item-filters-action-get {
    padding: 6px 8px;
    margin-left: 7px;
    line-height: normal;
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }

  .item-filters-delay-email {
    display: block;
    margin-bottom: 10px;
    font-size: 13px;
    font-weight: 500;
  }

  .item-filters-share-icon {
    margin: 0 7px;
  }

  .item-filters-info-icon {
    position: relative;
    display: flex;
    align-items: center;
    min-width: 15px;
    margin: 0 7px;
    cursor: pointer;
    &:hover {
      opacity: 0.6;
    }
    &::before {
      content: "";
      position: absolute;
      right: -12px;
      top: -10px;
      width: 40px;
      height: 40px;
    }
  }

  .hidden-input:checked + .item-filters-layout {
    position: relative;
    border-color: transparent;
    border-color: #3ab78f !important;
    border: 1px solid;
  }
}

.item-filters-email-label {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  padding: 10px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  .item-filters-email-icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    border-radius: 100%;
  }
}

.item-filters-email-wrapper {
  .label-wrapper {
    line-height: 14px;
    min-height: auto;
  }
  .item-filters-email-title {
    padding: 5px 0;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
  }
}

.item-filters-email-input {
  display: flex;
  align-items: center;
  justify-content: center;
  input {
    all: unset;
    display: block;
    padding: 5px 10px;
    max-width: 105px;
    margin-left: 10px;
    font-size: 13px;
    line-height: 18px;
    flex-grow: 1;
    font-weight: 300;
    background-color: #ffffff;
    color: #213038;
    border: 1px solid #ddd;
  }
  .button {
    padding: 6px 12px;
    font-size: 13px;
    line-height: 18px;
    margin: 0 10px;
    border: none;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

.item-filters-email-terms {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  font-size: 11px;
  label {
    position: relative;
    padding-left: 25px;
    font-size: 11px;
    line-height: normal;
    a {
      text-decoration: none;
      color: inherit;
      opacity: 0.8;
      &:hover {
        opacity: 0.5;
      }
    }
  }
  .checkbox-custom {
    position: relative;
    visibility: hidden;
    display: none;
    & + .checkboxsvg {
      position: absolute;
      width: 15px;
      height: 15px;
      left: 0;
      top: 5px;
      background-image: url("../images/checkbox-uncheck.svg");
      background-size: contain;
      background-position: center;
    }
    &:hover {
      & + .checkboxsvg {
        opacity: 0.6;
        cursor: pointer;
      }
    }
  }
  .checkbox-custom:checked {
    &:hover {
      & + .checkboxsvg {
        opacity: 0.9;
        cursor: pointer;
      }
    }
    & + .checkboxsvg {
      content: "";
      position: absolute;
      background-image: url("../images/checkbox-checked.svg");
      background-position: center;
    }
  }
}

.item-filters-layout,
.item-filters-title-text,
.item-filters-image,
.item-filters-description {
  cursor: pointer;
}

.preview-f-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 15px;
  font-size: 12px;
  svg {
    min-width: 24px;
    margin-right: 10px;
  }
}

.max-2lines {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.max-1lines {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
