.inline-new-look-wrapper {
  &.left {
    .new-look {
      margin: 0 auto 0 0;
    }

    .inline-title,
    .inline-footer {
      justify-content: flex-start;
      text-align: left;
    }
    .inline-description {
      text-align: left;
    }
    .inline-swiper-wrapper {
      justify-content: flex-start;
    }

    .no-navigation {
      .swiper {
        margin-left: 0;
      }
    }
  }
  &.right {
    .new-look {
      margin: 0 0 0 auto;
    }

    .inline-title,
    .inline-footer {
      justify-content: flex-end;
      text-align: right;
      padding: 0px 15px;
    }

    .inline-footer {
      flex-direction: row-reverse;
      svg {
        margin-right: 0;
        margin-left: 10px;
      }
    }

    .no-navigation {
      .swiper {
        margin-right: 0;
      }
    }

    .inline-description {
      text-align: right;
      padding: 0px 15px;
    }
    .inline-swiper-wrapper {
      justify-content: flex-end;
    }
  }
  &.center {
    margin: 0 auto;
  }
}

.extra-element {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  border-radius: 6px;
  cursor: pointer;
  svg {
    min-width: 20px;
  }
}

.extra-element-content {
  margin: 0 5px;
}

.extra-element-text-bold {
  font-weight: 600;
}

// SWIPER
.new-look {
  position: relative;
  padding: 20px 0;
  background-color: #fff;
  border: 1px solid;
  font-family: "Poppins";
  max-height: 800px;
  margin: auto;
  overflow: hidden;
  -webkit-transition: all 0.5;
  -moz-transition: all 0.5;
  -o-transition: all 0.5;
  -ms-transition: all 0.5;
  transition: all 0.5;
  &.collapsed {
    max-height: 120px;
    overflow: hidden;
    .new-look-toggle-arrow {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }
}

.preview-minimize {
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  background-color: #e2e2e2;
  cursor: pointer;
  svg {
    width: 10px;
  }
  &:hover {
    background-color: #f2f2f2;
  }
}

.new-look-title {
  position: relative;
  display: flex;
  align-items: center;
  // justify-content: center;
  padding: 0 5px;
  margin-bottom: 5px;
  .new-look-toggle-arrow {
    position: absolute;
    right: 15px;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
  .new-look-title-label {
    min-height: 28px;
    font-size: 22px;
    line-height: 24px;
    display: inline-block;
    vertical-align: middle;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.new-look-title-icon {
  svg {
    max-width: 20px;
    max-height: 20px;
    margin-right: 10px;
    margin-left: 5px;
  }
}

.new-look-description {
  min-height: 30px;
  padding: 0 5px;
  margin-bottom: 10px;
  line-height: 22px;
}

.new-look-qualified {
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 700;
  svg {
    margin-left: 5px;
  }
}

.new-look-selected {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px 0 5px;
  font-size: 14px;
  span {
    margin-bottom: 5px;
    font-weight: 600;
  }
}

.new-look-select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  span {
    margin: 0;
    font-weight: 400;
    margin-right: 5px;
  }
}

.new-look-checkbox {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid #c6c6c6;
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &.checked {
    background-color: #c6c6c6;
  }
}

.new-look-switch-wrapper {
  display: flex;
  label {
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    cursor: pointer;
    .new-look-switch-toggle {
      position: relative;
      display: inline-block;
      width: 30px;
      height: 18px;
      margin-right: 8px;
      border-radius: 30px;
      transition: right 0.2s linear;
      background-color: #f1f2f3;

      &--selected {
        background-color: #3ab78f;
        opacity: 1;
      }

      .new-look-switch-toggle-before {
        position: absolute;
        top: 3px;
        right: calc(100% - 13px - 3px);
        width: 12px;
        height: 12px;
        border-radius: 100%;
        background-color: #d7d7d7;
        transition: right 0.2s ease-in-out;

        &--selected {
          right: 3px;
          left: auto;
          background-color: #ffffff;
        }
      }
    }
  }
}

.new-look-swiper {
  position: relative;
  overflow: hidden;
  .swiper {
    position: initial;
    padding: 5px;
    .swiper-overflow {
      overflow: hidden;
    }
    .swiper-slide {
      height: auto;
    }
    .swiper-item {
      position: relative;
      height: 100%;
      input {
        display: none;
      }
      &.display {
        & > label {
          cursor: pointer;
          &:hover {
            .switch-text {
              opacity: 0.6;
            }
          }
        }
      }
      & > label {
        display: flex;
        flex-direction: column;
        padding: 20px;
        height: 100%;
        border-radius: 8px;
        border: 1px solid #d6dae5;
        background-color: #ffffff;
      }
    }
    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal {
      bottom: 0;
    }

    .swiper-pagination-bullet {
      margin: 0 5px;
    }

    .swiper-navigation {
      display: flex;
      align-items: center;
      justify-content: center;
      .swiper-pagination {
        position: relative;
        width: auto;
        margin: 0 20px;
      }
    }

    .swiper-button-next {
      position: relative;
      width: 30px;
      height: 30px;
      right: 0;
      margin: 0;
      &::after {
        content: none;
      }

      &:hover {
        opacity: 0.7;
      }
    }

    .swiper-button-prev {
      position: relative;
      width: 30px;
      height: 30px;
      left: 0;
      margin: 0;

      &::after {
        content: none;
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }
  .new-look-item-image {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
    img {
      min-width: 104px;
      width: 104px;
      height: 104px;
      margin: auto;
      margin-top: 0;
      border-radius: 8px;
      object-fit: cover;
      object-position: center;
      overflow: hidden;
      pointer-events: none;
    }
  }

  .new-look-item-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-left: 10px;
    padding-right: 10px;
    min-height: 104px;
  }

  .new-look-item-description {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    min-height: 26px;
    line-height: 1.4;
    span {
      opacity: 0.9;
    }
  }

  .new-look-item-get {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }

  .new-look-item-get-center {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .new-look-item-title {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
  }

  .new-look-item-email-icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 26px;
    min-height: 28px;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }

  .new-look-item-info-icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 32px;
    min-height: 32px;
    border: 1px solid #ddd;
    border-radius: 100%;
    cursor: pointer;
    margin-right: 10px;

    &:hover {
      opacity: 0.6;
    }
  }

  .new-look-item-share-icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 32px;
    min-height: 32px;
    margin-right: 10px;
    border: 1px solid #ddd;
    border-radius: 100%;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }

  .new-look-item-action-get {
    width: 100%;
    padding: 5px 7px;
    margin-right: 5px;
    border-radius: 6px;
    text-align: center;
    line-height: 16px;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }

  .new-look-item-layout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid transparent;
  }

  .input:checked + .new-look-item-layout {
    position: relative;
    border-color: transparent;
    border: 1px solid;

    & .switch-toggle {
      background-color: #3ab78f;
      opacity: 1;
      .switch-toggle-before {
        right: 3px;
        left: auto;
        background-color: #ffffff;
      }
    }
    & .switch-text {
      font-family: inherit;
      font-weight: 400;
      .checked-text {
        display: inline-block;
      }
      .unchecked-text {
        display: none;
      }
    }
    & .selected {
      .button-select {
        display: none;
      }
      .button-selected {
        display: inline-block;
      }
    }
  }
}

.new-look-item-icons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  svg {
    width: 18px;
    height: 18px;
  }
  .new-look-info {
    margin-left: 5px;
  }
  .new-look-share {
    margin-left: 10px;
  }
  .new-look-email {
    margin-left: 10px;
  }
}

.new-look-item-layout,
.new-look-item-title-text,
.new-look-item-image,
.new-look-item-description {
  cursor: pointer;
}

.new-look-footer {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 5px;
  font-size: 12px;
  svg {
    min-width: 24px;
    margin-right: 10px;
  }

  &:hover {
    cursor: pointer;
    .preview-tooltip {
      display: block;
    }
  }
}

.preview-tooltip {
  display: none;
  position: absolute;
  max-width: calc(100% - 20px);
  bottom: calc(100% + 10px);
  left: 10px;
  padding: 10px;
  background-color: #fef8de;
  color: #333333;
  z-index: 10;
  &::after {
    content: "";
    position: absolute;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #fef8de;
    bottom: -10px;
    left: calc(50% - 20px);
  }
}

.max-2lines {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.max-1lines {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
