.card {
  background-color: var(--background-color);
  border-radius: var(--border-radius-large);
  padding: 32px;
  margin-bottom: 30px;

  .title {
    font-size: 24px;
    margin-bottom: 5px;
    font-family: Poppins-SemiBold;
    margin-bottom: 32px;
  }

  .desc {
    margin-bottom: 20px;
    font-size: 14px;
    color: var(--font-color);
    font-family: Poppins-Regular;
  }

  .label-input-padding {
    padding: 8px 30px 8px 20px;
  }

  .top-center {
    top: 46%;
  }

  .options {
    display: flex;
    justify-content: space-between;

    .option {
      width: 50%;
      height: 100px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 12px;
      margin-right: 20px;
      border-radius: var(--border-radius-large);
      border: 2px solid var(--border-color);
      cursor: pointer;
      &.active {
        border-color: var(--active-color);
        background-color: var(--active-background-color);
      }
      &:last-child {
        margin-right: 0;
      }
    }

    .option-active {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 12px;
      right: 12px;
      width: 16px;
      height: 16px;
      background-color: var(--active-color);
      border-radius: 2px;
    }

    .option-content {
      text-align: center;
      font-size: 14px;
      color: var(--primary-color);
    }

    .option-image {
      width: 82.68px;
      height: 79.21px;
      margin-right: 20px;
    }
  }

  .admin-actions {
    .action {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #dbdbdb;
      border-radius: 12px;
      padding: 11px 19px;

      &--danger {
        border-color: var(--danger-color);
        background-color: #ffecef;
        justify-content: center;
        color: var(--danger-color);
        height: 45px;

        .delete-icon {
          margin-right: 8px;
        }
      }

      .info {
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          margin-right: 8px;
          font-family: Poppins;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          color: var(--font-color);
        }
      }
    }
  }

  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .action {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #dbdbdb;
      border-radius: 12px;
      width: 50%;
      padding: 11px 19px;
      height: fit-content;

      &:last-child {
        margin-right: 0;
      }

      &-auto-approve {
        margin-top: 7px;
        padding: 14px 19px;
      }
    }
  }

  .error-span {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
