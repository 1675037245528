.adv-embedd-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px dashed #ddd;
}
// SWIPER
.inline-popup {
  position: relative;
  padding: 15px;
  background-color: #fff;
  font-family: "Poppins";
}

.inline-popup-pre-1 {
  max-width: 450px;
}

.inline-popup-pre-2 {
  max-width: 720px;
}

.inline-popup-pre-3 {
  max-width: 1020px;
}

.inline-minimize {
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  background-color: #e2e2e2;
  cursor: pointer;
  svg {
    width: 10px;
  }
  &:hover {
    background-color: #f2f2f2;
  }
}

.inline-wrapper {
  &.left {
    .inline-title,
    .inline-footer {
      justify-content: flex-start;
      text-align: left;
    }
    .inline-description {
      text-align: left;
    }
    .inline-swiper-wrapper {
      justify-content: flex-start;
    }

    .no-navigation {
      .swiper {
        margin-left: 0;
      }
    }
  }
  &.right {
    .inline-title,
    .inline-footer {
      justify-content: flex-end;
      text-align: right;
      padding: 0px 15px;
    }

    .inline-footer {
      svg {
        margin-right: 0;
        margin-left: 10px;
      }
    }

    .no-navigation {
      .swiper {
        margin-right: 0;
      }
    }

    .inline-description {
      text-align: right;
      padding: 0px 15px;
    }
    .inline-swiper-wrapper {
      justify-content: flex-end;
    }
  }
}

.inline-title {
  display: flex;
  align-items: center;
  justify-content: center;
  .inline-title-label {
    font-size: 22px;
    display: inline-block;
    vertical-align: middle;
  }
}

.inline-title-icon {
  svg {
    max-width: 20px;
    max-height: 20px;
    margin-right: 5px;
  }
}

.inline-description {
  text-align: center;
}

.inline-swiper {
  overflow: hidden;
  .inline-swiper-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 0 40px;
    &.no-navigation {
      padding: 0;
    }
  }

  .swiper {
    position: initial;
    padding: 20px 0;
    .swiper-overflow {
      overflow: hidden;
    }
    .swiper-slide {
      height: auto;
      width: 350px;
      padding: 0 2px;
    }
    .swiper-item {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 100%;
      input {
        display: none;
      }
      &.display {
        & > label {
          cursor: pointer;
          &:hover {
            .switch-text {
              opacity: 0.6;
            }
          }
        }
      }
      & > label {
        display: flex;
        flex-direction: row;
        padding: 10px;
        height: 100%;
        border-radius: 8px;
        border: 1px solid #d6dae5;
        background-color: #ffffff;
      }
    }
    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal {
      bottom: 0;
    }

    .swiper-button-next {
      width: 34px;
      height: 34px;
      right: 0;
      &::after {
        content: none;
      }

      &:hover {
        opacity: 0.7;
      }
    }

    .swiper-pagination-bullet {
      background-color: #a3a8b9;
    }

    .swiper-button-prev {
      width: 34px;
      height: 34px;
      left: 0;

      &::after {
        content: none;
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }
  .item-image {
    min-width: 90px;
    margin-bottom: 5px;
    img {
      max-width: 100%;
      width: 100%;
      height: 100%;
      margin: auto;
      margin-top: 0;
      border-radius: 8px;
      object-fit: cover;
      object-position: center;
      overflow: hidden;
      pointer-events: none;
      &.image-xsmall {
        width: 50px;
        height: 50px;
      }
      &.image-small {
        width: 90px;
        height: 90px;
      }
      &.image-medium {
        width: 150px;
        height: 150px;
      }
      &.image-large {
        width: 200px;
        height: 200px;
      }
    }
  }

  .item-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-left: 10px;
  }

  .item-description {
    margin-bottom: 5px;
  }

  .item-get {
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
  }
  .item-get-center {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  .item-title {
    font-weight: 600;
    font-size: 15px;
  }

  .item-action-get {
    padding: 6px 12px;
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }

  .item-info-icon {
    position: relative;
    display: flex;
    align-items: center;
    min-width: 15px;
    margin-left: 15px;
    cursor: pointer;
    &:hover {
      opacity: 0.6;
    }
    &::before {
      content: "";
      position: absolute;
      right: -12px;
      top: -10px;
      width: 40px;
      height: 40px;
    }
  }

  .input:checked + .item-layout {
    position: relative;
    border-color: transparent;
    border-color: #3ab78f !important;
    border: 1px solid;
  }
}

.item-layout,
.item-title-text,
.item-image,
.item-description {
  cursor: pointer;
}

.item-email-label {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  padding: 10px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  .item-email-icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    border-radius: 100%;
  }
}

.inline-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 15px;
  font-size: 12px;
  svg {
    min-width: 24px;
    margin-right: 10px;
  }
}

.max-2lines {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.max-1lines {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.label-success-email {
  color: #3ab78f;
  font-size: 12px;
}
