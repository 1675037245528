.new-offers {
  display: flex;
  width: 100%;
  padding: 20px 50px;
  gap: 16px;
  font-family: Poppins;
  overflow-y: scroll;
  height: 100%;

  &__board {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: white;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px 0px #0000000a;
    height: 100%;

    &__header {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      &__title {
        font-size: 19px;
        font-weight: 600;
        line-height: 24px;
        color: #161616;
        margin-right: 8px;
      }
      &__num {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: #77787e;
      }
    }

    &__desc {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #77787e;
      margin-bottom: 12px;
    }

    &__add {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px dashed #a0a2a7;
      border-radius: 8px;
      text-align: center;
      padding: 13.5px 24px 13.5px 24px;
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      color: #3ab78f;
      cursor: pointer;
      margin-bottom: 10px;
    }

    &__alert {
      text-align: center;
      padding: 7.5px 15px !important;
      border-radius: 8px;
      border: 1px solid #3ab78f;
      background-color: #3ab78f1a;
      cursor: pointer;
      margin-top: auto;
    }

    .column-styles {
      height: 100%;

      &-box {
        height: 100%;
      }
    }
  }

  &__slot {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    padding: 16px;
    border-radius: 8px;
    overflow-y: scroll;

    &__add {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px dashed #a0a2a7;
      border-radius: 8px;
      text-align: center;
      padding: 13.5px 24px 13.5px 24px;
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      color: #3ab78f;
      cursor: pointer;
      margin-bottom: 10px;
    }

    &__box {
      display: flex;
      flex-direction: column;
      padding: 12px 16px;
      background-color: white;
      margin-bottom: 16px;
      border-radius: 8px !important;

      .ant-collapse-header {
        display: flex !important;
        align-items: center !important;
      }

      .ant-collapse-content {
        margin-top: 12px;
      }

      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__left {
          display: flex;
          align-items: center;
        }

        &__right {
          display: flex;
          align-items: center;

          .slot-delete-action {
            cursor: pointer;
            &--disabled {
              pointer-events: none; /* Prevent interaction */
              opacity: 0.5; /* Make it look faded */
              cursor: not-allowed; /* Show a "not allowed" cursor */
            }
          }
        }

        &__title {
          font-size: 15px;
          font-weight: 600;
          line-height: 24px;
          color: #161616;
          margin-right: 8px;
        }

        &__num {
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          color: #77787e;
        }
      }
    }
  }

  .dnd-box {
    display: flex;
    flex-direction: column;

    &--scroll {
      overflow-y: scroll;
    }
  }

  .card-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    width: 100%;

    &__percentage {
      rotate: 270deg;
      font-family: Poppins;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #77787e;
      width: 16px;
    }

    &__box {
      display: flex;
      width: 100%;
    }
  }

  .dnd-card {
    cursor: pointer;
    border: 1px solid #d3d5dd;
    border-radius: 8px;
    padding: 12px 24px 12px 12px;
    position: relative;
    width: 100%;

    &__content {
      display: flex;
      align-items: center;

      &__image {
        width: 60px;
        height: 60px;
        border-radius: 8px;
        margin-right: 12px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 8px;
          object-fit: cover;
        }
      }

      &__details {
        display: flex;
        flex-direction: column;
        flex: 1;
        &__main {
          display: flex;
          justify-content: space-between;
          align-items: center;
          line-height: 16px;
          height: 24px;

          &__content {
            display: flex;
            &__title {
              font-size: 14px;
              font-weight: 600;
              line-height: 16px;
              margin-right: 8px;
            }
            &__featured {
              height: 14px;
              width: 14px;
              margin-right: 10px;
            }
            &__edited {
              font-size: 12px;
              font-weight: 400;
              line-height: 16px;
              color: #844aff;
            }
          }

          &__cpa {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
          }

          &__actions {
            .delete-action {
              margin-left: 8px;
            }
          }
        }

        &__desc {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: #77787e;
        }

        &__info {
          display: flex;
          justify-content: space-between;
          align-items: center;

          &__owner {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;

            span {
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              color: #77787e;
            }
          }
          &__right {
            display: flex;
            align-items: center;

            &__date {
              font-size: 12px;
              font-weight: 400;
              line-height: 16px;
              color: #a0a2a7;
              margin-right: 12px;
            }

            &__status {
              font-size: 12px;
              font-weight: 400;
              line-height: 16px;
              padding: 2px 8px;
              border-radius: 2px;
              width: fit-content;
              &--active {
                color: #3ab78f;
                background-color: #3ab78f40;
              }
              &--not-active {
                color: #77787e;
                background-color: #f2f2f2;
              }
            }
          }
        }
      }
    }

    &:after {
      position: absolute;
      right: 0;
      top: 0;
      width: 12px;
      height: 100%;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border-left: 1px solid #d3d5dd;
      content: url("../../../../assets/images/icons/dash-vertical-line.svg");
      background-color: #0000000a;
      color: #d3d5dd;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.container-styles {
  position: relative;
}

.drop-line {
  border: 1px solid #3ab78f;
  margin-bottom: 12px;
}

.offers-modal {
  &__title {
    font-family: Poppins;
    font-size: 19px;
    font-weight: 600;
    line-height: 24px;
    padding: 18px 24px;
    border-bottom: 1px solid #d3d5dd;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    &__cancel {
      background-color: white;
      border-color: #3ab78f;
    }
    &__save {
      color: white !important;
      background-color: #3ab78f !important;
    }
  }

  .ant-modal-body {
    padding-top: 0;
  }

  .ant-tabs-tab {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    padding: 20px 24px;
    color: #a0a2a7;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #161616;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }
}

.offers-modal-table {
  .ant-table-thead {
    display: none;
  }
  .ant-table-cell {
    cursor: pointer;
  }
  .offers-table-row--active {
    .ant-table-cell {
      background-color: #3ab78f1a !important;
    }
  }
}

//table
.offers-table-image-mid {
  width: 60px;
  height: 60px;
  object-fit: cover;
  object-position: center;
  border-radius: 8px;
}

.offers-table-name {
  display: flex;
  height: 100%;
  align-items: flex-start;
}

.offers-table-title {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: #161616;
  margin-right: 8px;
}

.offers-table-description {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #77787e;
  margin-bottom: 12px;
}

.offers-table-status {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  padding: 2px 8px;
  border-radius: 2px;
  width: fit-content;
  &--active {
    color: #3ab78f;
    background-color: #3ab78f40;
  }
  &--not-active {
    color: #77787e;
    background-color: #f2f2f2;
  }
}

.offers-table-date {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #a0a2a7;
  margin-right: 12px;
}

.offers-table-action {
  .ant-checkbox {
    transform: scale(1.25);
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #3ab78f;
    border-color: #3ab78f;
  }
}

.multi-slider {
  display: flex;
  align-items: center;
  &__equalize {
    margin-left: 13px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #3ab78f;
    cursor: pointer;
  }
}

.slider {
  position: relative;
  height: 6px;
  background: transparent;
  border-radius: 38px;
  width: 100%;
  margin: 20px 0;
}

.track {
  position: absolute;
  height: 6px;
  border-radius: 38px;
  top: 0;
  pointer-events: none;
}

.handle {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
  height: 20px;
  cursor: grab;
  z-index: 100;
  background-image: url(../../../../assets/images/icons/slider-handle.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.handle:active {
  cursor: grabbing;
}

.slot-label {
  position: absolute;
  top: -20px;
  transform: translateX(-50%);
  font-family: Poppins;
  font-weight: 400;
  font-size: 12px;
  color: #77787e;
}

// Modal

.offers-layout-options {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 6px;
  border: 1px solid #e1e3e5;
  border-radius: 6px;

  .offers-filters {
    display: flex;
    align-items: center;
    .offers-filter-dropdown {
      padding: 8px 15px;
      border-radius: 6px;
      margin-right: 12px;
      background-color: #f2f2f2;
    }
  }
}

.offers-apply-button {
  border: 1px solid #3ab78f;
  background-color: #3ab78f1a;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding: 11.5px 16px;
  border-radius: 6px;
  cursor: pointer;
}
