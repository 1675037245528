.foundations {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.foundations-item {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: 10px;
}

.foundations-item-actions {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  img {
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
}

.foundations-add {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 10px;
  color: var(--font-color);
  font-size: 14px;
  font-weight: bold;
  border: 1px dashed var(--border-color);
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    color: var(--primary-color);
  }
}
