@font-face {
  font-family: "GoodHome";
  src: url("../fonts/GoodHome/GoodHome-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GoodHome";
  src: url("../fonts/GoodHome/GoodHome-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
