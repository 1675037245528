.unauth-container {
  min-height: 100%;
  max-width: 700px;
  margin: 0 auto;
  width: calc(100% - 100px);
}

.unauth-container-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: baseline;
  padding: 20px 0 0;
  overflow-y: auto;

  height: 100%;
  &.unauth-login {
    padding: 0;
    .unauth-widget {
      margin: 30px 0;
      padding: 80px 70px;
    }
    .unauth-button {
      margin-top: 20px;
    }
    .unauth-content {
      justify-content: center;
    }
  }

  &.unauth-onboard {
    .unauth-container {
      justify-content: space-between;
    }
  }
  .unauth-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

.unauth-widget {
  padding: 50px 70px;
}

.unauth {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.unauth-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 33%;
  background-color: var(--primary-color);
  color: var(--background-color);
}

.unauth-sidebar-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  margin: 40px 10px;
}

.unauth-sidebar-description {
  // font-size: 24px;
  // font-family: Poppins-SemiBold;
  // line-height: 36px;
  // text-align: center;
  // max-width: 320px;

  font-size: 18px;
  font-family: "Poppins-Medium";
  line-height: 28px;
  text-align: center;
  max-width: 320px;
}

.unauth-logo {
  max-width: 210px;
  margin-bottom: 40px;
}

.unauth-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--secondary-color);
}

.unauth-header {
  background-color: var(--background-color);
  border-bottom: 1px solid var(--border-color);
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    li {
      a {
        display: block;
        padding: 16px 20px 10px 20px;
        font-size: 14px;
        color: var(--font-color);
        cursor: pointer;
        &.active {
          color: var(--primary-color);
          font-family: Poppins-SemiBold;
        }
        &:hover {
          color: var(--primary-color);
        }
      }
    }
  }
}

.widget-form {
  margin: 0 auto;
  &.small-form {
    max-width: 420px;
  }

  &.verification {
    margin: 40px auto 50px auto;
    .unauth-link {
      margin-bottom: 15px;
    }
  }
  &.forgot-password,
  &.user-details,
  &.verification,
  &.user-type,
  &.companyType,
  &.affiliate {
    .widget-form-actions {
      justify-content: center;
    }
  }
}

.unauth-back {
  margin-bottom: 70px;
  text-align: center;
  button {
    margin-right: 10px;
  }
}

.unauth-label {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: Poppins-Regular;
  margin: 0 0 5px 0;
  color: var(--font-color);
}

.unauth-label-error {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  margin-bottom: 3px;
  img {
    max-height: 10px;
    margin-left: 5px;
  }
  span {
    font-size: 12px;
    line-height: 12px;
    color: var(--fail-color);
    text-align: right;
  }
}

.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.email-sent {
  font-size: 24px;
  margin-right: 15px;
  color: var(--font-color);
}
.email-sent-label {
  display: inline-block;
  margin-left: 5px;
  font-size: 14px;
  color: var(--warning-color);
  font-family: Poppins-Semibold;
}

.footer {
  min-height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  span {
    margin-left: 20px;
    font-size: 13px;
    font-family: Poppins-Regular;
    color: var(--font-color);
  }
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    li {
      margin-left: 20px;
      font-size: 13px;
      font-family: Poppins-Regular;
      color: var(--font-color);
    }
  }
}

.unauth-sidebar-image {
  img {
    max-width: 100%;
  }
}

.widget-form-forgot {
  span {
    font-size: 12px;
    font-family: Poppins-Regular;
  }
}

.unauth-button {
  margin-top: 30px;
  button {
    min-width: 150px;
    height: 40px;
    padding: 0 40px;
    border-radius: var(--border-radius-medium);
  }
}

.widget-form-error {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 20px;
  .error-line {
    display: block;
    text-align: center;
    width: 100%;
  }
}
