.ant-message-notice {
  text-align: right;
}

.ant-checkbox-inner,
.ant-checkbox-checked::after {
  border-radius: var(--border-radius-medium);
}

.checkbox-active {
  &.ant-checkbox-wrapper {
    color: var(--primary-color);
    font-family: Poppins-Regular;
    font-size: 14px;
    .ant-checkbox-checked + span {
      color: var(--primary-color);
    }
    .checkbox-value {
      margin-left: 5px;
      color: var(--disable-color);
    }
    .ant-checkbox-inner {
      border-color: var(--disable-color);
      border-width: 2px;
      background-color: transparent;
    }
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--active-color);
    border-color: var(--active-color);
  }
}

.ant-slider {
  &.ant-slider-disabled {
    opacity: 0.2;
  }
}

.ant-picker {
  width: 100%;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  &.filter-date {
    background-color: var(--background-color);
    border-radius: var(--border-radius-medium);

    input {
      min-height: 40px;
    }
    .ant-picker-clear {
      background-color: var(--background-color);
    }
  }
  input {
    outline: none;
    padding: 5px 10px 5px 20px;
    &:focus,
    &:hover {
      outline: none;
      border: none;
    }
  }
  &.ant-picker-focused {
    box-shadow: none;
  }
  .ant-picker-suffix {
    margin-right: 15px;
    font-size: 16px;
  }
  .ant-picker-clear {
    font-size: 16px;
    background-color: var(--secondary-color);
    right: 15px;
  }
}

.label-input-wrapper {
  .ant-picker {
    input {
      padding: 25px 30px 8px 20px;
    }
    .ant-picker-clear {
      margin-top: 5px;
    }

    .ant-picker-suffix {
      margin-top: 10px;
    }
  }
  .label-error {
    top: 8px;
    right: 15px;
    left: auto;
    bottom: auto;
  }
}

.ant-select-dropdown {
  padding: 5px 0;
  background-color: var(--background-color);
  border-radius: var(--border-radius-medium);
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
}

.list-tags {
  max-width: 370px;
  margin-bottom: 10px;
  li {
    display: inline-flex;
    align-items: center;
    margin-top: 0;
    margin-bottom: 10px;
    margin-right: 10px;
    padding: 3px 10px;
    border-radius: var(--border-radius-medium);
    background-color: var(--secondary-color);
  }
}

.dropdown {
  padding: 15px;
  background-color: var(--background-color);
  border-radius: var(--border-radius-medium);
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  .dropdown-title {
    margin-bottom: 10px;
    font-size: 14px;
    font-family: Poppins-SemiBold;
    color: var(--primary-color);
  }

  ul {
    max-height: calc(50vh - 150px);
    overflow: auto;
    &.list-tags {
      max-width: 370px;
      margin-bottom: 10px;
      li {
        margin-top: 0;
      }
    }
    li {
      margin-top: 10px;
      &:first-child {
        margin-top: 0;
      }
    }
  }
  .ant-dropdown-menu {
    position: relative;
    margin: 0;
    padding: 0;
    text-align: left;
    list-style-type: none;
    background-color: transparent;
    background-clip: padding-box;
    border-radius: 0;
    outline: none;
    box-shadow: none;
  }
}
.ant-dropdown {
  .ant-dropdown-menu {
    max-height: 70vh;
    overflow: auto;
  }
}

.dropdown-overlay-back {
  z-index: 150;
}

.ant-dropdown-trigger {
  cursor: pointer;
}

.ant-picker-footer {
  .ant-picker-ranges {
    li {
      vertical-align: sub;
    }
  }
}

.img-crop-modal {
  .ant-modal {
    .ant-modal-content {
      padding: 25px;
      .ant-modal-body {
        padding: 24px 0;
      }
    }
  }
}

.ant-modal {
  &.modal-default {
    color: var(--primary-color);
    .ant-modal-body {
      padding: 50px;
    }
  }
  .ant-modal-content {
    border-radius: var(--border-radius-large);
    box-shadow: 0 5px 25px 0 rgba(62, 65, 67, 0.2);
    overflow: hidden;
    .ant-modal-close-icon {
      width: 30px;
      height: 30px;
      background-color: var(--secondary-color);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
    }
  }
  .ant-modal-header,
  .ant-modal-footer {
    padding: 0;
    border: none;
  }
}
.ant-modal-confirm {
  .ant-modal-body {
    padding: 50px;
    .ant-modal-confirm-body {
      width: 100%;
    }
    .ant-modal-confirm-body-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      min-height: 250px;

      .ant-modal-confirm-title {
        margin: 0 auto 10px auto;
        font-family: Poppins-Semibold;
        font-size: 18px;
        text-align: center;
        color: var(--primary-color);
      }

      .ant-modal-confirm-content {
        margin: 0 auto 30px auto;
        font-family: Poppins-Regular;
        font-size: 13px;
        text-align: center;
        color: var(--font-color);
      }
      .ant-modal-confirm-btns {
        text-align: center;
        button {
          min-width: 145px;
          margin: 0 10px;
        }
      }
    }
  }
}

.ant-collapse {
  margin: 0;
  padding: 0;
  color: var(--primary-color);
  font-size: 14px;
  font-family: Poppins-Regular;
  font-variant: normal;
  line-height: normal;
  list-style: none;
  background-color: transparent;
  border: none;
  border-bottom: 0;
  border-radius: 0;

  .icon-collapse-mix {
    width: 11px;
    height: 11px;
    background-image: url("../../assets/images/icons/plus-icon.svg");
  }
  .ant-collapse-item-active {
    .icon-collapse-mix {
      width: 11px;
      height: 1.5px;
      background-image: url("../../assets/images/icons/minus-icon.svg");
    }
  }
  & > .ant-collapse-item {
    border-bottom: none;
    &:not(.ant-collapse-item-active) {
      .icon-arrow-down {
        transform: rotate(-90deg);
        transition: all 0.3s;
      }
    }
    &.ant-collapse-no-arrow > .ant-collapse-header {
      padding-left: 0;
    }
    & > .ant-collapse-header {
      position: relative;
      display: block;
      padding: 0;
      color: var(--primary-color);
      line-height: normal;
      cursor: pointer;
      transition: all 0.3s, visibility 0s;
    }
    & > .ant-collapse-content {
      background-color: transparent;
      border-top: 0;
      & > .ant-collapse-content-box {
        padding: 0;
      }
    }
  }
}

.ant-tabs {
  .ant-tabs-tab {
    &.ant-tabs-tab-disabled {
      opacity: 0.3;
    }
  }
}

.ant-dropdown-menu {
  .ant-dropdown-menu-item {
    color: var(--primary-color);
    font-size: 12px;
    &.ant-dropdown-menu-item-disabled {
      color: var(--disable-color);
    }
    &.ant-dropdown-menu-item-selected,
    &.ant-dropdown-menu-submenu-title-selected {
      color: #3ab78f;
      background-color: var(--secondary-color);
    }
  }
}

a.ant-typography,
.ant-typography a {
  color: var(--font-color);
}

.label-input-wrapper {
  .ant-select-arrow {
    margin-top: 0;
  }
  .ant-select-item {
  }
}

.ant-upload-list-picture-card {
  .ant-upload-list-item-actions {
    display: flex;
    align-items: center;
  }
}

.ant-select-multiple {
  .ant-select-selector {
    align-items: flex-start;
    justify-content: flex-start;
    .ant-select-selection-search-input,
    .ant-select-selection-search-mirror {
      height: 14px;
      font-size: 14px;
      line-height: 14px;
      font-family: "Poppins";
    }

    .ant-select-selection-item {
      height: auto;
      margin-top: 0;
      margin-bottom: 0;
      line-height: inherit;
      background: transparent;
      border: 1px solid #f0f0f0;
      border-radius: 0;
      margin-inline-end: 8px;
      padding-inline-start: 0;
      padding-inline-end: 0;
    }
  }
}

.ant-select {
  &.ant-select-disabled {
    opacity: 0.6;
  }
  &.button-select {
  }
  &.label-select-normal {
    width: 100%;
    display: block;
    font-size: 14px;
    line-height: 14px;
    padding: 0;
    font-family: Poppins-Regular;
    color: var(--primary-color);
    background-color: transparent;
    border: none;
    box-shadow: none;
    outline: none;
    &.ant-select-focused {
      &:not(.ant-select-disabled) {
        &:not(.ant-select-customize-input) {
          .ant-select-selector {
            box-shadow: none;
            border-width: 0;
            outline: none;
          }
        }
      }
    }
    &:not(.ant-select-customize-input) {
      .ant-select-selector {
        position: inherit;
        padding: 5px 20px;
        height: 50px;
        background-color: transparent;
        border: none;
        border-radius: 0;
      }
    }
    &.ant-select-single {
      .ant-select-selector {
        .ant-select-selection-item {
          line-height: 40px;
        }
      }
    }
  }
  &.label-select {
    width: 100%;
    display: block;
    font-size: 14px;
    line-height: 14px;
    padding: 0;
    font-family: Poppins-Regular;
    color: var(--primary-color);
    background-color: transparent;
    border: none;
    box-shadow: none;
    outline: none;
    &.ant-select-focused {
      &:not(.ant-select-disabled) {
        &:not(.ant-select-customize-input) {
          .ant-select-selector {
            box-shadow: none;
            border-width: 0;
            outline: none;
          }
        }
      }
    }
    &:not(.ant-select-customize-input) {
      .ant-select-selector {
        position: inherit;
        padding: 25px 30px 8px 20px;
        height: 50px;
        background-color: transparent;
        border: none;
        border-radius: 0;
      }
    }
  }
  &.ant-select-single {
    .ant-select-selector {
      .ant-select-selection-item {
        line-height: 14px;
      }
    }
  }
}

.option-col {
  .ant-select.ant-select-single
    .ant-select-selector
    .ant-select-selection-item {
    line-height: 30px;
  }
}

.ant-switch {
  background-color: var(--secondary-color);
  .ant-switch-inner {
    color: var(--font-color);
  }
  .ant-switch-handle {
    &::before {
      background-color: #d2d3d7;
    }
  }
  &.ant-switch-checked {
    background-color: var(--success-color);

    .ant-switch-inner {
      color: var(--background-color);
    }

    .ant-switch-handle {
      &::before {
        background-color: var(--background-color);
      }
    }
  }
  &.ant-switch:focus {
    box-shadow: none;
  }
}

.ant-upload {
  overflow: hidden;
}

.input-image-field {
  display: flex;
  .avatar-uploader {
    flex-basis: 50px;

    .ant-upload {
      overflow: hidden;
      &.ant-upload-select-picture-card {
        width: 50px;
        height: 52px;
        font-size: 11px;
        border-radius: var(--border-radius-large);
        .anticon.anticon-plus {
          display: none;
        }
        div > div {
          display: flex;
          align-items: flex-end;
          height: 34px;
          font-size: 10px;
        }
      }
    }
  }
}
