.content-steps {
  ul {
    display: flex;
    align-items: center;
    li {
      margin-right: 10px;

      &.step-details,
      &.step-offers,
      &.step-layout,
      &.step-placements {
        .step-icon {
          display: inline-block;
          width: 20px;
          height: 20px;
          margin-right: 5px;
          background-repeat: no-repeat;
          background-position: center;
        }
      }

      &.step-details {
        .step-icon {
          background-image: url("../../../assets/images/icons/details-icon.svg");
        }

        .active {
          .step-icon {
            background-image: url("../../../assets/images/icons/details-active-icon.svg");
          }
        }
      }

      &.step-offers {
        .step-icon {
          background-image: url("../../../assets/images/icons/offer-icon.svg");
        }

        .active {
          .step-icon {
            background-image: url("../../../assets/images/icons/offer-active-icon.svg");
          }
        }
      }

      &.step-layout {
        .step-icon {
          background-image: url("../../../assets/images/icons/layout-icon.svg");
        }

        .active {
          .step-icon {
            background-image: url("../../../assets/images/icons/layout-active-icon.svg");
          }
        }
      }

      &.step-placements {
        .step-icon {
          background-image: url("../../../assets/images/icons/placements-icon.svg");
        }
        .active {
          .step-icon {
            background-image: url("../../../assets/images/icons/placements-active-icon.svg");
          }
        }
      }

      .campaign-step {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 125px;
        font-size: 12px;
        font-family: Poppins-Regular;
        color: var(--font-color);
        border: 1px solid var(--border-color);
        border-radius: var(--border-radius-medium);
        &:not(.step-disabled) {
          cursor: pointer;
          &.active {
            background-color: var(--active-background-color);
            border-color: var(--active-color);
            color: var(--active-color);
            font-family: Poppins-SemiBold;

            &:hover {
              color: var(--active-color);
            }
          }
          &:hover {
            color: var(--font-color);
            border-color: var(--active-color);
          }
        }
      }
    }
  }
}

.interaction-wrapper {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  min-height: 100%;
}

.interaction-pages {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 25px 0;
  flex: 0 0 calc(350px);
  max-width: calc(350px - 15px);
  margin-right: 15px;
  background-color: var(--background-color);
  border-radius: var(--border-radius-large);
}

.interaction-pages-overlay {
  max-height: calc(100vh - 230px);
  margin: 0 2px;
  padding: 0 18px;
  overflow-y: auto;
  overflow-x: hidden;
}

.interaction-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  font-family: "Poppins-Regular";
}

.interaction-view {
  display: flex;
  flex-direction: column;
  padding: 25px;
  flex: 0 0 calc(100% - 350px);
  max-width: calc(100% - 350px);
  background-color: var(--background-color);
  border-radius: var(--border-radius-large);
}

.interaction-device {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.icon-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 6px 12px;
  border: 1px solid transparent;
  border-radius: var(--border-radius-medium);
  cursor: pointer;
  // &:first-child {
  //   margin-right: 5px;
  // }
  // &:last-child {
  //   margin-left: 5px;
  // }
  &.active {
    // font-family: 'Poppins-SemiBold';
    color: var(--success-color);
    border-color: var(--success-color);
    // background-color: var(--default-color);
  }
}

.view-title {
  margin-bottom: 15px;
  text-align: center;
  .view-trigger {
    float: right;
  }
}

.view-content {
  position: relative;
  display: flex;
  flex-grow: 1;
  height: 100%;
  margin: 25px 0;
  border-radius: var(--border-radius-large);
  border: 1px solid var(--border-color);
  overflow: hidden;
  &.view-embedded {
    overflow-x: auto;
    border: none;
  }

  &.mobile {
    width: 100%;
    margin: 25px auto;
    max-width: 400px;
    min-width: 200px;
  }
  .template-layout {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    &.template-blur {
      filter: blur(2px);
      opacity: 0.5;
    }

    #brswtgl {
      display: none;
    }
  }
  iframe {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: var(--border-radius-large);
    iframe {
      display: none;
    }
  }
  img {
    display: block;
    margin: 0 auto;
    object-fit: contain;
  }
}

.template-list-preview {
  position: relative;
  flex-grow: 1;
  padding-bottom: 50px;
  .view-content {
    margin: 0;
  }
  button {
    position: absolute;
    right: 10px;
    bottom: 0;
  }
}

.pages-title {
  margin-bottom: 10px;
  font-size: 14px;
}

.pages-content {
  flex-grow: 1;
}

.pages-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
  margin-top: 15px;
  border-top: 1px solid var(--border-color);
  span {
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
}

.pages-box {
  position: relative;
  margin-top: 10px;
  margin-bottom: 20px;
  padding-bottom: 10px;
  // margin: 0 18px 0 20px;
  // padding: 15px 26px 15px 0;
  border-bottom: 1px dashed var(--border-color);
  .table-icon {
    position: absolute;
    right: 0;
    top: 30px;
  }
  &.last {
    border-bottom: none;
  }
}

.box-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
  border-radius: var(--border-radius-medium);
  background-color: var(--secondary-color);
}

.box-display {
  margin-bottom: 10px;
}

.displayswitch {
  .ant-switch-inner {
    min-width: 26px;
  }
}

.box-message {
  display: flex;
  align-items: center;
  .icon-message {
    margin-right: 8px;
  }
  .font-xsmall {
    margin-left: 5px;
  }
}

.box-message-open {
  flex-grow: 1;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-medium);
}

.message-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 5px 0 10px;
  span.font-xsmall {
    margin-left: 0;
  }
}

.message-content {
  padding: 0px 10px 5px 10px;
  font-size: 12px;
  line-height: 1.3;
}

.layout-tabs {
  &.no-style {
    &.ant-tabs {
      > .ant-tabs-nav {
        &::before {
          content: none;
        }
        .ant-tabs-ink-bar {
          display: none;
        }
        .ant-tabs-nav-list {
          margin: 0 3px;

          div.ant-tabs-tab {
            &:first-child {
              // justify-content: flex-start;
              padding-right: 10px;
            }
            &:not(:first-child) + .ant-tabs-tab {
              // justify-content: flex-end;
              padding-left: 6px;
            }
            label {
              cursor: pointer;
              margin-left: 2px;
            }
          }
        }
      }
    }
  }
}

.tab-extra-checkbox {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 12px;
  color: var(--font-color);
}

.extra-tab-option {
  margin-bottom: 10px;
  text-align: center;
  label {
    font-size: 12px;
    color: var(--font-color);
    cursor: pointer;
  }
}

.layout-tabs {
  &.ant-tabs {
    .ant-tabs-nav {
      .ant-tabs-ink-bar {
        height: 4px;
        border-radius: var(--border-radius-large);
      }
      &::before {
        border-bottom: 1px dashed var(--border-color);
      }
    }
    .ant-tabs-nav-list {
      margin: 0 20px;
      width: 100%;

      .ant-tabs-tab {
        padding-top: 0;
        margin: 0;
        justify-content: center;
        flex: 1;
        .ant-tabs-tab-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          font-family: Poppins-Regular;
          color: var(--font-color);
        }
        label {
          font-size: 12px;
          font-family: Poppins-Regular;
          color: var(--font-color);
        }
        .icon {
          margin-right: 5px;
        }
        &.ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: var(--primary-color);
          }
          .icon-layout-template {
            background-image: url("../../../assets/images/icons/layout-template-active-icon.svg");
          }
          .icon-layout-settings {
            background-image: url("../../../assets/images/icons/layout-settings-active-icon.svg");
          }
        }
      }
    }

    .ant-tabs-content {
      padding: 0;

      .layout-settings-tabs {
        &.ant-tabs {
          .ant-tabs-nav {
            &::before {
              border: none;
            }
            .ant-tabs-ink-bar {
              height: 100%;
              background-color: transparent;
              border: 1px solid var(--success-color);
              border-radius: var(--border-radius-medium);
              z-index: -1;
            }
          }

          .ant-tabs-nav-list {
            height: 27px;
            margin: 0 20px;
            background-color: var(--secondary-color);
            border-radius: var(--border-radius-medium);
            .ant-tabs-tab {
              margin-bottom: 0;
              padding: 0;
              &.ant-tabs-tab-active {
                .ant-tabs-tab-btn {
                  color: var(--success-color);
                }
              }
            }
          }
        }

        .ant-tabs-content {
          padding: 0;
        }
      }
    }
  }
}

.layout-template-list {
  max-height: calc(100vh - 250px);
  margin: 0 2px;
  padding: 0 18px;
  overflow-y: auto;
  hr {
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
    border-style: dashed;
    border-bottom: 1px solid var(--secondary-color);
  }
  .template-list-item {
    position: relative;

    span {
      position: absolute;
      display: block;
      padding: 5px 10px;
      top: 10px;
      right: 10px;
      background-color: var(--secondary-color);
    }
  }
}

.settings-options-list {
  // max-height: calc(100vh - 320px);
  // padding: 0 18px;
  // margin: 0 2px;
  // overflow-y: auto;
}
.ant-collapse-item {
  .settings-option {
    // margin-bottom: 20px;
    // padding-bottom: 20px;
    // border-bottom: 1px dashed var(--border-color);
  }
}
.option-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  margin-bottom: 10px;
  font-size: 12px;
  font-family: "Poppins-SemiBold";
  color: var(--primary-color);
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
}
.option-label {
  margin-bottom: 5px;
  font-family: "Poppins-SemiBold";
  font-size: 12px;
}

.layout-template-list {
  li {
    padding: 15px;
    margin-bottom: 10px;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius-medium);
    cursor: pointer;
    &.active {
      border-color: var(--success-color);
      background-color: var(--default-color);
    }
    &.active-noselect {
      background-color: var(--secondary-color);
      border-color: var(--success-color);
    }
    &.active-selected {
      border-color: var(--success-color);
      border-width: 2px;
    }
    img {
      display: block;
      margin: auto;
      object-fit: contain;
      border-radius: var(--border-radius-medium);
      height: 150px;
      object-fit: contain;
    }
  }
}

.placements-dropdown {
  ul {
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 0 10px;
      .placement-d-title {
        flex-grow: 1;
        margin-right: 10px;
      }
    }
  }
}

.placement-tabs {
  &.ant-tabs {
    display: block;
    // max-height: calc(100vh - 320px);
    // padding: 0 0px 0 0;
    // margin: 0 -18px 0 -20px;
    // overflow-y: auto;
    &.ant-tabs-right {
      .ant-tabs-nav {
        flex-grow: 1;
        .ant-tabs-nav-list {
          white-space: break-spaces;
        }
        .ant-tabs-nav-wrap {
          white-space: break-all;
        }
        .ant-tabs-nav-operations {
          display: none;
        }
        .ant-tabs-ink-bar {
          width: 4px;
          border-radius: var(--border-radius-large);
        }
      }

      .ant-tabs-tab {
        display: block;
        margin: 0;
        padding: 0;
        text-align: left;
        flex-wrap: wrap;
        cursor: default;
        .ant-tabs-tab-btn {
          display: block;
        }

        .ant-tabs-tab-active {
          .pages-box {
            opacity: 1;
          }
        }

        &:not(.ant-tabs-tab-active) {
          .pages-box {
            cursor: pointer;
            opacity: 0.4;
            > * {
              pointer-events: none;
            }
            &:hover {
              opacity: 1;
            }
          }
        }

        &.ant-tabs-tab-active,
        &:hover {
          .ant-tabs-tab-btn {
            color: var(--primary-color);
          }
        }
      }
    }
  }

  .ant-tabs-content-holder {
    display: none;
  }
}

.desktop-placement,
.mobile-placement {
  position: relative;
  width: 100%;
  height: 100%;
  span {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: var(--background-color);
    border: 1px solid var(--border-color);
    cursor: pointer;
    &.placement-top {
      left: 50%;
      top: 10px;
      transform: translate(-50%, 0);
    }
    &.placement-topLeft {
      left: 10px;
      top: 10px;
      transform: translate(0, 0);
    }
    &.placement-center {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    &.placement-left {
      left: 10px;
      top: 50%;
      transform: translate(0, -50%);
    }
    &.placement-topRight {
      right: 10px;
      top: 10px;
      transform: translate(0, 0);
    }
    &.placement-right {
      right: 10px;
      top: 50%;
      transform: translate(0, -50%);
    }
    &.placement-bottomLeft {
      left: 10px;
      bottom: 10px;
      transform: translate(0, 0);
    }
    &.placement-bottom {
      left: 50%;
      bottom: 10px;
      transform: translate(-50%, 0);
    }
    &.placement-bottomRight {
      right: 10px;
      bottom: 10px;
      transform: translate(0, 0);
    }
    &.active {
      background-color: var(--success-color);
      border: 1px solid var(--success-color);
    }
    &:hover {
      border: 1px solid var(--success-color);
    }
  }
}

.bottom-content,
.top-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100px;
  min-width: 100px;
}

.desktop-screen {
  position: absolute;
  width: 80%;
  height: 80%;
  max-width: 130px;
  left: 50%;
  transform: translate(-50%, 0);
  top: 0;
  border-radius: var(--border-radius-medium);
  background-color: #eeeeee;
}
.desktop-tail {
  position: absolute;
  width: 7%;
  height: 10%;
  left: 50%;
  top: 80%;
  transform: translate(-50%, 0);
  background-color: #eeeeee;
}
.desktop-legs {
  position: absolute;
  width: 40%;
  height: 7%;
  left: 50%;
  top: 90%;
  max-width: 70px;
  transform: translate(-50%, 0);
  border-radius: var(--border-radius-medium);
  background-color: #eeeeee;
}

.mobile-screen {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: 70px;
  max-width: 100px;
  width: 40%;
  height: 90%;
  background-color: #eeeeee;
  border-radius: var(--border-radius-medium);
}

.option-box {
  margin-bottom: 20px;
}

.advanced-options {
  display: inline-block;
  padding: 0 6px;
  font-size: 12px;
  line-height: 21px;
  background-color: var(--secondary-color);
  border-radius: var(--border-radius-small);
  color: var(--font-color);
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
}

.option-single-col {
  margin-bottom: 10px;
}

.option-list-col {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
  .option-item {
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    color: var(--font-color);
    cursor: pointer;
    span {
      margin-top: 2px;
      display: block;
    }
    &.active,
    &:hover {
      .option-item-icon {
        background-color: var(--secondary-color);
        border-color: var(--success-color);
        svg {
          path,
          rect {
            fill: var(--success-color);
          }
        }
      }
    }
  }
  .option-item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    width: 100%;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius-medium);
    svg {
      path,
      rect {
        fill: var(--disable-color);
      }
    }
  }
}

.option-two-col {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .option-col {
    flex: 0 0 50%;
    &:first-child {
      padding-right: 10px;
    }
    &.option-fix {
      flex: 0 0 60px;
    }
    &.option-full {
      flex: 1;
    }
    .copy-icon {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 80px;
      margin-left: 5px;
      border: 1px solid var(--border-color);
      border-radius: var(--border-radius-small);
      padding: 3px 10px;
    }
  }
}

.option-col {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: var(--font-color);
}

.sub-option {
  padding: 5px 10px;
  border: 1px solid var(--secondary-color);
  background: var(--secondary-light-color);
  margin-bottom: 10px;
  border-radius: 6px;
}

.color-box {
  display: inline-block;
  width: 25px;
  flex: 0 0 25px;
  height: 20px;
  margin-left: -2px;
  margin-right: 5px;
  border-radius: var(--border-radius-small);
  border: 1px solid var(--border-color);
  & + label {
    font-family: Poppins-Regular;
    font-weight: normal;
    font-size: 12px;
    color: var(--font-color);
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
}

.button-shape-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 12px;
  flex-grow: 1;
  border: 1px solid transparent;
  border-radius: var(--border-radius-small);
  .button-shape {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    height: 30px;
    margin: 5px;
    padding: 0 15px;
    overflow: hidden;
    background-color: var(--secondary-color);
    color: var(--primary-color);
    span {
      word-break: break-word;
    }
    &.roundingAll {
      border-radius: var(--border-radius-large);
    }
    &.roundingTop {
      border-top-left-radius: var(--border-radius-large);
      border-top-right-radius: var(--border-radius-large);
    }
    &.roundingBottom {
      border-bottom-left-radius: var(--border-radius-large);
      border-bottom-right-radius: var(--border-radius-large);
    }
    &.squarel {
      border-radius: 0;
    }
    &.circle {
      flex-grow: 0;
      width: 70px;
      height: 70px;
      padding: 5px;
      text-align: center;
      border-radius: 100%;
      overflow: hidden;
    }
    &.squareImage {
      display: flex;
      flex-direction: column;
      flex-grow: 0;
      width: calc(100% - 40px);
      height: 100px;
      svg {
        width: 40px;
        margin-bottom: 5px;
      }
    }
    &.squareImageLock {
      display: flex;
      flex-direction: column;
      flex-grow: 0;
      width: calc(100% - 40px);
      height: 100px;
      svg {
        height: 40px;
        width: 24px;
        margin-bottom: 5px;
      }
    }
    cursor: pointer;
  }
  &:hover {
    &:not(.active) {
      opacity: 0.7;
    }
  }
  &.active {
    border: 1px solid var(--success-color);
  }
}

.text-select {
  padding: 3px;
  margin-right: 5px;
  font-size: 12px;
  color: var(--font-dark-menu);
  border-radius: var(--border-radius-small);
  cursor: pointer;
  &:hover {
    &:not(.active) {
      opacity: 0.7;
    }
  }
  &.active {
    color: var(--font-color);
    background-color: var(--secondary-color);
  }
}
.settings-widgets {
  flex-basis: 50%;
  align-self: baseline;
}

.content-mix-details {
  display: flex;
  justify-self: center;
  .widget {
    padding: 50px;
    flex-grow: 1;
    &.widget-full {
      min-width: 450px;
    }

    &.widget-icon {
      padding: 24px;
    }
  }
}

.social-grid {
  display: grid;
  grid-template-columns: calc(50% - 5px) calc(50% - 5px);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  text-align: center;
  margin-bottom: 15px;
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: center;
  .checkbox-wrapper {
    padding: 10px;
    // margin-left: 10px;
    // margin-right: 10px;
    // margin-bottom: 10px;
    // flex-grow: 1;
    background-color: var(--secondary-color);
    border-radius: var(--border-radius-medium);
  }
}

.condition-header {
  position: relative;
  text-align: center;
  padding: 0 100px;
  font-size: 15px;
  font-family: "Poppins-SemiBold";

  .condition-rule {
    position: absolute;
    left: 0;
    width: 100px;
    color: var(--font-color);
    font-size: 13px;
    label {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .active {
      color: var(--success-color);
    }
  }
}
.condition-header {
  margin-bottom: 20px;
}

.condition-rules-header {
  padding: 10px;
  margin-left: 25px;
  margin-right: 25px;
  color: var(--font-color);
}

.condition-rules-header,
.condition-rule-row {
  display: grid;
  &.x4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  &.x3 {
    grid-template-columns: 1fr 1fr 1fr;
  }
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.condition-rules-inputs {
  padding: 25px;
  background-color: var(--secondary-color);
  border-radius: var(--border-radius-large);
  &--disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.3;
  }
}

.condition-rule-row {
  position: relative;
  padding: 10px;
  margin: 0 25px 25px 25px;
  background-color: var(--background-color);
  border-radius: var(--border-radius-large);
  &:last-child {
    margin-bottom: 15px;

    .condition-float-label {
      display: none;
    }
  }

  .condition-rule-action {
    position: absolute;
    right: -25px;
    top: calc(50% - 6.5px);
    svg {
      cursor: pointer;
    }
  }
}

.condition-footer {
  position: relative;
  margin: 0 25px;
}

.condition-float-label {
  position: absolute;
  bottom: 0;
  left: -10px;
  transform: translateY(calc(50% + 12px));
  &::before {
    content: "";
    display: block;
    width: 10px;
    height: 30px;
    border-top-left-radius: 10px;
    border-left: 1px solid var(--font-disabled-color);
    border-top: 1px solid var(--font-disabled-color);
  }

  &::after {
    content: "";
    display: block;
    width: 10px;
    height: 30px;
    border-bottom-left-radius: 10px;
    border-left: 1px solid var(--font-disabled-color);
    border-bottom: 1px solid var(--font-disabled-color);
  }

  span {
    display: block;
    background-color: var(--font-disabled-color);
    color: var(--background-color);
    font-size: 13px;
    font-family: "Poppins-SemiBold";
    border-radius: 12px;
    padding: 0 5px;
    text-transform: uppercase;
    transform: translateX(-50%);
  }
}

.item-placement {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  margin-top: 15px;
  background-color: var(--background-color);
  border-radius: var(--border-radius-large);
}

.modal-layout {
  left: 0;
  top: 16px;
  padding-bottom: 0;
  height: calc(100vh - 32px);
  max-width: none;
  box-shadow: none;
  animation-duration: 0s !important;

  .view-content {
    margin: 0;
    &.mobile {
      margin: 0 auto;
    }
  }
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-content {
    background-color: var(--secondary-color);
    height: 100%;
  }
  .ant-modal-close {
    top: 18px;
    right: 20px;
    .ant-modal-close-x {
      width: 34px;
      height: 34px;
      line-height: 34px;
      border-radius: 100%;
      background-color: #72778a1f;
    }
  }
  .modal-layout-header {
    display: flex;
    justify-content: space-between;
    padding: 20px 40px;
    background-color: var(--background-color);
  }
}

.widget-layout {
  display: flex;
  // background-color: #fff;
  height: calc(100vh - 200px);
  margin-bottom: 0;
  padding: 0 40px 20px;
  &.widget-layout-template {
    height: calc(100vh - 177px);
  }
  .ant-tabs {
    height: calc(100% - 22px);
    .ant-tabs-content-holder {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}

.modal-layout-filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 40px 10px;
  &.large {
    padding: 30px 60px 20px 40px;
  }
}

.modal-layout-advance-options {
  padding: 10px 40px 15px;
  background: var(--secondary-light-color);
}

.advance-options-row {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.advance-options-title {
  font-size: 16px;
  margin-bottom: 5px;
}

.advance-options-description {
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-bottom: 10px;
}

.modal-layout-type {
  display: flex;
  align-items: center;
  flex-basis: 50%;
}

.modal-layout-type-toggle {
  padding: 6px 12px;
  margin-left: 20px;
  font-size: 14px;
  background-color: #edf5ff;
  border-radius: var(--border-radius-medium);
  .pre-active {
    font-family: "Poppins-SemiBold";
    color: var(--active-color);
  }
  .post-active {
    font-family: "Poppins-SemiBold";
    color: var(--label-active-font);
  }
  .ant-switch {
    margin: 0 5px;
    background-color: var(--active-color);
    .ant-switch-handle {
      &::before {
        background-color: var(--background-color);
      }
    }
    &.ant-switch.ant-switch-checked {
      background-color: var(--label-active-font);
    }
  }
}
.modal-layout-device {
  display: flex;
  background-color: var(--secondary-color);
  border-radius: var(--border-radius-medium);
}

.modal-layout-advance {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
  margin-right: 30px;
  margin-left: 15px;
  .advance-trigger {
    display: flex;
    align-items: center;
    color: var(--active-color);
  }
}

.placement-acions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.placement-expand {
  color: #c9cfd6;
}
.placement-title {
  font-size: 13px;
  font-family: Poppins-SemiBold;
}
.placement-description {
  font-size: 13px;
  font-weight: 400;
  &.placement-valid {
    color: #202223;
  }
  &.placement-invalid {
    color: #e80000;
  }
}
.placement-toggle {
  color: #63686a;
  font-size: 13px;
  font-weight: 400;
  &.active {
    color: #007a5c;
  }
}
.placement-button {
  background-color: #f1f2f3;
  margin-left: 10px;
  padding: 6px 12px;
  font-size: 13px;
  font-weight: 400;
  border-radius: 4px;
  color: #63686a;
  cursor: pointer;
  &:hover {
    background-color: #e0ede1;
  }
}

.layout-name {
  line-height: 30px;
  &.layout-link-disabled {
    opacity: 0.6;
    cursor: no-drop;
  }
}

.template-choose-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  margin-right: 10px;
  line-height: 34px;
  border-radius: 100%;
  background-color: rgba(114, 119, 138, 0.1215686275);
}

.layout-url {
  display: flex;
  align-items: center;
  .layout-globe-icon {
    display: flex;
    align-items: center;
    margin-right: 5px;
  }
}

.filter-label {
  display: inline-flex;
  align-items: center;
  margin-right: 20px;
  font-size: 14px;
  color: var(--font-color);
  cursor: pointer;
}

.campaign-badges {
  &-subtitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: var(--font-color);
    &-bold {
      font-weight: 600;
    }
  }

  &-radio {
    width: 100%;
    .ant-radio-group {
      display: flex;
      width: 100%;
      .ant-radio-wrapper {
        width: 33%;
      }
    }
  }

  .ant-checkbox-wrapper {
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        border-radius: 2px 0px 0px 0px;
        background-color: var(--success-color);
        border-color: var(--success-color);
      }
    }

    span {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      text-align: left;
      color: var(--font-color) !important;
    }
  }

  .frame {
    background-color: transparent;
    padding: 0px;
    .group {
      background-color: white !important;
      .campaign-badges-checkmark {
        position: absolute;
        right: 4px;
        top: 4px;
      }
    }
    .text-wrapper,
    .text-wrapper-4 {
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      text-align: left;
      color: var(--font-color);
    }
  }

  .scheduling-header {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .ant-switch {
      background-color: #d3d5dd;
    }
    .ant-switch-checked {
      background-color: var(--success-color);
    }
    .ant-switch-handle::before {
      background-color: var(--background-color);
    }
  }

  .scheduling-table {
    width: 100%;
    thead {
      tr {
        th {
          width: 12.5%;
          font-family: Poppins;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          text-align: center;
          color: var(--font-color);
          cursor: pointer;
          &:hover {
            color: var(--success-color);
          }
        }
      }
    }
    tbody {
      tr {
        td {
          font-family: Poppins;
          font-size: 12px;
          font-weight: 400;
          line-height: 28px;
          text-align: center;
          color: var(--font-color);
          border-right: 4px solid var(--secondary-color);
          border-left: 4px solid var(--secondary-color);
          cursor: default;
          &:not(:first-child) {
            cursor: pointer;
            background-color: white;
            border-bottom: 1px solid var(--secondary-color);
          }
        }
      }
      .rows-with-border-top {
        border-radius: 16px 16px 0px 0px;
      }
      .rows-with-border-bottom {
        border-radius: 0px 0px 16px 16px;
      }
    }
    .scheduling-button {
      color: var(--success-color);
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      text-align: center;
      cursor: pointer;
    }
  }
}

// Scc for devices smaller than desktop only
@media only screen and (max-width: 1300px) {
  .sidebar {
    + .wrapper {
      .content-mix-details {
        flex-direction: column;

        .settings-widgets {
          width: 100%;
          align-self: center;
        }
        .widget,
        .widget-wrapper {
          width: 100%;
          max-width: 550px;
          margin-left: auto;
          margin-right: auto;
          flex-shrink: 0;
        }
      }
    }
  }
}

.settings-text {
  font-family: Poppins-Regular;
  font-weight: normal;
  font-size: 12px;
  color: var(--font-color);
  cursor: pointer;
}

.not-active {
  color: var(--fail-color);
  font-weight: 900;
  font-family: Poppins !important;
}

// Scc for devices smaller than desktop only
@media only screen and (max-width: 1100px) {
  .content-mix-details {
    flex-direction: column;

    .settings-widgets {
      width: 100%;
      align-self: center;
    }
    .widget {
      width: 100%;
      flex-shrink: 0;
      &.widget-full {
        margin-right: 0;
        min-width: auto;
      }
    }
  }
}
