.payments {
  height: 100%;
  &-left {
    width: 310px;
    background-color: white;
    height: 100%;
    padding: 20px;
    overflow: scroll;
    .payments-item {
      border: 1px solid #d3d5dd;
      border-radius: 8px;
      padding: 12px 16px;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      margin-bottom: 20px;

      &:hover {
        border-color: #3ab88e;
        background-color: #eaf8f4;
        transition: all 0.2s ease-in;
      }

      &-date {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: var(--font-color);
        margin-bottom: 12px;
      }

      &-price {
        font-family: Poppins;
        font-size: 18px;
        font-weight: 600;
        line-height: 19px;
        text-align: left;
        color: #000;
      }
    }

    .active-month {
      border-color: #3ab88e;
      background-color: #eaf8f4;
    }
    .payments-loading-state {
      margin: 60px 0;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  &-right {
    width: 100%;
    padding: 20px;
    .payments-filters {
      background-color: white;
      padding: 8px;
      border-radius: 8px;
      width: fit-content;
      .label-input-wrapper {
        width: 200px;
        margin-bottom: 0px;
        &:first-child {
          margin-right: 8px;
        }
      }
      .apply-button {
        height: 100%;
        line-height: 50px;
        border-color: #3ab88e;
        background-color: #eaf8f4;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        text-align: left;
      }
    }
  }
}
