.insights-drawer {
  display: flex;
  flex-direction: column;
  width: 100%;
  &-item {
    margin-bottom: 30px;

    &--page {
      padding: 24px;
      background-color: white;
      border-radius: 8px;
    }

    .title {
      font-size: 21px;
      font-weight: 600;
      line-height: 24px;
      margin: 0;
    }

    .subtitle {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      margin-bottom: 10px;
    }

    .three-boxes {
      width: 100%;
      display: flex;
      &-item {
        display: flex;
        flex-direction: column;
        padding: 24px;
        border: 1px solid #d3d5dd;
        border-radius: 8px;
        margin-right: 16px;
        flex: 1;

        &:last-child {
          margin-right: 0;
        }

        &--white {
          background-color: white;
          border-color: transparent;
        }

        &-header {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          &-image {
            margin-right: 5px;
          }
          &-title {
            font-size: 21px;
            font-weight: 600;
            line-height: 24px;
            color: #161616;
          }
        }
        &-content {
          display: flex;
          align-items: center;
          &-number {
            font-size: 40px;
            font-weight: 600;
            line-height: 32px;
            color: #161616;
            margin-right: 30px;
          }

          &-percentage {
            font-family: Poppins;
            font-size: 18px;
            font-weight: 600;
            line-height: 19px;
          }
        }
      }
    }

    //heatmap
    .device-select {
      min-width: 160px;
      .ant-select-selection-item {
        line-height: 30px !important;
        font-size: 21px;
        font-weight: 600;
        line-height: 24px;
        margin: 0;
        font-family: Poppins-SemiBold;
      }
    }

    .donut-chart {
      position: relative;
      width: 330px;
      .total-number {
        position: absolute;
        top: 40%;
        .total-content {
          width: 242px;
          display: flex;
          flex-direction: column;
          justify-items: center;
          align-items: center;
          .number {
            font-family: Poppins;
            font-size: 24px;
            font-weight: 600;
            line-height: 28px;
            text-align: center;
            margin: 0;
          }
          .text {
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            margin: 0;
          }
        }
      }
    }

    .loading-state {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      &--400 {
        height: 400px;
      }

      &--350 {
        height: 350px;
      }

      &--250 {
        height: 250px;
      }

      svg {
        height: 60px;
        width: 60px;
      }
    }
  }

  .flex-50 {
    flex-basis: 50%;
  }

  .highcharts-credits {
    display: none;
  }

  .chart-header {
    margin-bottom: 30px;
  }

  .no-orders {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
