.template-preview {
  position: relative;
  background-image: url("./blur-bg.svg");
  height: 724px;
  gap: 0px;
  border-radius: 8.72px 0px 0px 0px;
  background-size: cover;
  margin-bottom: 90px;

  .device-switch {
    display: flex;
    width: 86px;
    height: 34px;
    position: absolute;
    top: 20px;
    right: 20px;
    border-radius: 4px;
    border: 1px solid #fff;
    background-color: #fff;
    z-index: 2;
    .active {
      background-color: #007a5c;
    }
    .icon-holder {
      border-radius: 4px;
      &.active {
        border-color: #fff;
      }
    }
  }
}
