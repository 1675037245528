.offer-preview {
  position: relative;
  padding: 5px;
  min-height: 400px;
  max-height: calc(100vh - 260px);
  background-color: var(--font-color);
  border-radius: var(--border-radius-medium);
  color: var(--background-color);
  overflow: auto;
  .offer-preview-featured {
    position: relative;
    min-height: 600px;
    min-width: 900px;
    .offer-preview-box {
      &.feature {
        max-width: 880px;
        width: 880px;
        .offer-preview-image.feature {
          width: 856px;
          height: 300px;
          max-width: 856px;
          margin-bottom: 15px;
        }
      }
    }
  }
}

.offer-preview-bottomtext {
  position: absolute;
  bottom: 0;
  left: 5px;
}

.offer-preview-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: calc(100% - 10px);
  max-height: calc(100% - 60px);
  padding: 12px;
  background-color: var(--background-color);
  border-radius: var(--border-radius-medium);
  &.compact {
    display: flex;
  }
  &.popup {
    width: 244px;
    padding-bottom: 50px;
  }
  &.feature {
    width: calc(100% - 50px);
    max-width: 600px;

    .offer-preview-content {
      width: 100%;
    }
  }
}

.offer-preview-image {
  overflow: hidden;
  background-color: var(--secondary-color);
  border-radius: var(--border-radius-medium);
  &.popup {
    width: 100%;
    height: 200px;
    max-width: 100%;
    margin-bottom: 15px;
  }
  &.compact {
    width: 74px;
    height: 74px;
    margin-right: 15px;
  }
  &.feature {
    width: 100%;
    height: 250px;
    max-width: 100%;
    margin-bottom: 15px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.offer-preview-content {
  width: 200px;
  max-width: calc(100% - 10px);
}

.offer-preview-title {
  color: var(--primary-color);
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
}

.offer-preview-description {
  color: var(--primary-color);
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
}

.voucher-uploaded {
  .ant-upload.ant-upload-drag {
    .ant-upload-drag-container {
      display: flex;
      align-items: center;
      justify-content: space-around;
      img {
        border-radius: var(--border-radius-medium);
        margin-left: 20px;
        max-width: 100px;
      }
    }
  }
}

.voucher-multiple-upload {
  .ant-upload.ant-upload-drag {
    .ant-upload-drag-container {
      display: block;
      img {
        border-radius: var(--border-radius-medium);
        margin-left: 20px;
        max-width: 74px;
        margin-bottom: 20px;
      }
    }
  }
}
