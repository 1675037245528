.auth {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.sidebar {
  display: flex;
  flex-direction: column;
  max-width: 250px;
  min-width: 250px;
  background-color: var(--new-primary-color);
  transition: max-width 0.3s ease-in-out, min-width 0.3s ease-in-out;
  overflow: auto;

  &.closed {
    max-width: 117px;
    min-width: 117px;

    .logo {
      img {
        width: 40px;
        height: 40px;
      }
    }

    .sidebar-list {
      li {
        a,
        .a-link {
          padding: 0;
          .list-link {
            padding: 0;
            border-radius: 50%;
            margin: 0 0 5px 0;
            width: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .list-label {
            opacity: 0;
            max-width: 0;
            overflow: hidden;
            transition-delay: 0s;
          }
        }
      }
    }
  }
}

.sidebar-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  &.main {
    flex-grow: 1;
  }
  li {
    position: relative;
    a,
    .a-link {
      width: 100%;
      padding: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.3s ease-in-out;
      color: var(--font-dark-menu);
      cursor: pointer;

      .list-link {
        display: block;
        width: 175px;
        transition: background-color 0.3s ease-in-out, width 0.3s ease-in-out;
        border-radius: 48px;
        width: 100%;
        padding: 0 18px;
        margin: 0 12px 5px 12px;
        &:hover {
          background-color: #343434;
        }
      }
      img {
        width: 24px;
        height: 60px;
        object-fit: contain;
      }
      &.active {
        background-color: #181617;
        color: var(--background-color);
        .list-link {
          background-color: #343434;
        }
      }
      &:hover {
        color: var(--background-color);
      }
      .list-label {
        position: absolute;
        display: inline-block;
        max-width: 250px;
        height: 64px;
        line-height: 64px;
        left: 80px;
        font-size: 16px;
        transition: opacity 0.3s ease-in-out;
        transition-delay: 0.3s;
      }
    }
  }
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  margin: 0 22px;

  img {
    width: 100%;
    object-fit: contain;
    transition: all 0.3s ease-in-out;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: 100vh;
  overflow: hidden;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 70px;
  padding: 0 50px;
  border-bottom: 1px solid var(--border-color);
}

.scroll-dark {
  /* Track */
  &::-webkit-scrollbar-track {
    background-color: var(--border-color);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background-color: var(--default-icon-color);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
    background-color: var(--default-icon-color);
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: var(--secondary-color);
  overflow: hidden;
  overflow-y: auto;

  &.campaign-content {
    overflow-y: hidden;
    .content-layout {
      max-height: calc(100vh - 140px);
      overflow-y: auto;
    }
  }

  .footer {
    padding: 20px 50px;
    span,
    ul {
      margin: 0;
    }
  }
}

.content-layout {
  flex-grow: 1;
  padding: 20px 50px;
  &.content-center {
    display: flex;
    justify-self: center;
    align-items: center;
    .widget {
      padding: 50px;
      flex-grow: 1;
    }
  }
  &.content-offers {
    align-items: baseline;
    flex-grow: 0;
    .widget-centered {
      max-width: 650px;
      margin-left: auto;
      margin-right: auto;
    }

    .widget-form-description {
      text-align: left;
    }
  }
}

.content-header {
  padding: 0 50px;
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--background-color);
  overflow-y: auto;
}

.content-list {
  display: block;

  li {
    position: relative;
    display: inline-flex;
    padding: 0 15px;
    height: 70px;
    align-items: center;
    font-size: 14px;
    color: #a0a2a7;
    font-family: "Poppins-SemiBold";

    cursor: pointer;
    &.active {
      font-family: "Poppins-SemiBold";
      color: #000;
      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: var(--success-color);
        border-radius: var(--border-radius-large);
      }
    }
  }
}

.content-action {
  display: flex;
  button {
    margin-left: 10px;
  }
}

// ToDo REMOVED THIS AFTER DESIGN CHANGED
.content-footer {
  padding: 0 50px;
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--background-color);
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  .updat-info {
    font-size: 13px;
    color: var(--font-color);
    img {
      margin-right: 5px;
    }
  }
}

.header-title {
  font-size: 15px;
  font-family: Poppins-SemiBold;
  margin-right: 10px;
  text-transform: capitalize;
}

.header-icons {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    li {
      display: flex;
      align-items: center;
      padding: 0 15px;
      font-size: 14px;
      border-right: 1px solid var(--border-color);
      text-transform: capitalize;
      &:last-child {
        border-right: 0;
      }
      .header-name {
        margin-left: 10px;
      }
      .icon-arrow-down {
        margin-left: 7px;
      }
      .dropdown-trigger {
        display: flex;
        align-items: center;
        align-self: stretch;
      }
      .char {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0px 5px;
        margin-left: 5px;
        border-radius: var(--border-radius-small);
        color: #fff;
        background-color: #c8d0d9;
        cursor: pointer;
        &:not(.active):hover {
          opacity: 0.7;
        }
        &.active {
          background-color: #844aff;
        }
      }
    }
  }
  button {
    margin-left: 15px;
  }
}

.dropdown-buttons {
  .ant-dropdown-menu-item {
    cursor: default;
  }
}

.layout-actions {
  display: flex;
  button {
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.layout-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 30px;
  font-size: 18px;
}

.layout-title {
  font-size: 18px;
  font-family: Poppins-Regular;
}

.layout-options {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.filters,
.views {
  display: flex;
  align-items: center;
}

.filters-padding {
  padding: 20px 50px 0 50px;
}

.filter-checkbox {
  &::after {
    content: "";
    display: inline-block;
    margin: 0 10px;
    height: 22px;
    width: 1px;
    vertical-align: middle;
    background-color: var(--border-color);
  }
  .checkbox-active {
    &.ant-checkbox-wrapper {
      color: var(--font-color);
    }
  }
}

.filter-icon {
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-left: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  &.list {
    background-image: url("../../../../assets//images/icons/list-view-icon.svg");
    &.active {
      background-image: url("../../../../assets//images/icons/list-view-active-icon.svg");
    }
  }

  &.grid {
    background-image: url("../../../../assets//images/icons/grid-view-icon.svg");
    &.active {
      background-image: url("../../../../assets//images/icons/grid-view-active-icon.svg");
    }
  }
}

.company-list-type {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .char {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0px 5px;
    margin-left: 5px;
    border-radius: var(--border-radius-small);
    color: #fff;
    background-color: #c8d0d9;
    cursor: pointer;
    &:not(.active):hover {
      opacity: 0.7;
    }
    &.active {
      background-color: #844aff;
    }
  }
}
