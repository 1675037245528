.adv-embedd-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px dashed #ddd;
}

.super-desktop-feature + .inline-grid {
  // max-width: 858px;
  margin: auto;
}
.inline-popup {
  .feature-item {
    position: relative;
    width: 100%;
    margin: 10px auto;
    border: 0;
    overflow: initial;

    .super-item-title {
      font-weight: 600;
      font-size: 15px;
    }

    .super-item-email-wrapper {
      .super-item-email-title {
        text-align: left;
      }
    }

    .super-item-info-icon {
      margin: 0 10px;
    }

    &.wide {
      margin: 10px auto;
    }

    .super-item-layout {
      position: relative;
      display: block;
      border-left: 1px solid #d6dae5;
      border-right: 1px solid #d6dae5;
      border-bottom: 1px solid #d6dae5;
      border-radius: 10px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      overflow: hidden;
    }
    .hidden-input {
      display: none;
    }

    .super-item-image {
      width: 100%;
      height: 300px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .super-item-content {
      padding: 15px 20px;
      height: 100px;
      display: flex;
    }

    .super-item-content-info {
      flex-grow: 1;
      margin-right: 10px;
    }

    .super-item-email {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 20px;
      background-color: #f2f2f2;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;

      .label-wrapper {
        position: absolute;
        bottom: -12px;
        left: 50%;
        transform: translate(-50%, 0);
        .label-error {
          font-size: 10px;
        }
      }
    }

    .super-item-email-wrapper {
      margin-right: 20px;
    }

    .super-item-action-get {
      padding: 12px 25px;
    }

    .super-item-get-icons {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px 5px;
    }
  }
}

.esuper {
  // grid
  .inline-popup {
    overflow: hidden;
    position: relative;
    padding: 15px;
    background-color: #fff;
    font-family: "Poppins";
  }

  .inline-minimize {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    border-radius: 100%;
    background-color: #e2e2e2;
    cursor: pointer;
    svg {
      width: 10px;
    }
    &:hover {
      background-color: #f2f2f2;
    }
  }

  .inline-wrapper {
    &.left {
      .inline-title,
      .inline-footer {
        justify-content: flex-start;
        text-align: left;
      }

      .inline-description {
        text-align: left;
      }

      .inline-grid {
        justify-content: flex-start;
      }

      .no-navigation {
        .grid {
          margin-left: 0;
        }
      }
      .preview-f-openfilters {
        align-items: flex-start;
      }

      .preview-f-closedfilters {
        justify-content: flex-start;
      }
    }

    &.right {
      .inline-title,
      .inline-footer {
        justify-content: flex-end;
        text-align: right;
        padding: 0;
      }

      .inline-footer {
        flex-direction: row-reverse;
        justify-content: flex-start;
        svg {
          margin-right: 0;
          margin-left: 10px;
        }
      }

      .no-navigation {
        .grid {
          margin-right: 0;
        }
      }

      .inline-description {
        text-align: right;
        padding: 0;
      }

      .inline-grid {
        justify-content: flex-end;
      }

      .preview-f-openfilters {
        align-items: flex-end;
      }

      .preview-f-closedfilters {
        justify-content: flex-end;
      }

      .preview-f-button:last-child {
        margin-right: 0;
      }

      .grid-wrapper {
        margin-left: auto;
      }
    }

    &.center {
      .inline-title,
      .inline-footer {
        justify-content: center;
        text-align: center;
      }

      .inline-description {
        text-align: center;
      }

      .inline-grid {
        justify-content: center;
      }

      .grid-wrapper {
        margin: auto;
      }
    }
  }

  .inline-title {
    display: flex;
    align-items: center;
    justify-content: center;
    .inline-title-label {
      font-size: 22px;
      display: inline-block;
      vertical-align: middle;
    }
  }

  .inline-title-icon {
    svg {
      max-width: 20px;
      max-height: 20px;
      margin-right: 5px;
    }
  }

  .inline-description {
    text-align: center;
  }

  .hidden {
    display: none;
  }

  .displayed {
    animation: fade 0.5s;
  }

  @keyframes fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .inline-grid {
    overflow: hidden;
    .inline-grid-wrapper {
      position: relative;
      display: flex;
      justify-content: center;
      padding: 0 40px;
    }
    .grid {
      position: initial;
      padding: 20px 0;
      display: grid;
      grid-gap: 15px;
      .grid-overflow {
        overflow: hidden;
      }
      .grid-item {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        &.grid-left {
          .item-image {
            margin-left: 0;
          }
          .item-content {
            text-align: left;
          }
          .item-get-center,
          .item-email-label {
            justify-content: flex-start;
          }
        }

        &.grid-right {
          .item-image {
            margin-right: 0;
          }
          .item-content {
            text-align: right;
          }
          .item-get-center,
          .item-email-label {
            justify-content: flex-end;
            .item-action-get {
              margin-right: 0;
            }
          }
        }

        &.grid-center {
          .item-content {
            text-align: center;
          }
          .item-get-center,
          .item-email-label {
            justify-content: center;
          }
        }

        input {
          display: none;
        }
        &.display {
          & > label {
            cursor: pointer;
            &:hover {
              .switch-text {
                opacity: 0.6;
              }
            }
          }
        }
        & > label {
          display: flex;
          flex-direction: column;
          padding: 10px;
          height: 100%;
          border-radius: 8px;
          border: 1px solid #d6dae5;
          background-color: #ffffff;
        }
      }
    }

    .item-image {
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      margin-bottom: 5px;
      img {
        max-width: 100%;
        width: 100%;
        height: 200px;
        margin: auto;
        margin-top: 0;
        border-radius: 8px;
        object-fit: cover;
        object-position: center;
        overflow: hidden;
        pointer-events: none;
      }
    }

    .item-content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      text-align: left;
      margin: 0 5px;
    }

    .item-get {
      flex-grow: 1;
      display: flex;
      align-items: flex-end;
    }

    .item-get-center {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }

    .item-title {
      font-weight: 600;
      font-size: 15px;
    }

    .item-action-get {
      padding: 6px 12px;
      margin: 0 7px;
      &:hover {
        opacity: 0.7;
        cursor: pointer;
      }
    }

    .item-info-icon {
      position: relative;
      display: flex;
      align-items: center;
      min-width: 15px;
      margin: 7px;
      cursor: pointer;
      &:hover {
        opacity: 0.6;
      }
      &::before {
        content: "";
        position: absolute;
        right: -12px;
        top: -10px;
        width: 40px;
        height: 40px;
      }
      svg {
        width: 16px;
        height: 16px;
      }
    }

    .input:checked + .item-layout {
      position: relative;
      border-color: transparent;
      border-color: #3ab78f;
      border: 1px solid;
    }
  }

  .item-layout,
  .item-title-text,
  .item-image,
  .item-description {
    cursor: pointer;
  }

  .item-description {
    margin-bottom: 10px;
  }

  .item-email-label {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    padding: 10px;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
    .item-email-icon {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 5px;
      border-radius: 100%;
      border: none;
      &:hover {
        opacity: 1;
      }
    }
  }

  .inline-footer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 15px;
    font-size: 12px;
    svg {
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }
    &:hover {
      cursor: pointer;
      .inline-tooltip {
        display: block;
      }
    }
  }

  .inline-tooltip {
    display: none;
    position: absolute;
    max-width: calc(100% - 20px);
    bottom: calc(100% + 10px);
    left: 10px;
    padding: 10px;
    background-color: #fef8de;
    color: #333333;
    z-index: 10;
    &::after {
      content: "";
      position: absolute;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-top: 20px solid #fef8de;
      bottom: -10px;
      left: calc(50% - 20px);
    }
  }

  .max-2lines {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .max-1lines {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .inline-wrapper {
    &.mobile {
      .inline-grid {
        .inline-grid-wrapper {
          padding: 0;
          .grid {
            padding-bottom: 30px;
            .grid-pagination {
              display: block;
            }
          }
        }
        .grid-button-prev,
        .grid-button-next {
          display: block;
        }

        .grid-item {
          margin-right: 0px;
        }
      }
    }
  }

  .grid-wrapper {
    flex-direction: row !important;
  }

  .item-email-input {
    input {
      display: flex !important;
    }
  }
  .item-email-terms {
    margin-bottom: 10px;
  }
}

.dme6-grid-container {
  &-left {
    display: grid;
    justify-content: flex-start;
  }
  &-right {
    display: grid;
    justify-content: flex-end;
  }
  &-center {
    display: grid;
    justify-content: center;
  }
}

@media screen and (max-width: 500px) {
  .dme6 {
    .inline-grid {
      .inline-grid-wrapper {
        padding: 0;
        .inline-popup {
          padding: 0 15px;
        }
        .grid {
          padding-bottom: 30px;
          .grid-pagination {
            display: block;
          }
        }
      }
      .grid-button-prev,
      .grid-button-next {
        display: none;
      }
    }
  }
}
