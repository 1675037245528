.transaction-add {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #a0a2a7;
  border-radius: 8px;
  text-align: center;
  padding: 13.5px 24px 13.5px 24px;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: #3ab78f;
  cursor: pointer;
  margin-bottom: 10px;
  background-color: #fff;
}

.box-active {
  color: #3ab78f;
  background-color: #3ab78f40;
  width: fit-content;
  border-radius: 2px;
  padding: 0 8px;
}

.box-inactive {
  color: #ff3d60;
  background-color: #ff3d6040;
  width: fit-content;
  border-radius: 2px;
  padding: 0 8px;
}

.payments-actions {
  .edit-action,
  .delete-action {
    cursor: pointer;
  }
}

.delete-text {
  color: var(--Warning, #ff3d60);
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.payment-filters {
  background-color: #fff;
}
